import React from 'react';

import {
	EvTableColumn,
	EvTableOptions,
	SORT_ORDER,
	TableSortOption,
	UrlCellFormatter
} from '@evinced-private/ui-common';

import { ICommonPageIssuesBreakdown } from '../../services/scan/ScansComparisonService';
import renderNumberCellFormatter from '../common/ev-table/cell-formatters/numberCellFormatter';
import { ScanTableDefinition } from '../scan-analysis/base/BaseScanViewV2';

const getDefaultSort = (): TableSortOption => {
	return {
		dataField: 'newIssues',
		order: SORT_ORDER.DESC
	};
};

const getColumns = (): EvTableColumn[] => {
	return [
		{
			dataField: 'url',
			text: 'Page',
			style: { width: '500px', maxWidth: '500px' },
			headerStyle: { width: '500px', maxWidth: '500px' },
			sort: true,
			formatter: (url: string) => {
				return <UrlCellFormatter url={url} />;
			}
		},
		{
			dataField: 'newIssues',
			text: 'new issues',
			style: { width: '80px' },
			headerStyle: { width: '80px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'resolvedIssues',
			text: 'resolved issues',
			style: { width: '80px' },
			headerStyle: { width: '80px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'recurringIssues',
			text: 'reocurring issues',
			style: { width: '80px' },
			headerStyle: { width: '80px' },
			sort: true,
			formatter: renderNumberCellFormatter
		}
	];
};

const getOptions = (): EvTableOptions => {
	return {
		title: 'Common pages issues',
		caption: 'Common pages issues table',
		pagination: true,
		remote: true,
		dataType: 'URLs',
		defaultSorted: [getDefaultSort()]
	};
};

const getTableDefinition = (
	pages: ICommonPageIssuesBreakdown[],
	totalCount: number,
	totalPaginationableResults: number
): ScanTableDefinition => {
	const result: ScanTableDefinition = {
		columns: getColumns(),
		options: getOptions(),
		data: pages,
		totalCount,
		totalPaginationableResults
	};
	return result;
};
export default { getTableDefinition, getDefaultSort };
