import React, { FC } from 'react';

import { EvIcon } from '@evinced-private/ui-common';

import InfoStatusIcon from '../../../../components/icons/InfoStatusIcon.svg';

import './InfoLine.scss';

interface IInfoLine {
	msg: string;
}

const InfoLine: FC<IInfoLine> = ({ msg }) => (
	<div className="info-line-msg">
		<EvIcon icon={InfoStatusIcon} className="info-line-icon" />
		<p className="info-line-text">{msg}</p>
	</div>
);

export default InfoLine;
