import React, { FC, useCallback, useEffect, useState } from 'react';

import {
	BUTTON_TYPES,
	EvConfirm,
	EvIcon,
	EvLink,
	EvLinkTypes,
	EvLoader,
	EvTableColumn,
	SORT_ORDER
} from '@evinced-private/ui-common';

import { SITE_SCANNER_APP_ID } from '../../../../consts/dom-consts';
import UrlFormattingHelper from '../../../../helpers/UrlFormattingHelper';
import { IUrlSeedDisplay } from '../../../../interfaces/UrlSeedDisplay';
import { useTablesStore } from '../../../../providers/tablesProvider/TablesProvider';
import SiteScannerTable from '../../../common/site-scanner-table/SiteScannerTable';
import RemoveIcon from '../../../icons/RemoveIcon.svg';
import SearchFilter from '../../../search-filter/SearchFilter';

interface ISeedsListProps {
	seeds: IUrlSeedDisplay[];
	selectedSeeds: IUrlSeedDisplay[];
	setSelectedSeeds: (seeds: IUrlSeedDisplay[]) => void;
	updatePropertySeeds: (seeds: IUrlSeedDisplay[], closeModal: () => void) => void;
	propertyId: string;
	isImporting?: boolean;
}

const SeedsList: FC<ISeedsListProps> = ({
	seeds,
	selectedSeeds,
	setSelectedSeeds,
	updatePropertySeeds,
	propertyId,
	isImporting = false
}: ISeedsListProps) => {
	const [filteredSeeds, setFilteredSeeds] = useState(seeds);
	const [searchFilterValue, setSearchFilterValue] = useState('');
	const tablesStore = useTablesStore();

	const tableId = `seeds-table-${propertyId}`;

	useEffect(() => {
		const filtered = seeds.filter((seed) => {
			return seed.url.toLowerCase().includes(searchFilterValue.toLowerCase());
		});
		setFilteredSeeds(filtered);
	}, [searchFilterValue, seeds]);

	const onSelectAll = (isSelect, seeds): void => {
		if (isSelect) {
			setSelectedSeeds(seeds);
		} else {
			setSelectedSeeds([]);
		}
	};

	const onInputChange = (newValue: string): void => {
		const { sizePerPage } = tablesStore.getPagination(tableId);

		tablesStore.updatePagination(tableId, { page: 1, sizePerPage });
		setSearchFilterValue(newValue);
	};

	const onSelectRow = (row, isSelect): void => {
		let newSelectedSeeds = [...selectedSeeds];
		if (isSelect) {
			newSelectedSeeds.push(row);
		} else {
			newSelectedSeeds = newSelectedSeeds.filter((seed) => seed.url !== row.url);
		}
		setSelectedSeeds(newSelectedSeeds);
	};

	const selectableTableActionsCellFormatter = (cell, row): JSX.Element => {
		return (
			<div className="actions-cell">
				<EvConfirm
					appElement={SITE_SCANNER_APP_ID}
					title="Confirm removing URL"
					promptMessage={`Are you sure you want to remove "${row.url}"?`}
					triggerButtonProps={{
						type: BUTTON_TYPES.ICON,
						title: 'Remove URL',
						children: <EvIcon icon={RemoveIcon} />
					}}
					onConfirm={(closeModal) => {
						let newSeeds = [...seeds];
						newSeeds = newSeeds.filter((s) => s.url !== row.url);
						updatePropertySeeds(newSeeds, closeModal);
					}}
				/>
			</div>
		);
	};

	const getColumns = (): EvTableColumn[] => {
		return [
			{
				dataField: 'url',
				text: 'URL',
				classes: 'clickable-cell',
				sort: true,
				// eslint-disable-next-line react/no-unstable-nested-components
				formatter: (url: string) => {
					const formattedUrl = UrlFormattingHelper.removeAllTrailingSlashes(url);
					return (
						<EvLink
							style={{ maxWidth: 'unset' }}
							url={url}
							linkText={formattedUrl}
							customTooltip={{ dataTip: url, dataFor: 'table-tooltip' }}
							type={EvLinkTypes.DARK}
						/>
					);
				}
			},
			{
				dataField: 'actions',
				text: '',
				classes: 'actions-cell-td',
				style: { width: '30px' },
				headerStyle: { width: '30px' },
				formatter: selectableTableActionsCellFormatter
			}
		];
	};

	const renderIsImporting = useCallback(
		(): JSX.Element => (
			<div className="loader">
				<EvLoader message="Importing data..." />
			</div>
		),
		[]
	);
	const topRowActionsProps = isImporting
		? { topRowActions: renderIsImporting(), alignActionsRight: false }
		: {};

	return (
		<div className="property-urls-table">
			<SearchFilter searchInputValue={searchFilterValue} onInputChange={onInputChange} />
			<SiteScannerTable
				tableId={tableId}
				tableData={filteredSeeds}
				totalCount={seeds.length}
				noDataIndicationMessage="No Seeds..."
				columns={getColumns()}
				persistTableState
				options={{
					title: 'Property Seeds',
					pagination: true,
					caption: 'URLs table',
					dataType: 'urls',
					defaultSorted: [
						{
							dataField: 'url',
							order: SORT_ORDER.ASC
						}
					],
					selectRow: {
						onSelect: onSelectRow,
						onSelectAll,
						selected: selectedSeeds.map((s) => s.id)
					}
				}}
				{...topRowActionsProps}
			/>
		</div>
	);
};

export default SeedsList;
