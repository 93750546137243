import React, { FC, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { EvLinkNavigationTabs, IMenuOption } from '@evinced-private/ui-common';

import EvPropertyViews from '../../components/common/ev-property-views/EvPropertyViews';
import PropertyViewLoadErrorPage from '../../components/common/ev-property-views/PropertyViewLoadErrorPage';
import RoutesHelper from '../../helpers/RoutesHelper';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import PropertyPage from '../property/PropertyPage';
import TrendsPage from '../trends/TrendsPage';

import './ScanAndTrendsPage.scss';

enum TAB_ID {
	SCANS = 'scans',
	TRENDS = 'trends'
}

const ScanAndTrendsPage: FC = () => {
	const history = useHistory();
	const location = useLocation();
	const { propertyId } = useParams<{ [key: string]: string }>();
	const [isTrendsDisabled, setTrendsDisabled] = useState<boolean>(true);

	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const currentViewId = useMemo(() => properyViewContext?.currentView?.id, [properyViewContext]);
	const viewLoadErr = useMemo(() => properyViewContext?.loadViewErr, [properyViewContext]);

	const getSelectedTabId = (): TAB_ID => {
		if (location.pathname.includes(RoutesHelper.getPropertyTrendsPath(propertyId))) {
			return TAB_ID.TRENDS;
		}
		return TAB_ID.SCANS;
	};

	const navigationOptions: IMenuOption[] = useMemo(
		() => [
			{
				onClick: () =>
					history.push(RoutesHelper.getPropertyPagePath(propertyId, currentViewId), {
						from: history.location.pathname
					}),
				title: 'Scans',
				id: TAB_ID.SCANS
			},
			{
				onClick: () =>
					history.push(RoutesHelper.getPropertyTrendsPath(propertyId, currentViewId), {
						from: history.location.pathname
					}),
				title: 'Trends',
				id: TAB_ID.TRENDS,
				disabled: isTrendsDisabled
			}
		],
		[history, propertyId, isTrendsDisabled, currentViewId]
	);

	const selectedTabId = getSelectedTabId();

	if (viewLoadErr) {
		return <PropertyViewLoadErrorPage propertyId={propertyId} />;
	}

	return (
		<div className="scan-and-trends-page">
			<div className="header-container">
				<EvLinkNavigationTabs options={navigationOptions} selectedTabId={selectedTabId} />
				<EvPropertyViews propertyId={propertyId} />
			</div>
			{selectedTabId === TAB_ID.SCANS ? (
				<PropertyPage propertyId={propertyId} disableTrends={setTrendsDisabled} />
			) : null}
			{selectedTabId === TAB_ID.TRENDS ? (
				<TrendsPage propertyId={propertyId} disableTrends={setTrendsDisabled} />
			) : null}
		</div>
	);
};

export default ScanAndTrendsPage;
