import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react-lite';

import { FormatHelper } from '@evinced-private/ui-common';

import BaseScanViewV2, {
	ScanTableDefinition
} from '../../../../components/scan-analysis/base/BaseScanViewV2';
import { MAX_RESULTS } from '../../../../consts/Results';
import { IIssuesPaginationResponse } from '../../../../interfaces/IssuesPaginationResponse';
import { SummaryItem } from '../../../../interfaces/SummaryItem';
import scanService from '../../../../services/scan/ScanService';
import { FilterParams } from '../../../../types/FilterParams';
import { ComponentDrilldownSummaryV2 } from '../../../../types/OverviewTypes';
import { PaginationParams } from '../../../../types/PaginationParams';
import ScanBreakdownTableHelper from '../breakdown/ScanBreakdownTableHelperV2';

interface IComponentDrillDownView {
	scanId: string;
	propertyId: string;
	componentId: string;
	viewId?: string;
}

const defaultTableSort = ScanBreakdownTableHelper.getDefaultSort();

const ComponentDrillDownView: FC<IComponentDrillDownView> = ({
	scanId,
	propertyId,
	componentId,
	viewId
}: IComponentDrillDownView) => {
	const [scanResults, setScanResults] = useState<IIssuesPaginationResponse>(null);
	const [totalCount, setTotalCount] = useState<number>(null);
	const [summaryPaneInfo, setSummaryPaneInfo] = useState<ComponentDrilldownSummaryV2>();

	const screenId = `scan-${scanId}-view-${viewId}-component-${componentId}-drilldown-view`;

	const loadTableItems = useCallback(
		async (
			scanId: string,
			paginationParams: PaginationParams,
			filtersParams: FilterParams
		): Promise<void> => {
			if (!filtersParams.filterValues) {
				filtersParams.filterValues = {};
			}
			filtersParams.filterValues.components = [{ value: componentId, label: componentId }];
			const response: IIssuesPaginationResponse = await scanService.getScanIssuesV2(
				scanId,
				paginationParams,
				{ ...filtersParams },
				viewId
			);
			setScanResults(response);
		},
		[componentId, viewId]
	);

	useEffect(() => {
		if (scanResults?.overallTotal) {
			setTotalCount(scanResults.overallTotal);
		}
	}, [scanResults?.overallTotal]);

	const loadSummaryData = useCallback(async (): Promise<void> => {
		const summaryPaneInfo = await scanService.getComponentDrilldownSummaryV2(
			scanId,
			componentId,
			viewId
		);
		setSummaryPaneInfo(summaryPaneInfo);
	}, [scanId, componentId, viewId]);

	const summaryPaneData = useMemo((): SummaryItem[] => {
		if (!summaryPaneInfo) {
			return [];
		}
		return [
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.totalOccurrencesCount),
				label: 'Total occurrences'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.totalPagesCount),
				label: 'Pages impacted'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.totalIssuesCount),
				label: 'Total issues'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.critical),
				label: 'Critical issues'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.serious),
				label: 'Serious issues'
			}
		];
	}, [summaryPaneInfo]);

	const tableDefinition = useMemo((): ScanTableDefinition => {
		if (!scanResults) {
			return null;
		}

		const { issues } = scanResults;
		const totalPaginationableResults =
			scanResults.overallTotal > MAX_RESULTS ? MAX_RESULTS : scanResults.overallTotal;

		return ScanBreakdownTableHelper.getTableDefinition(
			issues,
			totalCount,
			totalPaginationableResults
		);
	}, [scanResults, totalCount]);

	return (
		<BaseScanViewV2
			viewId={viewId}
			scanId={scanId}
			screenId={screenId}
			summaryPaneItems={summaryPaneData}
			tableDefinition={tableDefinition}
			defaultTableSort={defaultTableSort}
			loadSummaryData={loadSummaryData}
			loadTableItems={loadTableItems}
			propertyId={propertyId}
			renderCsvExportButton={true}
			tableWithUrlSwitcher={true}
		/>
	);
};

export default observer(ComponentDrillDownView);
