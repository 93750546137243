import React, { createContext, FC, useContext } from 'react';

import CommonFiltersStore from '../../stores/CommonFiltersStore';

const CommonFiltersStoreContext = createContext<CommonFiltersStore>(new CommonFiltersStore());

const CommonFiltersProvider: FC<{ store: CommonFiltersStore }> = ({ store, children }) => {
	return (
		<CommonFiltersStoreContext.Provider value={store}>
			{children}
		</CommonFiltersStoreContext.Provider>
	);
};

const useCommonFiltersStore = (): CommonFiltersStore => {
	return useContext(CommonFiltersStoreContext);
};

export { CommonFiltersProvider, useCommonFiltersStore };
