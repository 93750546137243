import React, { FC, useEffect, useState } from 'react';

import _ from 'lodash';

import {
	BUTTON_TYPES,
	EvCheckbox,
	EvConfirm,
	EvInfoTooltip,
	EvMainTitle,
	EvTextInput,
	EvTitle,
	TEXT_INPUT_TYPES,
	TITLE_MODES
} from '@evinced-private/ui-common';

import { SITE_SCANNER_APP_ID } from 'src/consts/dom-consts';
import { isRegexValid } from 'src/helpers/FormHelper';
import { IGroupingRule } from 'src/interfaces/GroupingRule';
import { IProperty } from 'src/interfaces/Property';

import defaultGroupingRuleUrl from '../../../../consts/defaultGroupingRuleUrl';
import queryParamsOptions from '../../../../consts/queryParamsOptions';
import groupingHelper from '../../../../helpers/GroupingHelper';
import propertyHelper, { newGroupingRule } from '../../../../helpers/PropertyHelper';
import UrlNormalizingHelper from '../../../../helpers/UrlNormalizingHelper';
import SiteScannerPopup from '../../../common/site-scanner-popup/SiteScannerPopup';

import GroupingQueryParams from './GroupingQueryParams';

import './AddRulePopup.scss';

interface IAddGroupingRulePopupProps {
	/**
	 * the property to be edited
	 */
	property: IProperty;
	/**
	 * is the popup open?
	 */
	isOpen: boolean;
	onClosePopup: () => void;
	onGroupingRulesUpdated: (any) => void;
	/**
	 * if the popup is in editmode, this is the initialData
	 */
	ruleForEdit?: IGroupingRule;
	showSampleSize?: boolean;
}

const AddGroupingRulePopup: FC<IAddGroupingRulePopupProps> = ({
	property,
	isOpen,
	ruleForEdit,
	onGroupingRulesUpdated,
	onClosePopup,
	showSampleSize = true
}: IAddGroupingRulePopupProps) => {
	const [rule, setRule] = useState<IGroupingRule>(newGroupingRule);
	const [originalRuleBeforeChanges, setOriginalRuleBeforeChanges] =
		useState<IGroupingRule>(newGroupingRule);
	const [regexErrorMessage, setRegexErrorMessage] = useState<string>(null);
	const [samplesCountErrorMessage, setSamplesCountErrorMessage] = useState<string>(null);
	const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
	const [emptyListRuleError, setEmptyListRuleError] = useState('');

	const resetData = (): void => {
		setRule(newGroupingRule);
		setRegexErrorMessage(null);
		setSamplesCountErrorMessage(null);
		setOriginalRuleBeforeChanges(newGroupingRule);
	};

	useEffect(() => {
		if (ruleForEdit && ruleForEdit.urlPattern) {
			const newRule = groupingHelper.formatRuleForForm(ruleForEdit);
			setRule(newRule);
			setOriginalRuleBeforeChanges(newRule);
		}
		return () => {
			resetData();
		};
	}, [ruleForEdit]);

	const groupingRules = propertyHelper.getPropertyDiscoveryGroupingRules(property);

	const isPositiveInteger = (num: number): boolean => {
		return Number.isInteger(num) && num > 0;
	};

	const addRuleToProperty = (close): void => {
		const newRule = { ...rule };
		newRule.urlPattern = UrlNormalizingHelper.formatURL(rule.urlPattern);
		const isValid = isRegexValid(newRule.urlPattern);
		if (!isValid) {
			setRegexErrorMessage('Please enter a valid Regex expression');
			return;
		}
		if (newRule.maxSamplesCount && !isPositiveInteger(newRule.maxSamplesCount)) {
			setSamplesCountErrorMessage('Please enter a positive round number');
			return;
		}

		const maxPages = propertyHelper.getMaxPagesCount(property);
		if (newRule.maxSamplesCount > maxPages) {
			setSamplesCountErrorMessage(
				`Max sample size must be less than the total pages count (${maxPages})`
			);
			return;
		}

		if (
			newRule.ignoreQueryParameters === queryParamsOptions.LIST &&
			newRule.excludedQueryParameters.length === 0
		) {
			setEmptyListRuleError('Please insert at least 1 query parameter');
			return;
		}

		const isEditMode = ruleForEdit && ruleForEdit.urlPattern;
		const groupingRuleExists = groupingRules.find((r) => r.urlPattern === newRule.urlPattern);

		if (groupingRuleExists && !isEditMode) {
			setRegexErrorMessage('this rule is already applied');
			return;
		}

		const newProperty = propertyHelper.addOrEditPropertyRules(newRule, ruleForEdit, property);
		onGroupingRulesUpdated(newProperty);
		resetData();
		close();
	};

	const renderScopeSection = (): JSX.Element => {
		const scopeSectionTitleId = 'scope-section-title';
		return (
			<section className="grouping-rule-part scope-section" aria-labelledby={scopeSectionTitleId}>
				<div id={scopeSectionTitleId}>
					<EvTitle
						titleText="Add New Grouping Rule"
						id={scopeSectionTitleId}
						mode={TITLE_MODES.MAIN}
					/>
				</div>
				<EvMainTitle className="title-text bottom-spacing">Scope/Base URL</EvMainTitle>
				<div className="popup-form-row bottom-spacing">
					<label className="form-label">URL Regex</label>
					<div className="form-field">
						<EvTextInput
							isRequired
							ariaLabel="URL Regex"
							error={regexErrorMessage}
							placeholder="Enter Regex"
							value={rule.urlPattern}
							isDisabled={ruleForEdit && ruleForEdit.urlPattern === defaultGroupingRuleUrl}
							onChange={(value) => {
								setRule({ ...rule, urlPattern: value });
							}}
						/>
					</div>
				</div>
				{showSampleSize && (
					<div className="popup-form-row">
						<label className="form-label">Sample size</label>
						<div className="form-field">
							<EvTextInput
								type={TEXT_INPUT_TYPES.NUMBER}
								ariaLabel="Sample size"
								value={rule.maxSamplesCount}
								placeholder="Leave empty for unlimited"
								error={samplesCountErrorMessage}
								onChange={(value) => {
									const intValue = parseInt(value);
									if (Number.isNaN(intValue)) {
										setRule({ ...rule, maxSamplesCount: null });
									} else {
										setRule({ ...rule, maxSamplesCount: intValue });
									}
								}}
							/>
						</div>
						<EvInfoTooltip title="Sample size" tooltipPlace="left">
							Define the maximum number of pages to include in this group. Leave empty for unlimited
						</EvInfoTooltip>
					</div>
				)}
			</section>
		);
	};

	const renderAttributesSection = (): JSX.Element => {
		const attributesSectionTitleId = 'attributes-section-title';
		const ignoreUrlFragmentsExplanation = 'ignore-url-fragments-explanation';
		const caseSensitiveExplanation = 'case-sensitive-explanation';
		return (
			<section
				className="grouping-rule-part attributes-selection-form"
				aria-labelledby={attributesSectionTitleId}
			>
				<div id={attributesSectionTitleId}>
					<EvMainTitle className="title-text">Attributes</EvMainTitle>
				</div>
				<div className="radio-item">
					<div className="attributes-option">
						<EvCheckbox
							isChecked={rule.ignoreFragment}
							label="Ignore URL fragments"
							ariaDescribedBy={ignoreUrlFragmentsExplanation}
							onClick={() => {
								setRule({ ...rule, ignoreFragment: !rule.ignoreFragment });
							}}
						/>
						<div className="checkbox-explanation" aria-hidden="true">
							<EvInfoTooltip
								title="Ignore URL fragments"
								tooltipPlace="right"
								id={ignoreUrlFragmentsExplanation}
								className="attributes-option-info-tooltip"
							>
								Uncheck only if URL Fragments (#) are used to differentiate between different pages
							</EvInfoTooltip>
						</div>
					</div>
				</div>
				<div className="radio-item">
					<div className="attributes-option">
						<EvCheckbox
							isChecked={rule.caseSensitive}
							label="Case sensitive"
							ariaDescribedBy={caseSensitiveExplanation}
							onClick={() => {
								setRule({ ...rule, caseSensitive: !rule.caseSensitive });
							}}
						/>
						<div className="checkbox-explanation" aria-hidden="true">
							<EvInfoTooltip
								title="Case sensitive"
								tooltipPlace="right"
								id={caseSensitiveExplanation}
								className="attributes-option-info-tooltip"
							>
								Uncheck if your web server treats URLs as case insensitive
							</EvInfoTooltip>
						</div>
					</div>
				</div>
			</section>
		);
	};

	const onClose = (): void => {
		resetData();
		onClosePopup();
	};

	const closeModalWithChangesCheck = (): void => {
		const isRuleChanged = !_.isEqual(rule, originalRuleBeforeChanges);
		if (isRuleChanged) {
			setConfirmModalOpen(true);
		} else {
			onClose();
		}
	};

	const popupActions = [
		{
			title: 'Cancel',
			type: BUTTON_TYPES.SECONDARY,
			onClick: closeModalWithChangesCheck
		},
		{
			title: 'Save Rule',
			onClick: () => {
				addRuleToProperty(onClosePopup);
			}
		}
	];

	return (
		<>
			<SiteScannerPopup
				isControlled
				isOpen={isOpen}
				onClose={closeModalWithChangesCheck}
				title="Add new grouping Rule"
				isSrOnly
				className="settings-popup"
				buttons={popupActions}
				popupWidth={490}
			>
				<div className="add-rule-popup-body">
					<div className="form-column">
						{renderScopeSection()}
						<GroupingQueryParams
							rule={rule}
							updateRule={setRule}
							emptyListRuleError={emptyListRuleError}
							setEmptyListRuleError={setEmptyListRuleError}
						/>
						{renderAttributesSection()}
					</div>
				</div>
			</SiteScannerPopup>
			<EvConfirm
				appElement={SITE_SCANNER_APP_ID}
				title="Confirm close"
				approveButtonText="Confirm"
				promptMessage="You have unsaved changes, are you sure you want to go back?"
				isOpen={isConfirmModalOpen}
				isControlled={true}
				onConfirm={(closeModal) => {
					onClose();
					closeModal();
				}}
				onClose={() => setConfirmModalOpen(false)}
			/>
		</>
	);
};

export default AddGroupingRulePopup;
