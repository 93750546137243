import React, { FC, useEffect, useRef, useState } from 'react';

import { EvTooltip } from '@evinced-private/ui-common';

import UUIDHelper from '../../../helpers/UUIDHelper';

import './EllipsedTextWithTooltip.scss';

interface IEllipsedTextWithTooltipProps {
	/**
	 * The text to be ellipsed
	 */
	text: string;
	/**
	 * The maximal width for the text (e.g. 300px)
	 */
	maxWidth: string;
	/**
	 * Use width comparison to determine if the text is ellipsed and show tooltip
	 */
	showTooltipOnlyOnEllipsed?: boolean;
}

const EllipsedTextWithTooltip: FC<IEllipsedTextWithTooltipProps> = ({
	text,
	maxWidth,
	showTooltipOnlyOnEllipsed
}: IEllipsedTextWithTooltipProps) => {
	const tooltipId = UUIDHelper.generateUUID();
	const style = {
		maxWidth: maxWidth || '300px',
		width: 'fit-content'
	};
	const [isEllipsis, setIsEllipsis] = useState(false);
	const textRef = useRef<HTMLDivElement>();

	useEffect(() => {
		const textNode = textRef.current;
		const calculateIsEllipsis = (): void => {
			setIsEllipsis(textNode?.scrollWidth > textNode?.offsetWidth);
		};
		if (showTooltipOnlyOnEllipsed) {
			textNode.addEventListener('resize', calculateIsEllipsis);
			calculateIsEllipsis();
		}
		return () => textNode?.removeEventListener('resize', calculateIsEllipsis);
	}, [text, maxWidth, showTooltipOnlyOnEllipsed]);

	const showTooltip = isEllipsis || !showTooltipOnlyOnEllipsed;

	return (
		<>
			<div
				ref={textRef}
				className="ellipsed-text"
				data-tooltip-id={showTooltip ? tooltipId : null}
				data-tooltip-content={showTooltip ? text : null}
				style={style}
			>
				{text}
			</div>
			{showTooltip && (
				<EvTooltip id={tooltipId} className="common-tooltip" place="bottom" bgColor="white" />
			)}
		</>
	);
};

export default EllipsedTextWithTooltip;
