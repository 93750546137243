import { Report } from 'src/types/ReportModel';

import ServerApi from '../../api/ServerApi';
import API_URL_PATHS from '../../consts/ApiUrlPaths';
import { generateUrlWithViewId } from '../../helpers/PropertyViewsHelper';
import ScanCrawlStateHelper from '../../helpers/ScanCrawlStateHelper';
import { IFileResult } from '../../interfaces/FileResult';
import { IScan } from '../../interfaces/Scan';
import { ScanScore } from '../../interfaces/ScanScore';
import { ITrend } from '../../interfaces/Trend';
import { IUrlSet } from '../../interfaces/UrlSet';
import propertiesService from '../PropertiesService';
import urlSetService from '../UrlSetService';

const { SCANS_URL_SUB_PATH, TRENDS_PATH } = API_URL_PATHS;

const cachedScans = new Map<string, IScan[]>();
const getCachedScansKey = (propertyId: string, viewId: string): string => `${propertyId}-${viewId}`;

const sortScansByTimeDesc = (scans: IScan[]): IScan[] => {
	return scans.sort((scanA, scanB) => {
		const timeA = new Date(scanA.createdTime).getTime();
		const timeB = new Date(scanB.createdTime).getTime();
		return timeB - timeA;
	});
};

async function updateCachedScans(propertyId: string, viewId: string = null): Promise<void> {
	const url = `/${SCANS_URL_SUB_PATH}?propertyId=${propertyId}`;
	const scans = await ServerApi.apiCall('GET', generateUrlWithViewId(url, viewId));
	cachedScans.set(getCachedScansKey(propertyId, viewId), scans);
}

async function getCachedScansMetadata(propertyId: string, viewId: string = null): Promise<IScan[]> {
	if (!cachedScans.has(getCachedScansKey(propertyId, viewId))) {
		await updateCachedScans(propertyId, viewId);
	}
	return cachedScans.get(getCachedScansKey(propertyId, viewId));
}

/**
 * returns a list of all scans sorted by date,
 * descending order (in the future move this to the API itself)
 * @param propertyId - property Id
 */
async function getAllScansSortedByTimeDesc(
	propertyId: string,
	viewId: string = null
): Promise<IScan[]> {
	await updateCachedScans(propertyId, viewId);
	return sortScansByTimeDesc(cachedScans.get(getCachedScansKey(propertyId, viewId)));
}

async function getTrends(
	ids: string[],
	isCommon: boolean,
	viewId: string = null
): Promise<ITrend[]> {
	const url = `/${SCANS_URL_SUB_PATH}${TRENDS_PATH}?ids=${ids.join(',')}&common=${isCommon}`;
	return ServerApi.apiCall('GET', generateUrlWithViewId(url, viewId));
}
async function getScansScore(ids: string[], viewId: string = null): Promise<ScanScore[]> {
	const url = `${SCANS_URL_SUB_PATH}/score?ids=${ids.join(',')}`;
	return ServerApi.apiCall('GET', generateUrlWithViewId(url, viewId));
}
async function createScan(propertyId: string): Promise<IScan> {
	const url = `/${SCANS_URL_SUB_PATH}`;
	const body = { propertyId };
	return ServerApi.apiCall('POST', url, { data: body });
}

async function getScanMetadata(scanId: string): Promise<IScan> {
	const url = `/${SCANS_URL_SUB_PATH}/${scanId}?metadata=false`;
	return ServerApi.apiCall('GET', url);
}

async function deleteScan(scanId: string): Promise<null> {
	const url = `/${SCANS_URL_SUB_PATH}/${scanId}`;
	return ServerApi.apiCall('DELETE', url);
}

async function getScanUrlSetConfig(scanId: string): Promise<IUrlSet> {
	return getScanMetadata(scanId).then((scanDetails) => {
		return urlSetService.getUrlSet(scanDetails.configuration.urlSetId);
	});
}

async function isScanCurrentlyRunning(propertyId: string): Promise<boolean> {
	const propertyData = await propertiesService.getProperty(propertyId);
	const state = propertyData?.lastScan?.state;
	return ScanCrawlStateHelper.isInProgress(state);
}

const getAllSuccessfulScans = async (
	propertyId: string,
	viewId: string = null
): Promise<IScan[]> => {
	const allScans = await getAllScansSortedByTimeDesc(propertyId, viewId);
	if (!allScans || allScans.length === 0) {
		throw new Error('No scans found.');
	}
	return allScans;
};

const getDoneScan = async (
	propertyId: string,
	scanId?: string,
	viewId: string = null
): Promise<IScan> => {
	const allScans = await getAllSuccessfulScans(propertyId, viewId);
	return allScans.find((scan) => scan.id === scanId);
};

function getScanIssue(scanId: string, issueId: string): Promise<Report> {
	return ServerApi.apiCall('GET', `/scans/${scanId}/issues/${issueId}`);
}

function getCsvScanResultsFile(url: string): Promise<string> {
	return ServerApi.apiCall('GET', url, { isAbsoluteUrl: true, noExtraHeaders: true });
}

function getScanCsv(scanId: string, groupByPages?: boolean): Promise<IFileResult> {
	const groupByUrl = 'groupBy=url';
	const params = [];

	if (groupByPages) {
		params.push(groupByUrl);
	}

	const queryParams = params.length ? `?${params.join('&')}` : '';
	return ServerApi.apiCall('GET', `/${SCANS_URL_SUB_PATH}/${scanId}/export-results${queryParams}`, {
		isFile: true
	});
}

const scansService = {
	sortScansByTimeDesc,
	getAllScansSortedByTimeDesc,
	getScansScore,
	createScan,
	getScanMetadata,
	deleteScan,
	isScanCurrentlyRunning,
	getDoneScan,
	getScanUrlSetConfig,
	getCsvScanResultsFile,
	getScanCsv,
	getScanIssue,
	getTrends,
	getCachedScansMetadata
};

export default scansService;
