import React, { FC } from 'react';

import { EvLink, EvPieChart, EvSpinner, FormatHelper, PieDatum } from '@evinced-private/ui-common';

import NotGroupedComponent from '../../consts/NotGroupedComponent';
import chartsHelper from '../../helpers/ChartsHelper';
import MathHelper from '../../helpers/MathHelper';
import pieHelper from '../../helpers/PieHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import { ComponentOverviewResponse } from '../../types/OverviewTypes';
import EvChartTableLink from '../common/ev-chart-table-link/EvChartTableLink';
import { getComponentsPieTooltipV2 } from '../common/ev-pie-chart-tooltip/ComponentsPieTooltipV2';
import ChartWrapper from '../data-displays/overview-charts/chart-wrapper/ChartWrapper';
import ChartsSection, {
	SectionTextBlock
} from '../data-displays/overview-charts/charts-section/ChartsSection';

import CriticalComponentsRaceBar from './bars/CriticalComponentsRaceBar';

interface IComponentChartsProps {
	totalCriticalIssues: number;
	totalIssues: number;
	groupedIssues: number;
	scanId?: string;
	propertyId: string;
	componentsCountHash: ComponentOverviewResponse;
	onComponentTableLinkClick?: (componentId: string) => void;
}

const ComponentsCharts: FC<IComponentChartsProps> = ({
	totalCriticalIssues,
	totalIssues,
	groupedIssues,
	scanId,
	propertyId,
	componentsCountHash,
	onComponentTableLinkClick
}) => {
	if (!componentsCountHash) {
		return <EvSpinner />;
	}
	const getSectionTextBlock = (): SectionTextBlock => {
		return {
			title: 'Grouping of issues into components',
			rows: [
				{
					label: 'Total number of components',
					value: FormatHelper.formatNumber(componentsCountHash.componentsCount)
				},
				{
					label: 'Components with critical issues',
					value: FormatHelper.formatNumber(componentsCountHash.criticalComponentsCount)
				}
			]
		};
	};

	const fieldFormatter = (componentId: string): React.ReactNode => {
		return (
			<EvChartTableLink
				onClick={() => onComponentTableLinkClick(componentId)}
				value={componentId}
			/>
		);
	};

	const renderComponentsDistributionPie = (): JSX.Element => {
		const componentsPieData: PieDatum[] = pieHelper.getComponentsDistributionPieDataV2(
			componentsCountHash,
			totalIssues
		);

		if (componentsPieData.length === 0) {
			return null;
		}

		const percentageOfGroupedIssues = totalIssues
			? MathHelper.calcPercentage(groupedIssues, totalIssues, false)
			: 0;

		// modify name of the Not Grouped component
		componentsPieData
			.filter((c) => c.id === NotGroupedComponent.ID)
			.forEach((c) => {
				c.id = NotGroupedComponent.DISPLAY_NAME;
			});

		// display round numbers of the graphs
		componentsPieData.forEach((c) => {
			c.value = Math.round(c.value);
		});
		return (
			<ChartWrapper title="Evinced advantage: auto detection of critical issues chart">
				<EvPieChart
					className="components-distribution"
					data={componentsPieData}
					title={`${Math.floor(percentageOfGroupedIssues)}% of all issues are grouped into ${
						componentsCountHash.componentsCount
					} components`}
					withLegend={false}
					barDesignProps={{
						colors: { scheme: 'spectral' },
						enableArcLabels: false,
						activeOuterRadiusOffset: 0,
						arcLinkLabelsSkipAngle: 20,
						tooltip: getComponentsPieTooltipV2(componentsCountHash)
					}}
					width={501}
					height={340}
					headerTooltipProps={{
						tooltipText:
							'The graph indicates the % of issues grouped by components. Each component indicates the same element on different pages. Solving issues related to a component will affect all pages that contain this component.',
						tooltipTitle: 'What is Grouping of issues into components',
						tooltipPlacement: 'right'
					}}
					tableProps={{
						columnsPattern: [
							{ name: 'Component', field: 'label', formatter: fieldFormatter },
							{ name: 'Issues Percentage', field: 'percent' }
						]
					}}
				/>
			</ChartWrapper>
		);
	};

	const renderCriticalComponentsRaceBar = (): JSX.Element => {
		const componentsWithCritical = chartsHelper.getComponentsWithCriticalV2(componentsCountHash);
		if (componentsWithCritical.length === 0) {
			return null;
		}
		return (
			<ChartWrapper title="Breakdown by components">
				<CriticalComponentsRaceBar
					componentsWithCritical={componentsWithCritical}
					totalCriticalIssues={totalCriticalIssues}
					propertyId={propertyId}
					scanId={scanId}
					onTableLinkClick={onComponentTableLinkClick}
				/>
			</ChartWrapper>
		);
	};

	const sectionTextBlock = getSectionTextBlock();

	return (
		<ChartsSection
			sectionTextBlock={sectionTextBlock}
			className="avoid-break"
			skiplinkId="components-charts"
			renderTitleActions={() => {
				return (
					// Temporary the url is empty - until the components page will be ready
					<EvLink
						linkText="View all components"
						url={
							scanId ? RoutesHelper.getScanResultComponentsBreakdownPath(propertyId, scanId) : ''
						}
						ariaLabel="view all components"
					/>
				);
			}}
		>
			<div className="charts-row">
				{componentsCountHash ? (
					<>
						{renderComponentsDistributionPie()}
						{renderCriticalComponentsRaceBar()}
					</>
				) : (
					<EvSpinner />
				)}
			</div>
		</ChartsSection>
	);
};

export default ComponentsCharts;
