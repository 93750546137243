import React, { FC } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';

import { SuccessfulSignupMessage } from '@evinced-private/ui-common';

import RoutesHelper from './helpers/RoutesHelper';
import analyticsService from './services/analytics/AnalyticsService';
import HubspotService from './services/analytics/HubspotService';
import App from './App';

const domain = process.env.AUTH0_EVINCED_DOMAIN;
const clientId = process.env.AUTH0_CLIENT_ID;
const audience = process.env.AUTH0_EVINCED_API_AUDIENCE;

const history = createBrowserHistory();

const AppContainer: FC = () => {
	// using the history object as an aspect for google analytics page views
	history.listen((location) => {
		analyticsService.pageView(location.pathname);
		HubspotService.sendPageView(location.pathname);
	});

	const onRedirectCallback = (appState): void => {
		history.push({
			pathname:
				RoutesHelper.getLoginSuccessPath() || appState?.returnTo || window.location.pathname,
			search: ''
		});
	};

	const searchParams = new URLSearchParams(window.location.search);
	const error = searchParams.get('error');
	const errorDescription = searchParams.get('error_description');
	if (
		(error === 'unauthorized' || error === 'access_denied') &&
		errorDescription?.includes('verify your email')
	) {
		return <SuccessfulSignupMessage />;
	}

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience,
				scope: 'read:current_user openid profile user_metadata'
			}}
			onRedirectCallback={onRedirectCallback}
		>
			<App history={history} />
		</Auth0Provider>
	);
};

export default AppContainer;
