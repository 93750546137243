import React, { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import { EvButton, EvMainTitle } from '@evinced-private/ui-common';

import withTabsNavigation from '../../hoc/withTabsNavigation';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import TogglesService, { DevelopmentToggles } from '../../services/TogglesService';
import TabsViews from '../../types/TabsViews';

import ScanBreakdownViewV2 from './views/breakdown/ScanBreakdownViewV2';
import ScanComponentsViewV3 from './views/components/ScanComponentsViewV2';
import ScanIssueTypesViewV2 from './views/issues/ScanIssueTypesViewV2';
import ScanOverviewViewV2 from './views/overview/ScanOverviewViewV2';
import ScanPagesViewV2 from './views/pages/ScanPagesViewV2';

import './ScanAnalysisPage.scss';

interface IScanAnalysisPageProps {
	view: TabsViews;
}

// todo: change to ScanPage once we'll remove the old one
const ScanAnalysisPage: FC<IScanAnalysisPageProps> = ({ view }: IScanAnalysisPageProps) => {
	const { propertyId, scanId } = useParams<{ [key: string]: string }>();
	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const viewId = useMemo(() => properyViewContext?.currentView?.id, [properyViewContext]);

	const showPrintButton = useMemo(() => {
		return TogglesService.getToggle(DevelopmentToggles.SHOW_PRINT_BUTTON);
	}, []);

	const onDownloadPDFClick = useCallback((): void => {
		const currentPageTitle = document.title;
		// set a custom name for the pdf file
		document.title = `${propertyId}-Scan-${scanId}`;
		window.print();
		// return to previous page title
		document.title = currentPageTitle;
	}, [propertyId, scanId]);

	const renderActionButtons = useCallback((): JSX.Element => {
		const isOverview = view === TabsViews.OVERVIEW;
		return (
			<div className="actions">
				{showPrintButton && isOverview && (
					<EvButton title="Download PDF" onClick={onDownloadPDFClick}>
						Print \ Save as PDF
					</EvButton>
				)}
			</div>
		);
	}, [showPrintButton, onDownloadPDFClick, view]);

	const renderViewContent = (): JSX.Element => {
		switch (view) {
			case TabsViews.OVERVIEW: {
				return (
					<ScanOverviewViewV2
						scanId={scanId}
						propertyId={propertyId}
						renderActionButtons={renderActionButtons}
						viewId={viewId}
					/>
				);
			}

			case TabsViews.COMPONENTS: {
				return <ScanComponentsViewV3 viewId={viewId} scanId={scanId} propertyId={propertyId} />;
			}

			case TabsViews.ISSUE_TYPES: {
				return <ScanIssueTypesViewV2 viewId={viewId} scanId={scanId} propertyId={propertyId} />;
			}

			case TabsViews.PAGES: {
				return <ScanPagesViewV2 viewId={viewId} scanId={scanId} propertyId={propertyId} />;
			}

			case TabsViews.BREAKDOWN: {
				return <ScanBreakdownViewV2 viewId={viewId} scanId={scanId} propertyId={propertyId} />;
			}
			default:
				return <div />;
		}
	};

	return (
		<div className="scan-analysis-page">
			<div className="scan-page-top">
				<EvMainTitle className="sr-only">{view}</EvMainTitle>
			</div>
			{renderViewContent()}
		</div>
	);
};

export default withTabsNavigation(ScanAnalysisPage);
