import React, { FC } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { BUTTON_TYPES, EvButton, EvConfirm, EvIcon } from '@evinced-private/ui-common';

import defaultGroupingRuleUrl from '../../../../consts/defaultGroupingRuleUrl';
// import EvConfirm from '../../../common/ev-confirm/EvConfirm';
import { SITE_SCANNER_APP_ID } from '../../../../consts/dom-consts';
import queryParamsOptions from '../../../../consts/queryParamsOptions';
import { IGroupingRule } from '../../../../interfaces/GroupingRule';
import DragIcon from '../../../icons/DragIcon.svg';
import * as EditIcon from '../../../icons/EditIcon.svg';
import RemoveIcon from '../../../icons/RemoveIcon.svg';

interface IGroupingRulesListProps {
	groupingRules?: IGroupingRule[];
	onRemoveRule: (url: string) => void;
	onDragEnd: (rule: IGroupingRule) => void;
	onEditRule: (rule: IGroupingRule) => void;
}

const GroupingRulesList: FC<IGroupingRulesListProps> = ({
	groupingRules,
	onRemoveRule,
	onEditRule,
	onDragEnd
}: IGroupingRulesListProps) => {
	const renderDeleteRuleConfirmPopup = (url): JSX.Element => {
		return (
			<EvConfirm
				appElement={SITE_SCANNER_APP_ID}
				title="Delete rule"
				triggerButtonProps={{
					type: BUTTON_TYPES.ICON,
					title: 'Delete rule',
					className: 'rule-action',
					children: <EvIcon icon={RemoveIcon} />
				}}
				approveButtonText="Delete"
				promptMessage="Are you sure you want to delete this rule?"
				onConfirm={(closeModal) => {
					onRemoveRule(url);
					closeModal();
				}}
			/>
		);
	};

	const renderRuleLine = (rule: IGroupingRule, index: number): JSX.Element => {
		if (rule.urlPattern === defaultGroupingRuleUrl) {
			return null;
		}

		let queryParams = queryParamsOptions.INCLUDE_ALL;
		if (rule.ignoreQueryParameters) {
			queryParams =
				rule.excludedQueryParameters && rule.excludedQueryParameters.length > 0
					? queryParamsOptions.LIST
					: queryParamsOptions.IGNORE_ALL;
		}

		return (
			<Draggable draggableId={rule.urlPattern} index={index} key={rule.urlPattern}>
				{(provided) => (
					<div ref={provided.innerRef} {...provided.draggableProps}>
						<div className="rule-line" key={rule.urlPattern}>
							<div
								className="rule-field handle"
								{...provided.dragHandleProps}
								aria-label="drag handle"
							>
								<EvIcon icon={DragIcon} small />
							</div>
							<div className="rule-info">
								<div className="rule-field">
									<div className="prop-name">URL:</div>
									<div className="prop-value urlpattern-value" title={rule.urlPattern}>
										{rule.urlPattern}
									</div>
								</div>
								<div className="rule-field">
									<div className="prop-name">Sampling:</div>
									<div className="prop-value" title={`${rule.maxSamplesCount}` || 'Unlimited'}>
										{rule.maxSamplesCount || 'Unlimited'}
									</div>
								</div>
								<div className="rule-field">
									<div className="prop-name long-name">Query parameters:</div>
									<div className="prop-value" title={queryParams}>
										{queryParams}
									</div>
								</div>
								<div className="rule-field">
									<div className="prop-name">Attributes:</div>
									<div
										className="prop-value"
										title={rule.ignoreFragment && rule.caseSensitive ? 'default' : 'custom'}
									>
										{rule.ignoreFragment && rule.caseSensitive ? 'default' : 'custom'}
									</div>
								</div>
							</div>
							<div className="rule-actions">
								<EvButton
									type={BUTTON_TYPES.ICON}
									title={`Edit grouping rule ${index}`}
									className="rule-action"
									onClick={() => {
										onEditRule(rule);
									}}
								>
									<EvIcon icon={EditIcon} />
								</EvButton>
								{renderDeleteRuleConfirmPopup(rule.urlPattern)}
							</div>
						</div>
					</div>
				)}
			</Draggable>
		);
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="grouping">
				{(provided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{groupingRules.map((rule: IGroupingRule, index: number) => {
							return renderRuleLine(rule, index);
						})}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default GroupingRulesList;
