import React, { FC } from 'react';
import { useHistory } from 'react-router';

import classNames from 'classnames';

import { BUTTON_TYPES, EvButton } from '@evinced-private/ui-common';

import EllipsedTextWithTooltip from '../../../../components/common/ellipsed-text-with-tooltip/EllipsedTextWithTooltip';
import { ALL_CONTENT_VIEW_ID } from '../../../../components/common/ev-property-views/consts/PropertyViews';
import ScreenshotCellFormatter from '../../../../components/scan-analysis/table-formatters/screenshot-cell-formatter/ScreenshotCellFormatter';
import RoutesHelper from '../../../../helpers/RoutesHelper';
import ScoreHelper from '../../../../helpers/ScoreHelper';
import { TQuickWin } from '../../DashboardTypes';
import Box from '../box/Box';

import './QuickWin.scss';

interface IQuickWin {
	quickWin: TQuickWin;
	spaceToRight: boolean;
}
const exploreTitle = 'Explore';

const MAX_PROPERTY_NAME_LENGTH = '205px';

const QuickWin: FC<IQuickWin> = ({ quickWin, spaceToRight = true }) => {
	const history = useHistory();

	if (quickWin.criticalIssuesPercentage < 2) {
		return null;
	}

	return (
		<Box
			className={classNames('quick-win', {
				'space-to-right': spaceToRight
			})}
		>
			<div>
				<div className="resolve-precentage">
					Resolve
					<span className="precentage">
						{` ${ScoreHelper.roundScore(quickWin.criticalIssuesPercentage)}% `}
					</span>
					of critical issues
				</div>
				<div className="property-name">
					<span className="in-prefix">in</span>
					<EllipsedTextWithTooltip
						text={quickWin.property.name}
						maxWidth={MAX_PROPERTY_NAME_LENGTH}
						showTooltipOnlyOnEllipsed
					/>
				</div>
				<div className="by-fixing">{`By fixing ${quickWin.componentsCount} components`}</div>
			</div>
			<div className="screenshot">
				<ScreenshotCellFormatter
					cellDescriptor="Component sample issue"
					screenshotDetails={{
						pageScreenshotUrl: quickWin.screenshot.pageScreenshotUrl,
						boundingBoxes: [[0, 0, 0, 0]]
					}}
					imageContainerClassName="screenshot-image-container"
					screenshotImgClassName="screenshot-image"
					noCrop={true}
				/>
			</div>
			<EvButton
				className="explore-button"
				type={BUTTON_TYPES.ACTION}
				title={`${exploreTitle} the critical issues of property ${quickWin.property.name}`}
				onClick={() =>
					history.push({
						pathname: RoutesHelper.getScanResultComponentsBreakdownPath(
							quickWin.property.id,
							quickWin.property.lastSuccessfulScan.id
						),
						search: `viewId=${ALL_CONTENT_VIEW_ID}`,
						state: {
							severity: 'Critical',
							isTemporary: true
						}
					})
				}
			>
				{exploreTitle}
			</EvButton>
		</Box>
	);
};

export default QuickWin;
