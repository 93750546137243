import { SkipLinksProvider } from '@evinced-private/ui-common';

import CommonFiltersStore from '../stores/CommonFiltersStore';
import TablesStore from '../stores/TablesStore';

import { CommonFiltersProvider } from './commonFiltersProvider/CommonFiltersProvider';
import NotificationsProvider from './notificationsProvider/NotificationsProvider';
import { TablesStoreProvider } from './tablesProvider/TablesProvider';
import { UserTenantProvider } from './userTenantProvider/UserTenantProvider';
import { combineComponents } from './utils/CombineProviders';
import {
	initialPageScrollPosition,
	PageScrollPositionProvider } from './PageScrollPositionProvider';

const commonFiltersStore = new CommonFiltersStore();

const tablesStore = new TablesStore();

const providers = [
	{ Component: SkipLinksProvider },
	{ Component: NotificationsProvider },
	{ Component: UserTenantProvider },
	{ Component: PageScrollPositionProvider, value: { state: initialPageScrollPosition } },
	{ Component: TablesStoreProvider, value: { store: tablesStore } },
	{ Component: CommonFiltersProvider, value: { store: commonFiltersStore } }
];
export const AppProvider = combineComponents(...providers);
