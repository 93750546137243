import _ from 'lodash';

import { OptionType } from '@evinced-private/ui-common';

import { IProperty } from '../interfaces/Property';
import { IScopeRule } from '../interfaces/ScopeRule';
import { IScopeRuleUIFormat } from '../interfaces/ScopeRuleUIFormat';
import {
	IAllowedDeniedFilter,
	IUrlSetDiscoveryConfiguration
} from '../interfaces/UrlSetDiscoveryConfiguration';
import logger from '../services/Logger';
import SCOPE_ACTIONS from '../types/scope/ScopeActions';
import SCOPE_TYPES from '../types/scope/ScopeTypes';

import UUIDHelper from './UUIDHelper';

const getDetailedRuleList = (
	urlSetDiscoveryConfiguration: IUrlSetDiscoveryConfiguration,
	type: SCOPE_TYPES,
	action: SCOPE_ACTIONS
): string[] => {
	if (!urlSetDiscoveryConfiguration) {
		return [];
	}

	if (type === SCOPE_TYPES.LANG) {
		if (!urlSetDiscoveryConfiguration.languageFilter) {
			return [];
		}
		return action === SCOPE_ACTIONS.INCLUDE
			? urlSetDiscoveryConfiguration?.languageFilter?.allowed || []
			: urlSetDiscoveryConfiguration?.languageFilter?.denied || [];
	}
	if (type === SCOPE_TYPES.URL) {
		return action === SCOPE_ACTIONS.INCLUDE
			? urlSetDiscoveryConfiguration?.urlPatternFilter?.allowed || []
			: urlSetDiscoveryConfiguration?.urlPatternFilter?.denied || [];
	}
	if (type === SCOPE_TYPES.DOMAIN) {
		return action === SCOPE_ACTIONS.INCLUDE
			? urlSetDiscoveryConfiguration?.domainFilter?.allowed || []
			: urlSetDiscoveryConfiguration?.domainFilter?.denied || [];
	}
	logger.error('error in passed type', type, action);
	return [];
};

const getAllRules = (urlSetDiscoveryConfiguration: IUrlSetDiscoveryConfiguration): IScopeRule[] => {
	// mapping url rules
	const allowedLangRules = getDetailedRuleList(
		urlSetDiscoveryConfiguration,
		SCOPE_TYPES.LANG,
		SCOPE_ACTIONS.INCLUDE
	).map((url) => ({
		url,
		scopeAction: SCOPE_ACTIONS.INCLUDE,
		scopeType: SCOPE_TYPES.LANG
	}));

	const allowedUrlRules = getDetailedRuleList(
		urlSetDiscoveryConfiguration,
		SCOPE_TYPES.URL,
		SCOPE_ACTIONS.INCLUDE
	).map((url) => ({
		url,
		scopeAction: SCOPE_ACTIONS.INCLUDE,
		scopeType: SCOPE_TYPES.URL
	}));

	const allowedDomainRules = getDetailedRuleList(
		urlSetDiscoveryConfiguration,
		SCOPE_TYPES.DOMAIN,
		SCOPE_ACTIONS.INCLUDE
	).map((url) => ({
		url,
		scopeAction: SCOPE_ACTIONS.INCLUDE,
		scopeType: SCOPE_TYPES.DOMAIN
	}));

	const deniedLangRules = getDetailedRuleList(
		urlSetDiscoveryConfiguration,
		SCOPE_TYPES.LANG,
		SCOPE_ACTIONS.EXCLUDE
	).map((url) => ({
		url,
		scopeAction: SCOPE_ACTIONS.EXCLUDE,
		scopeType: SCOPE_TYPES.LANG
	}));

	const deniedUrlRules = getDetailedRuleList(
		urlSetDiscoveryConfiguration,
		SCOPE_TYPES.URL,
		SCOPE_ACTIONS.EXCLUDE
	).map((url) => ({
		url,
		scopeAction: SCOPE_ACTIONS.EXCLUDE,
		scopeType: SCOPE_TYPES.URL
	}));

	const deniedDomainRules = getDetailedRuleList(
		urlSetDiscoveryConfiguration,
		SCOPE_TYPES.DOMAIN,
		SCOPE_ACTIONS.EXCLUDE
	).map((url) => ({
		url,
		scopeAction: SCOPE_ACTIONS.EXCLUDE,
		scopeType: SCOPE_TYPES.DOMAIN
	}));
	return [
		...allowedDomainRules,
		...deniedDomainRules,
		...allowedUrlRules,
		...deniedUrlRules,
		...allowedLangRules,
		...deniedLangRules
	];
};

const getAllRulesInUIFormat = (
	urlSetDiscoveryConfiguration: IUrlSetDiscoveryConfiguration
): IScopeRuleUIFormat[] => {
	const allRules: IScopeRule[] = getAllRules(urlSetDiscoveryConfiguration);
	// eslint-disable-next-line prefer-object-spread
	return allRules.map((r) => Object.assign({ ...r }, { id: UUIDHelper.generateUUID() }));
};

// creates an default rule per type
const createDefaultRuleInUIFormat = (
	scopeType: SCOPE_TYPES,
	issuesList?: OptionType[]
): IScopeRuleUIFormat => {
	const rule: IScopeRuleUIFormat = {
		id: UUIDHelper.generateUUID(),
		url: '',
		scopeType,
		scopeAction: SCOPE_ACTIONS.INCLUDE
	};

	if (issuesList?.length) {
		rule.issueTypes = issuesList.map((issue) => issue.label);
	}
	return rule;
};

const getAllowedDeniedRules = (
	rules: IScopeRuleUIFormat[],
	type: SCOPE_TYPES
): IAllowedDeniedFilter => {
	return {
		allowed: rules
			.filter((r) => r.scopeType === type && r.scopeAction === SCOPE_ACTIONS.INCLUDE)
			.map((r) => r.url),
		denied: rules
			.filter((r) => r.scopeType === type && r.scopeAction === SCOPE_ACTIONS.EXCLUDE)
			.map((r) => r.url)
	};
};

const setDiscoveryScopeRulesToProperty = (
	property: IProperty,
	rules: IScopeRuleUIFormat[]
): IProperty => {
	const languageRules = getAllowedDeniedRules(rules, SCOPE_TYPES.LANG);
	const urlRegexRules = getAllowedDeniedRules(rules, SCOPE_TYPES.URL);
	const domainRules = getAllowedDeniedRules(rules, SCOPE_TYPES.DOMAIN);

	const propertyClone = _.merge(
		{ ...property },
		{
			urlSetConfiguration: {
				discoveryConfiguration: {}
			}
		}
	);
	propertyClone.urlSetConfiguration.discoveryConfiguration.languageFilter = languageRules;
	propertyClone.urlSetConfiguration.discoveryConfiguration.urlPatternFilter = urlRegexRules;
	propertyClone.urlSetConfiguration.discoveryConfiguration.domainFilter = domainRules;
	return propertyClone;
};

export default {
	getAllRules,
	getAllRulesInUIFormat,
	getDetailedRuleList,
	createDefaultRuleInUIFormat,
	setDiscoveryScopeRulesToProperty
};
