import React, { FunctionComponent, useCallback } from 'react';

import classNames from 'classnames';

import { EvSection, EvTitle } from '@evinced-private/ui-common';

import IdFormatterHelper from '../../../../helpers/IdFormatterHelper';
import SummaryPane from '../../../common/summary-pane/SummaryPane';

import './ChartsSection.scss';

export type SectionTextBlock = {
	title: string;
	rows: { label: string; value: string | number }[];
};

interface IChartsSectionProps {
	sectionTextBlock: SectionTextBlock;
	/**
	 * skiplinkId to be passed to the section, required for skiplinks
	 */
	skiplinkId: string;
	className?: string;
	renderTitleActions?: () => JSX.Element;
	children: JSX.Element | JSX.Element[];
}

const ChartsSection: FunctionComponent<IChartsSectionProps> = ({
	sectionTextBlock,
	children,
	className,
	renderTitleActions,
	skiplinkId
}: IChartsSectionProps) => {
	const rows = sectionTextBlock.rows || [];
	const sectionTitleId = IdFormatterHelper.formatTextToId(sectionTextBlock.title);
	const classes: string = classNames('charts-section', { [className]: !!className });

	const renderActionButtons = useCallback(
		() => renderTitleActions && <div className="actions">{renderTitleActions()}</div>,
		[renderTitleActions]
	);

	return (
		<EvSection className={classes} ariaLabelledby={sectionTitleId} skipLinkId={skiplinkId}>
			<div className="section-header">
				<EvTitle titleText={sectionTextBlock.title} className="section-title" id={sectionTitleId} />
			</div>

			<SummaryPane
				items={rows}
				sectionLabel={`${sectionTextBlock.title} - summary`}
				shouldHaveSkipLink={false}
				className="charts-summary-pane"
				renderActionButtons={renderActionButtons}
			/>

			{children}
		</EvSection>
	);
};

export default ChartsSection;
