import React, { FC } from 'react';

import classNames from 'classnames';

import './Box.scss';

interface IBox {
	className?: string;
}

const Box: FC<IBox> = ({ children, className }) => {
	return <div className={classNames('box', { [className]: !!className })}>{children}</div>;
};

export default Box;
