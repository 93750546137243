import { OptionType } from '@evinced-private/ui-common';

import { FilterParams } from '../types/FilterParams';

const URL_PARAMS_NAMES = {
	severities: 'severity',
	types: 'issueType',
	searchValue: 'freeSearch',
	components: 'component',
	urls: 'issueUrl',
	tags: 'tags'
};

type PaginationParams = {
	page: number;
	sizePerPage: number;
	sortField?: string;
	sortOrder?: string;
};

const getParamsValues = (arrOfSelectedValues): OptionType[] =>
	arrOfSelectedValues.map((val: OptionType) => encodeURIComponent(val.value));

const getSelectedValues = (arrOfSelectedValues, maxNumOfOptions): OptionType[] => {
	if (arrOfSelectedValues[0].value === '*' || maxNumOfOptions === arrOfSelectedValues.length) {
		return [];
	}
	return arrOfSelectedValues;
};

const getUrlParams = (
	arrOfSelectedValues: OptionType[],
	paramKeyName: string,
	maxNumOfOptions: number
): string => {
	const arrToConvert = getSelectedValues(arrOfSelectedValues, maxNumOfOptions);
	return arrToConvert.length > 0
		? `&${paramKeyName}=${getParamsValues(arrToConvert).join(',')}`
		: '';
};

const createFiltersUrlParams = ({ filterValues, filterOptions }): string => {
	if (!filterValues) {
		return '';
	}
	return Object.keys(filterValues)
		.reduce((res, filterKey) => {
			if (!filterValues[filterKey] || filterValues[filterKey].length === 0) {
				return [...res];
			}
			if (typeof filterValues[filterKey] === 'string') {
				return [
					...res,
					`&${URL_PARAMS_NAMES[filterKey]}=${encodeURIComponent(filterValues[filterKey])}`
				];
			}
			return [
				...res,
				getUrlParams(
					filterValues[filterKey],
					URL_PARAMS_NAMES[filterKey],
					filterOptions?.[filterKey]?.length
				)
			];
		}, [])
		.join('');
};

const requestParamsToObject = (stringParams: string): object => {
	const paramsArray = stringParams.split('&');
	const params = paramsArray.reduce((res, value) => {
		const paramKeyValue = value.split('=');
		if (paramKeyValue.length < 2) {
			return { ...res };
		}
		const paramKey = paramKeyValue[0];
		if (URL_PARAMS_NAMES.searchValue === paramKey) {
			return { ...res, [paramKey]: decodeURIComponent(paramKeyValue[1]) };
		}
		const paramVal = paramKeyValue[1].split(',');
		return { ...res, [paramKey]: paramVal.map((val) => decodeURIComponent(val)) };
	}, {});
	return params;
};

const createSortUrlParams = (paginationParams?: PaginationParams): string => {
	if (!paginationParams) {
		return '';
	}
	const { page, sizePerPage, sortField, sortOrder } = paginationParams;
	let params = '';
	if (page) {
		params += `&pageNumber=${page - 1}`;
	}
	if (sizePerPage) {
		params += `&pageSize=${sizePerPage}`;
	}
	if (sortField) {
		params += `&sortField=${sortField}`;
	}
	if (sortOrder) {
		params += `&sortDirection=${sortOrder.toUpperCase()}`;
	}
	return params;
};

const createUrlParams = (
	paginationParams?: PaginationParams,
	filtersParams?: FilterParams
): string => {
	const filtersRequestStr = filtersParams?.filterValues
		? createFiltersUrlParams(filtersParams)
		: '';
	const paginationRequestStr = createSortUrlParams(paginationParams);
	return `${paginationRequestStr}${filtersRequestStr}`;
};

export default {
	requestParamsToObject,
	createFiltersUrlParams,
	createSortUrlParams,
	createUrlParams
};
