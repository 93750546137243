import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { EVINCED_SEVERITIES, EvSpinner, FormatHelper } from '@evinced-private/ui-common';

import ChartsHelper from '../../helpers/ChartsHelper';
import ScanAndPropertyResultsHelper from '../../helpers/ScanAndPropertyResultsHelper';
import Logger from '../../services/Logger';
import scanService, { ScanOverviewResponse } from '../../services/scan/ScanService';
import CriticalComponentsRaceBar from '../charts/bars/CriticalComponentsRaceBar';
import PagesBySeverityBarV2 from '../charts/bars/PagesBySeverityBarV2';
import SummaryPane from '../common/summary-pane/SummaryPane';
import ErrorRect from '../icons/ErrorRect.svg';
import TrendsNoDataIndication from '../trends-no-data-indication/TrendsNoDataIndication';

import './SingleScanTrendsScreen.scss';

interface ISingleScanChartsScreen {
	scanId: string;
	propertyId: string;
	onBarItemClick: (scanId: string, severity: string) => void;
	viewId?: string;
}

const SingleScanChartsScreen: FC<ISingleScanChartsScreen> = ({
	scanId,
	propertyId,
	onBarItemClick,
	viewId
}: ISingleScanChartsScreen) => {
	const [errorLoading, setErrorLoading] = useState(false);
	const [scanOverview, setScanOverview] = useState<ScanOverviewResponse>(null);

	const mainClasses = 'single-scan-trends-screen';
	const totalCriticalIssues = useMemo(
		() =>
			ScanAndPropertyResultsHelper.getIssuesCount(
				scanOverview?.issues,
				EVINCED_SEVERITIES.CRITICAL
			),
		[scanOverview]
	);

	const loadScanOverview = useCallback(async (): Promise<void> => {
		try {
			const scanOverview = await scanService.getScanOverview(scanId, viewId);
			setScanOverview(scanOverview);
		} catch (error) {
			Logger.error('Failed getting scan overview', error);
			setErrorLoading(true);
		}
	}, [scanId, viewId]);

	const componentsWithCritical = scanOverview
		? ChartsHelper.getComponentsWithCriticalV2(scanOverview.components)
		: null;

	useEffect(() => {
		loadScanOverview();
	}, [loadScanOverview]);

	if (errorLoading) {
		return (
			<div className={mainClasses}>
				<TrendsNoDataIndication customIcon={ErrorRect} />
			</div>
		);
	}
	if (!scanOverview) {
		return (
			<div className={mainClasses}>
				<EvSpinner />
			</div>
		);
	}

	const summaryPaneItems = [
		{
			value: FormatHelper.formatNumber(scanOverview.pages.overview.pagesWithIssues),
			label: 'Pages scanned'
		},
		{ value: FormatHelper.formatNumber(scanOverview.issues.issuesCount), label: 'Number of issues' }
	];

	return (
		<div className={mainClasses}>
			<div
				className={classNames('upper-charts', {
					'without-racebar': !totalCriticalIssues
				})}
			>
				<div className="summary">
					<label>Last scan results</label>
					<SummaryPane
						items={summaryPaneItems}
						shouldHaveSkipLink={false}
						className="single-scan-charts"
					/>
				</div>
				<div className="severity-chart-wrapper">
					<PagesBySeverityBarV2
						pagesSeverities={scanOverview.pages.severities}
						pagesScanned={scanOverview.pages.overview.scanned}
						chartHeight={260}
						chartWidth={450}
						fitWidthToContent={false}
						onBarItemClick={onBarItemClick}
					/>
				</div>
				<CriticalComponentsRaceBar
					className="single-scan-trends-racebar"
					// TODO: when componentsWithCritical is 0 it won't render
					componentsWithCritical={componentsWithCritical}
					totalCriticalIssues={totalCriticalIssues}
					scanId={scanId}
					propertyId={propertyId}
					maxHeight={242}
				/>
			</div>
			<div className="lower-empty-state">
				<TrendsNoDataIndication
					title="No trends available"
					renderTextMessage={() => (
						<>
							The property should include at least two scans in order to <br /> review accessibility
							trends over time.
						</>
					)}
				/>
			</div>
		</div>
	);
};

export default SingleScanChartsScreen;
