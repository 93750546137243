import React, { FC, useCallback, useMemo, useState } from 'react';

import { BUTTON_TYPES, EvBrowseFiles, EvConfirm, EvIcon } from '@evinced-private/ui-common';

import { SITE_SCANNER_APP_ID } from '../../../consts/dom-consts';
import SeedsListHelper from '../../../helpers/SeedsListHelper';
import UrlNormalizingHelper from '../../../helpers/UrlNormalizingHelper';
import ErrorMsgMark from '../../icons/ErrorMsgMark.svg';
import InfoMsgMark from '../../icons/InfoMsgMark.svg';

import './PropertyImportUrlsButton.scss';

const fileMaxSizeToImport = 10000000; // 10 MB
const fileIsTooBigMsg = 'File is too big (up to 10MB).';
const popUpMsg = 'Start by uploading a single column CSV file with the new URLs.';
const confirmButtonTitle = 'Import URLs';

interface IPropertyImportUrlsButton {
	onConfirm: (closeModal: () => void, fileContent: string[]) => void;
	importing: boolean;
	validHeader?: string;
}

const PropertyImportUrlsButton: FC<IPropertyImportUrlsButton> = ({
	onConfirm,
	importing,
	validHeader = ''
}) => {
	const [disableImportBtn, setDisableImportBtn] = useState(true);
	const [showErrMgs, setShowErrMsg] = useState(false);
	const [validCount, setValidCount] = useState<number>(0);
	const [errCount, setErrCount] = useState<number>(0);
	const [content, setContent] = useState<string[]>([]);
	const fileReader = useMemo(() => new FileReader(), []);

	const errorFoundMsg = useMemo(
		() => `${validCount} URLs were found and ${errCount} errors.`,
		[validCount, errCount]
	);

	const isValidHeader = useCallback(
		(fileHeader): boolean => {
			return fileHeader.trim().toLocaleLowerCase() === validHeader.trim().toLocaleLowerCase();
		},
		[validHeader]
	);

	const handleFileRead = useCallback((): void => {
		const fileContent = fileReader.result;
		const fileContentArr = fileContent
			.toString()
			.split(/\r\n|\r|\n/)
			.filter((line) => line) // remove empty lines
			.map((urlStr) => UrlNormalizingHelper.formatURL(urlStr));
		if (isValidHeader(fileContentArr[0])) {
			fileContentArr.shift();
		}
		const { validUrls } = SeedsListHelper.getUrlsFromCSV(fileContentArr);
		setValidCount(validUrls.length);
		/* error count = total browsed from minus valid new seeds */
		setDisableImportBtn(validUrls.length < 1);
		const numberOfErrors = fileContentArr.length - validUrls.length;
		setErrCount(numberOfErrors);
		setShowErrMsg(numberOfErrors > 0);
		setContent(fileContentArr);
	}, [fileReader.result, isValidHeader]);

	const onFileBrowse = useCallback(
		({ browsedFiles }): void => {
			setContent([]);
			setShowErrMsg(false);
			const fileBrowsed = browsedFiles[0];
			const fileTooBig = fileBrowsed.size > fileMaxSizeToImport;
			setDisableImportBtn(fileTooBig);
			setShowErrMsg(fileTooBig);
			if (!fileTooBig) {
				fileReader.onloadend = handleFileRead;
				fileReader.readAsText(fileBrowsed);
			}
		},
		[fileReader, handleFileRead]
	);

	const renderErrMsg = useCallback(
		(): JSX.Element => (
			<div className="file-msg">
				<EvIcon className="msg-icon" icon={ErrorMsgMark} />
				{errCount > 0 ? errorFoundMsg : fileIsTooBigMsg}
			</div>
		),
		[errCount, errorFoundMsg]
	);

	const renderNumberOfLinesMsg = useCallback(
		(): JSX.Element => (
			<div className="file-msg">
				<EvIcon className="msg-icon" icon={InfoMsgMark} />
				{`${content.length} URLs were found.`}
			</div>
		),
		[content]
	);

	const renderImportUrlPrompt = useCallback(
		() => (
			<div className="prompt">
				<div className="main-msg">{popUpMsg}</div>
				<div className="browse-container">
					<div className="file-browser">
						<EvBrowseFiles fileTypes=".csv" onChange={onFileBrowse} />
					</div>
					{showErrMgs && renderErrMsg()}
					{!showErrMgs && content.length > 0 && renderNumberOfLinesMsg()}
				</div>
			</div>
		),
		[showErrMgs, onFileBrowse, content, renderNumberOfLinesMsg, renderErrMsg]
	);

	return (
		<div className="import-urls-button">
			<EvConfirm
				appElement={SITE_SCANNER_APP_ID}
				title={confirmButtonTitle}
				promptMessage={renderImportUrlPrompt()}
				approveButtonText="Import"
				disabled={disableImportBtn}
				className="import-urls-button"
				onClose={(closeModal) => {
					setShowErrMsg(false);
					setContent([]);
					setDisableImportBtn(true);
					if (closeModal) {
						closeModal();
					}
				}}
				triggerButtonProps={{
					type: BUTTON_TYPES.ACTION,
					title: confirmButtonTitle,
					disabled: importing,
					// eslint-disable-next-line react/jsx-no-useless-fragment
					children: <>{confirmButtonTitle}</>
				}}
				onConfirm={(closeModal) => onConfirm(closeModal, content)}
			/>
		</div>
	);
};

export default PropertyImportUrlsButton;
