import React, {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { ALL_CONTENT_VIEW } from '../components/common/ev-property-views/consts/PropertyViews';
import {
	createOrUpdateSelectedView,
	getCurrentView,
	getSearchParams,
	getSelectedViewIdFromServer
} from '../helpers/PropertyViewsHelper';
import RoutesHelper from '../helpers/RoutesHelper';
import { IPropertyView } from '../interfaces/PropertyViews';
import Logger from '../services/Logger';
import PropertyViewsService from '../services/PropertyViewsService';

import { useNotifications } from './notificationsProvider/NotificationsConsumer';

export interface IPropertyViewContext {
	propertyViews: IPropertyView[];
	selectedView: IPropertyView;
	currentView: IPropertyView;
	loadViewErr: boolean;
	updateViews: (views: IPropertyView[]) => void;
	updateSelectedView: (view: IPropertyView) => Promise<void>;
	onViewsChange: () => Promise<void>;
}

const PropertyViewContext = createContext<IPropertyViewContext>({} as IPropertyViewContext);
export const useProperyViewContext = (): IPropertyViewContext => useContext(PropertyViewContext);

const INVALID_SUFIX_PATH: string[] = ['/settings', '/views', '/urlset'];

const PropertyViewProvider: FC = ({ children }) => {
	const location = useLocation();
	const history = useHistory();
	const { propertyId } = useParams<{ [key: string]: string }>();
	const notificationsContext = useNotifications();
	const isRendered = useRef<boolean>(false);
	const [propertyViews, setPropertyViews] = useState<IPropertyView[]>([]);
	const [selectedView, setSelectedView] = useState<IPropertyView>(null);
	const [currentView, setCurrentView] = useState<IPropertyView>(null);
	const [loadViewErr, setLoadViewErr] = useState<boolean>(false);

	const currrentPath = useMemo(() => RoutesHelper.getPropertyPagePath(propertyId), [propertyId]);
	const excludedPath = useCallback(
		(): boolean =>
			INVALID_SUFIX_PATH.some((sufix) => location.pathname.includes(`${currrentPath}${sufix}`)),
		[currrentPath, location]
	);

	const onViewsChange = useCallback(async (): Promise<void> => {
		const views: IPropertyView[] = await PropertyViewsService.getViews(propertyId);
		setPropertyViews(views);
	}, [propertyId]);

	const updateViews = (views: IPropertyView[]): void => {
		setPropertyViews(views);
	};

	const updateUrlWithViewId = useCallback(
		(selectedView: IPropertyView = null) => {
			if (!excludedPath()) {
				const searchParams = getSearchParams(location, selectedView?.id);
				const currentSearchParams = location.search.replace(/^\?/, '');
				if (searchParams !== currentSearchParams) {
					history.replace({ search: searchParams, state: location.state });
				}
			}
		},
		[location, history, excludedPath]
	);

	const updateSelectedView = useCallback(
		async (view: IPropertyView): Promise<void> => {
			if (view.id !== currentView?.id) {
				try {
					await createOrUpdateSelectedView(propertyId, view.id);
					updateUrlWithViewId(view);
					setSelectedView(view);
					setCurrentView(view);
					if (loadViewErr) {
						history.go(0);
					}
				} catch (err) {
					Logger.error('Failed to set selected in server');
				}
			}
		},
		[propertyId, updateUrlWithViewId, currentView, history, loadViewErr]
	);

	const onLoad = useCallback(async (): Promise<void> => {
		isRendered.current = true;
		try {
			// 1. Get Views
			const views: IPropertyView[] = await PropertyViewsService.getViews(propertyId);
			setPropertyViews(views);

			// 2. Get Selected View from Server
			const selectedViewId: string = await getSelectedViewIdFromServer(propertyId);
			const savedSelectedView: IPropertyView =
				views.find((view) => view.id === selectedViewId) || ALL_CONTENT_VIEW;

			// 3. Decide which view should be selected
			const [currentSelected, invalidView] = getCurrentView(location, views, savedSelectedView);

			if (!invalidView) {
				updateUrlWithViewId(currentSelected);
				setCurrentView(currentSelected);
			} else if (currentSelected) {
				// case in which the requested view is invalid there are other views to show
				setLoadViewErr(true);
			} else {
				// there are no views to show - back to properties
				notificationsContext.alert({
					errorMessage: 'Error loading this property, redirecting to properties page',
					onOK: () => history.push(RoutesHelper.getPropertiesPagePath())
				});
			}
		} catch (err) {
			const requestUrl: string = err.response.request.responseURL;
			let errorMessage = 'Error occurred, redirecting to properties page';

			if (requestUrl.includes('/views')) {
				errorMessage = 'Error loading views, redirecting to properties page';
			}
			if (requestUrl.includes('/view-selection')) {
				errorMessage = 'Error loading selected view, redirecting to properties page';
			}
			notificationsContext.alert({
				errorMessage,
				serverError: err,
				onOK: () => history.push(RoutesHelper.getPropertiesPagePath())
			});
			Logger.error(errorMessage, err);
		}
	}, [location, propertyId, updateUrlWithViewId, notificationsContext, history]);

	useEffect(() => {
		onLoad();
	}, [onLoad]);

	const renderContent = useCallback(
		() => (isRendered.current ? children : null),
		[children, isRendered]
	);

	const value = useMemo(() => {
		return {
			propertyViews,
			selectedView,
			currentView,
			loadViewErr,
			updateViews,
			updateSelectedView,
			onViewsChange
		};
	}, [propertyViews, selectedView, currentView, loadViewErr, updateSelectedView, onViewsChange]);
	return (
		<PropertyViewContext.Provider value={value}>{renderContent()}</PropertyViewContext.Provider>
	);
};

export default PropertyViewProvider;
