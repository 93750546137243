import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { FormatHelper } from '@evinced-private/ui-common';

import BaseScanView, {
	ScanTableDefinition
} from '../../../../components/scan-analysis/base/BaseScanViewV2';
import { MAX_RESULTS } from '../../../../consts/Results';
import { IComponentsPaginationResponse } from '../../../../interfaces/ComponentsPaginationResponse';
import { SummaryItem } from '../../../../interfaces/SummaryItem';
import scanService from '../../../../services/scan/ScanService';
import { FilterParams } from '../../../../types/FilterParams';
import { ComponentsPaneSummary } from '../../../../types/OverviewTypes';
import { PaginationParams } from '../../../../types/PaginationParams';
import ScanPagesTableHelperV2 from '../pages/ScanPagesTableHelperV2';

import ScanComponentsTableHelper from './ScanComponentsTableHelperV2';

interface IScanComponentsView {
	viewId: string;
	scanId: string;
	propertyId: string;
}

const defaultTableSort = ScanPagesTableHelperV2.getDefaultSort();

const ScanComponentsView: FC<IScanComponentsView> = ({
	viewId,
	scanId,
	propertyId
}: IScanComponentsView) => {
	const [totalCount, setTotalCount] = useState<number>(null);
	const [summaryPaneInfo, setSummaryPaneInfo] = useState<ComponentsPaneSummary>();
	const [scanResults, setScanResults] = useState<IComponentsPaginationResponse>(null);
	const screenId = `scan-${scanId}-view-${viewId}-components-view`;

	const loadTableItems = useCallback(
		async (
			scanId: string,
			paginationParams: PaginationParams,
			filtersParams?: FilterParams
		): Promise<void> => {
			const response: IComponentsPaginationResponse = await scanService.getScanComponentsV2(
				scanId,
				paginationParams,
				filtersParams,
				viewId
			);
			setScanResults(response);
		},
		[viewId]
	);

	useEffect(() => {
		if (scanResults?.overallTotal) {
			setTotalCount(scanResults.overallTotal);
		}
	}, [scanResults?.overallTotal]);

	const loadSummaryData = useCallback(async (): Promise<void> => {
		const summaryPaneInfo: ComponentsPaneSummary = await scanService.getComponentsSummaryV2(
			scanId,
			viewId
		);
		setSummaryPaneInfo(summaryPaneInfo);
	}, [scanId, viewId]);

	const summaryPaneData = useMemo((): SummaryItem[] => {
		if (!summaryPaneInfo) {
			return [];
		}
		const { totalGroupedIssues, totalIssues, totalComponents } = summaryPaneInfo;
		return [
			{
				value: `${FormatHelper.formatNumber(totalGroupedIssues)} / ${FormatHelper.formatNumber(
					totalIssues
				)}`,
				label: 'Grouped issues / Total issues'
			},
			{
				value: FormatHelper.formatNumber(totalComponents),
				label: 'Components'
			}
		];
	}, [summaryPaneInfo]);

	const tableDefinition = useMemo((): ScanTableDefinition => {
		if (!scanResults) {
			return null;
		}

		const { components } = scanResults;

		const totalPaginationableResults =
			scanResults.overallTotal > MAX_RESULTS ? MAX_RESULTS : scanResults.overallTotal;
		return ScanComponentsTableHelper.getTableDefinition(
			components,
			propertyId,
			scanId,
			totalCount,
			totalPaginationableResults,
			screenId
		);
	}, [scanResults, totalCount, propertyId, scanId, screenId]);

	return (
		<BaseScanView
			scanId={scanId}
			screenId={screenId}
			summaryPaneItems={summaryPaneData}
			tableDefinition={tableDefinition}
			defaultTableSort={defaultTableSort}
			loadSummaryData={loadSummaryData}
			loadTableItems={loadTableItems}
		/>
	);
};

export default ScanComponentsView;
