import React, { FC } from 'react';

import { BUTTON_TYPES } from '@evinced-private/ui-common';

import ScanCrawlStateHelper from '../../helpers/ScanCrawlStateHelper';
import ScanCrawlTextHelper from '../../helpers/ScanCrawlTextHelper';
import { IScan } from '../../interfaces/Scan';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import JobStatusAction from '../job-status-action/JobStatusAction';

import './PropertyJobsStatusAction.scss';

interface IPropertyJobsStatusActionProps {
	onDeleteScan: (id: string) => Promise<void>;
	lastScan?: IScan;
}

const PropertyJobsStatusAction: FC<IPropertyJobsStatusActionProps> = ({
	lastScan,
	onDeleteScan
}) => {
	const { isReadOnlyUser } = useUserTenant();

	const onCancelScan = async (): Promise<void> => {
		await onDeleteScan(lastScan.id);
	};

	const isScanRunning = ScanCrawlStateHelper.isInProgress(lastScan?.state);

	if (!isScanRunning) {
		return null;
	}

	const getScanStatus = (): JSX.Element => {
		const title = ScanCrawlTextHelper.getPopupCancelTitle('scan');
		const promptMessage = ScanCrawlTextHelper.getPopupCancelMessage('scan');
		const confirmButtonText = ScanCrawlTextHelper.getConfirmButtonText('scan');
		const jobsStatusText = ScanCrawlTextHelper.getJobStatus('scan');
		return (
			<JobStatusAction
				popupTitle={title}
				confirmButtonText={confirmButtonText}
				jobsStatusText={jobsStatusText}
				onConfirm={onCancelScan}
				promptMessage={promptMessage}
				triggerButtonProps={{
					type: BUTTON_TYPES.LINK,
					title: 'Cancel',
					children: 'Cancel'
				}}
				isPopupOpen={!isReadOnlyUser()}
			/>
		);
	};

	return (
		<div className="property-jobs-status-action">{isScanRunning ? getScanStatus() : null}</div>
	);
};

export default PropertyJobsStatusAction;
