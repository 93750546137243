import React, { FC, useCallback, useEffect, useState } from 'react';

import {
	BUTTON_TYPES,
	EvButton,
	EvConfirm,
	EvIcon,
	EvSection,
	EvSelect,
	EvTextInput,
	EvTitle
} from '@evinced-private/ui-common';

import { SITE_SCANNER_APP_ID } from '../../../../consts/dom-consts';
import domainInputPlaceholder from '../../../../consts/domainPlaceholderText';
import urlDiscoveryScopeHelper from '../../../../helpers/UrlDiscoveryScopeHelper';
import { IProperty } from '../../../../interfaces/Property';
import { IPropertyError } from '../../../../interfaces/PropertyError';
import { IScopeRuleUIFormat } from '../../../../interfaces/ScopeRuleUIFormat';
import { SetPropertyType } from '../../../../pages/property-settings/PropertySettingsPageTypes';
import { OptionType } from '../../../../types/OptionType';
import SCOPE_ACTIONS from '../../../../types/scope/ScopeActions';
import SCOPE_TYPES from '../../../../types/scope/ScopeTypes';
import RemoveIcon from '../../../icons/RemoveIcon.svg';

import './ScopeSettings.scss';

const SCOPE_SETTINGS_TITLE = 'scope-settings-title';
const SCOPE_SETTINGS_DESCRIPTION = 'scope-settings-description';

interface IScopeSettingsProps {
	property: IProperty;
	propertyError?: IPropertyError;
	setProperty: SetPropertyType;
}

const ScopeSettings: FC<IScopeSettingsProps> = ({
	property,
	propertyError,
	setProperty
}: IScopeSettingsProps) => {
	// In this class we treat the rules as if they all were in one list.
	// to do that - we add an ID for each rule, that will identify a rule for editing
	// or deleting purposes.
	// before saving rules to the server, we format them back to the model saved on the server side
	const [allRules, setAllRules] = useState<IScopeRuleUIFormat[]>(
		urlDiscoveryScopeHelper.getAllRulesInUIFormat(
			property.urlSetConfiguration.discoveryConfiguration
		)
	);
	const removeScopeRule = (ruleIdToDelete: string): void => {
		setAllRules([...allRules.filter((r) => r.id !== ruleIdToDelete)]);
	};

	const addScopeRule = (scopeType: SCOPE_TYPES): void => {
		const newRule: IScopeRuleUIFormat =
			urlDiscoveryScopeHelper.createDefaultRuleInUIFormat(scopeType);
		setAllRules([...allRules, newRule]);
	};

	const editScopeRuleAction = (id: string, action: SCOPE_ACTIONS): void => {
		const newRules = [...allRules];
		newRules.find((r) => r.id === id).scopeAction = action;
		setAllRules(newRules);
	};

	const editScopeRuleTextValue = (id: string, value: string): void => {
		const newRules = [...allRules];
		newRules.find((r) => r.id === id).url = value;
		setAllRules(newRules);
	};

	const setDiscoveryScopeAndSaveProperty = useCallback(() => {
		setProperty((property: IProperty): IProperty => {
			return urlDiscoveryScopeHelper.setDiscoveryScopeRulesToProperty(property, allRules);
		});
	}, [allRules, setProperty]);

	// When rules array is changed, change the model as well
	useEffect(() => {
		setDiscoveryScopeAndSaveProperty();
	}, [setDiscoveryScopeAndSaveProperty]);

	const renderScopeInfo = (): JSX.Element => {
		return (
			<>
				As Evinced crawls properties, it can get to other internal properties as well as external
				sites (e.g. Twitter, LinkedIn etc.). <br />
				URL rules are applied on URLs and domains while Language rules operate on the HTML language
				tag.
				<br />
				Use regular expressions (Regex) rules to control the crawler.
				<br />
				<br />
				<b>Include:</b> A Regex to define the domains, subdomains, URLs or languages to be included
				in the scope of the property. By default, at least one Include URL Regex must exist. <br />
				<br />
				<b>Exclude:</b> Enables you to exclude domains, subdomains, URLs or languages from the above
				property scope.
			</>
		);
	};

	const renderDeleteRuleConfirmPopup = (ruleId: string): JSX.Element => {
		return (
			<EvConfirm
				appElement={SITE_SCANNER_APP_ID}
				title="Delete rule"
				triggerButtonProps={{
					type: BUTTON_TYPES.ICON,
					title: 'Delete rule',
					className: 'rule-action',
					children: <EvIcon icon={RemoveIcon} />
				}}
				approveButtonText="Delete"
				promptMessage="Are you sure you want to delete this rule?"
				onConfirm={(closeModal) => {
					removeScopeRule(ruleId);
					closeModal();
				}}
			/>
		);
	};

	const getDropDownOptions = (type: SCOPE_TYPES): OptionType[] => {
		if (type === SCOPE_TYPES.URL) {
			return [
				{
					label: 'Include Regex',
					value: SCOPE_ACTIONS.INCLUDE
				},
				{
					label: 'Exclude Regex',
					value: SCOPE_ACTIONS.EXCLUDE
				}
			];
		}
		if (type === SCOPE_TYPES.LANG) {
			return [
				{
					label: 'Include Language',
					value: SCOPE_ACTIONS.INCLUDE
				},
				{
					label: 'Exclude Language',
					value: SCOPE_ACTIONS.EXCLUDE
				}
			];
		}
		return [
			{
				label: 'Include Domain',
				value: SCOPE_ACTIONS.INCLUDE
			},
			{
				label: 'Exclude Domain',
				value: SCOPE_ACTIONS.EXCLUDE
			}
		];
	};

	const renderOptionsDropdown = (rule: IScopeRuleUIFormat): JSX.Element => {
		const options: OptionType[] = getDropDownOptions(rule.scopeType);
		const selectedOption = options.find((o) => o.value === rule.scopeAction);
		return (
			<EvSelect
				isMulti={false}
				isSearchable={false}
				placeholder="Select Regex type"
				value={[selectedOption]}
				options={options}
				onChange={(selectedItem) => {
					editScopeRuleAction(rule.id, selectedItem.value);
				}}
			/>
		);
	};

	const getAriaLabel = (type: SCOPE_TYPES): string => {
		if (type === SCOPE_TYPES.DOMAIN) {
			return 'Domain';
		}
		if (type === SCOPE_TYPES.URL) {
			return 'URL Regex';
		}
		return 'Language';
	};

	const getInputPlaceholder = (type: SCOPE_TYPES): string => {
		if (type === SCOPE_TYPES.DOMAIN) {
			return domainInputPlaceholder;
		}
		if (type === SCOPE_TYPES.URL) {
			return 'e.g. ^https?://.*.example.com(/.*)?$';
		}
		return 'e.g. en-US';
	};

	const renderTextInput = (rule: IScopeRuleUIFormat): JSX.Element => {
		return (
			<EvTextInput
				autoFocus
				placeholder={getInputPlaceholder(rule.scopeType)}
				ariaLabel={getAriaLabel(rule.scopeType)}
				value={rule.url}
				onChange={(value) => editScopeRuleTextValue(rule.id, value)}
				error={rule.urlError}
			/>
		);
	};

	const renderRules = (): JSX.Element => {
		return (
			<div className="rules">
				{allRules.map((rule: IScopeRuleUIFormat): JSX.Element => {
					return (
						<div className="rule-line" key={rule.id}>
							<label className="form-label">{renderOptionsDropdown(rule)}</label>
							<div className="form-field">{renderTextInput(rule)}</div>
							<div className="rule-actions">{renderDeleteRuleConfirmPopup(rule.id)}</div>
						</div>
					);
				})}
			</div>
		);
	};

	const renderActions = (): JSX.Element => {
		return (
			<div className="actions">
				<div className="single-action">
					<EvButton
						title="Add Domain Rule"
						onClick={() => {
							addScopeRule(SCOPE_TYPES.DOMAIN);
						}}
						type={BUTTON_TYPES.ACTION}
					>
						Add Domain
					</EvButton>
				</div>
				<div className="single-action">
					<EvButton
						title="Add language"
						onClick={() => {
							addScopeRule(SCOPE_TYPES.LANG);
						}}
						type={BUTTON_TYPES.ACTION}
					>
						Add language
					</EvButton>
				</div>
				<div className="single-action">
					<EvButton
						title="Add Regex rule"
						onClick={() => {
							addScopeRule(SCOPE_TYPES.URL);
						}}
						type={BUTTON_TYPES.ACTION}
					>
						Add Regex rule
					</EvButton>
				</div>
			</div>
		);
	};

	const renderHeader = (): JSX.Element => {
		return (
			<div className="section-header">
				<div className="title">
					<EvTitle
						titleText="Scope"
						MoreInfoContent={renderScopeInfo()}
						id={SCOPE_SETTINGS_TITLE}
						descriptionId={SCOPE_SETTINGS_DESCRIPTION}
					/>
					{renderActions()}
				</div>
			</div>
		);
	};

	const renderError = (): JSX.Element => {
		return propertyError && <div className="tab-error">{propertyError.message}</div>;
	};

	return (
		<EvSection
			className="scope-settings-section"
			ariaLabelledby={SCOPE_SETTINGS_TITLE}
			ariaDescribedby={SCOPE_SETTINGS_DESCRIPTION}
		>
			<div className="scope-settings-content">
				{renderHeader()}
				<div className="section-body">
					{renderRules()}
					{renderError()}
				</div>
			</div>
		</EvSection>
	);
};

export default ScopeSettings;
