import React, { FC, useCallback, useEffect, useState } from 'react';

import {
	EvChartTooltip,
	EvLoader,
	EvPieChart,
	PieDatum,
	PieTooltipProps
} from '@evinced-private/ui-common';

import ErrorLoadPageIcon from '../../../../components/icons/ErrorLoadPageIcon.svg';
import DashboardHelper from '../../../../helpers/dashboard/DashboardHelper';
import { useDashboard } from '../../../../providers/dashboard/DashboardProvider';
import LoadErrorMsgLayout from '../../../property/LoadErrorMsgLayout';

import './ScoreAcrossProperties.scss';

const DEFAULT_CHART_HEIGHT = 270;
const DEFAULT_CHART_WIDTH = 420;
const LABEL_FIELD = 'label';
const SCORE_FIELD = 'score';
const PERCENT_FIELD = 'percent';
const PERFORMANCE = 'Performance';
const SCORE = 'Score';
const PROPERTY_PERCENTAGE = 'Percentage';
const TOOLTIP_TEXT =
	'A summary of all properties within the account according to their current score.';
const CHART_TITLE = 'Property score summary';
const errorTitle = 'Failed to load graph';
const errorMsg = 'Please try refreshing the page.';

const ScoreAcrossProperties: FC = () => {
	const [chartData, setChartData] = useState<PieDatum[]>([]);
	const { activePropertiesList } = useDashboard();

	const tooltipRenderer = ({ label, value }): JSX.Element => {
		return <EvChartTooltip label={label} value={`${value}%`} />;
	};

	const renderCustomLegend = useCallback(
		(legends) => (
			<div className="chart-legend">
				{legends.map((data) => (
					<div key={data.id} className="legend-item">
						<span className="legend-rect" style={{ background: data.color }} />
						<div className="legend-label">
							<span>{data.label}</span>
							<span>{data.score}</span>
						</div>
					</div>
				))}
			</div>
		),
		[]
	);

	useEffect(() => {
		if (activePropertiesList?.length) {
			setChartData(DashboardHelper.getScoreChartData(activePropertiesList));
		}
	}, [activePropertiesList]);

	const renderErrorState = useCallback(() => {
		return (
			<div className="score-chart-error-wrapper">
				<LoadErrorMsgLayout
					mainTitle={errorTitle}
					secondaryMsg={errorMsg}
					icon={ErrorLoadPageIcon}
				/>
			</div>
		);
	}, []);

	if (!activePropertiesList) {
		return <EvLoader message="Loading..." />;
	}

	return (
		<div className="score-across-properties">
			<EvPieChart
				data={chartData}
				title={CHART_TITLE}
				className="ev-pie-chart-title"
				withLegend={false}
				barDesignProps={{
					enableArcLabels: false,
					activeOuterRadiusOffset: 0,
					arcLinkLabelsSkipAngle: 15,
					tooltip: ({ datum }: PieTooltipProps<PieDatum>) => tooltipRenderer(datum),
					innerRadius: 0.8,
					padAngle: 3,
					arcLinkLabel: (d) => `${d.label} (${d.value}%)`,
					margin: { top: 20, right: 120, bottom: 30, left: 120 }
				}}
				width={DEFAULT_CHART_WIDTH}
				height={DEFAULT_CHART_HEIGHT}
				headerTooltipProps={{
					tooltipText: TOOLTIP_TEXT,
					tooltipTitle: `What is ${CHART_TITLE}`,
					tooltipPlacement: 'right'
				}}
				tableProps={{
					columnsPattern: [
						{ name: PERFORMANCE, field: LABEL_FIELD, sort: false },
						{ name: SCORE, field: SCORE_FIELD, sort: false },
						{ name: PROPERTY_PERCENTAGE, field: PERCENT_FIELD, sort: false }
					]
				}}
				renderCustomLegend={renderCustomLegend}
				isError={!activePropertiesList?.length}
				renderErrorState={renderErrorState}
			/>
		</div>
	);
};

export default ScoreAcrossProperties;
