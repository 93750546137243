import React, { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { EvSpinner } from '@evinced-private/ui-common';

import { handleSuccessfulAuthentication } from '../../helpers/AuthenticationHelper';
import useUniversalLogin from '../../hooks/useUniversalLogin';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import logger from '../../services/Logger';
import loginRedirectionService from '../../services/LoginRedirectionService';

const AuthToken: FC = () => {
	const { getAccessToken } = useUniversalLogin();
	const history = useHistory();
	const { updateUserTenant } = useUserTenant();
	const handleAuthentication = useCallback(async () => {
		try {
			const accessToken = await getAccessToken();
			await handleSuccessfulAuthentication(
				{ accessToken, idToken: accessToken, appState: null },
				history
			);
			try {
				await updateUserTenant();
			} catch (err) {
				logger.error(`Failed to update user and tenant with error: ${err}`);
			}
			const { path } = loginRedirectionService.getRedirectToPath();
			history.push(path);
		} catch (e) {
			console.error(e);
		}
	}, [getAccessToken, history, updateUserTenant]);

	useEffect(() => {
		handleAuthentication();
	}, [handleAuthentication]);

	return <EvSpinner />;
};

export default AuthToken;
