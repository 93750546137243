import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
	EvLink,
	EvLinkTypes,
	EvSection,
	EvSpinner,
	EvTableColumn,
	EvTitle,
	SORT_ORDER
} from '@evinced-private/ui-common';

import EvLinkLocal from '../../../../../../components/common/ev-link-local/EvLinkLocal';
import renderNumberCellFormatter from '../../../../../../components/common/ev-table/cell-formatters/numberCellFormatter';
import SiteScannerTable from '../../../../../../components/common/site-scanner-table/SiteScannerTable';
import RoutesHelper from '../../../../../../helpers/RoutesHelper';
import {
	IPropertyViewContext,
	useProperyViewContext
} from '../../../../../../providers/PropertyViewProvider';
import Logger from '../../../../../../services/Logger';
import { UrlIssuesCount } from '../../../../../../services/scan/ScanOverviewService';
import scanService from '../../../../../../services/scan/ScanService';

import './TopPagesByIssueCountTable.scss';

const TOP_PAGES_BY_ISSUE_COUNT_TABLE = 'top-pages-by-issue-count-table-title';

interface ITopPagesByIssueCountTableProps {
	scanId: string;
	propertyId: string;
}

const TopPagesByIssueCountTable: FC<ITopPagesByIssueCountTableProps> = ({
	scanId,
	propertyId
}: ITopPagesByIssueCountTableProps) => {
	const NUMBER_OF_ROWS_TO_SHOW = 5;
	const [isTableLoading, setTableLoading] = useState(true);
	const [tableData, setTableData] = useState<UrlIssuesCount[]>(null);

	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const currentViewId: string = useMemo(
		() => properyViewContext?.currentView?.id || null,
		[properyViewContext]
	);

	const getColumns = (): EvTableColumn[] => {
		return [
			{
				dataField: 'url',
				text: 'URL',
				style: { width: '100px' },
				headerStyle: { width: '100px' },
				sort: false,
				// eslint-disable-next-line react/no-unstable-nested-components
				formatter: (url: string) => {
					return (
						<EvLinkLocal
							url={RoutesHelper.getUrlDrilldownPath(propertyId, scanId, url)}
							linkText={url}
							type={EvLinkTypes.DARK}
							customTooltip={{ dataTip: url, dataFor: 'table-tooltip' }}
						/>
					);
				}
			},
			{
				dataField: 'issuesCount',
				text: 'Total issues',
				style: { width: '40px' },
				headerStyle: { width: '40px' },
				sort: false,
				formatter: renderNumberCellFormatter
			}
		];
	};

	const loadTableData = useCallback(async (): Promise<void> => {
		setTableLoading(true);
		try {
			const { pages } = await scanService.getScanPagesV2(
				scanId,
				{
					page: 0,
					sizePerPage: NUMBER_OF_ROWS_TO_SHOW,
					sortField: 'totalIssues',
					sortOrder: 'desc'
				},
				currentViewId
			);
			const issuesCountPerUrl =
				pages?.map((pageData) => ({
					url: pageData.url,
					issuesCount: pageData.totalIssues
				})) ?? [];
			setTableData(issuesCountPerUrl);
		} catch (error) {
			Logger.error('Error getting components data for table', error);
		} finally {
			setTableLoading(false);
		}
	}, [scanId, currentViewId]);

	useEffect(() => {
		loadTableData();
	}, [loadTableData]);

	const renderViewAllPagesLink = (): JSX.Element => {
		if (tableData?.length === 0) {
			return null;
		}
		return (
			<div className="actions">
				<EvLink
					linkText="View all pages"
					url={RoutesHelper.getScanResultPagesBreakdownPath(propertyId, scanId)}
					ariaLabel="view more pages"
				/>
			</div>
		);
	};

	return (
		<EvSection
			className="top-pages-by-issues"
			ariaLabelledby={TOP_PAGES_BY_ISSUE_COUNT_TABLE}
			skipLinkId="worst-pages"
		>
			<div className="title">
				<EvTitle titleText="Top 5 worst performing pages" id={TOP_PAGES_BY_ISSUE_COUNT_TABLE} />
				{renderViewAllPagesLink()}
			</div>
			{isTableLoading ? (
				<EvSpinner />
			) : (
				<SiteScannerTable
					tableId={`scan-${scanId}-worst-five-performing-pages-table`}
					className="pages-table"
					tableData={tableData ?? []}
					totalCount={tableData?.length ?? 0}
					columns={getColumns()}
					options={{
						title: 'Top 5 worst performing pages',
						caption: 'Top 5 worst performing pages',
						defaultSorted: [
							{
								dataField: 'issueCount',
								order: SORT_ORDER.DESC
							}
						],
						dataType: 'pages'
					}}
					isTableLoading={isTableLoading}
					showInfoRow={false}
				/>
			)}
		</EvSection>
	);
};

export default TopPagesByIssueCountTable;
