import React, { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';

import LocalStorageApi from '../../api/LocalStorageApi';
import CollectionsService from '../../services/CollectionsService';
import Logger from '../../services/Logger';
import { IPropertyCollection } from '../../types/CollectionType';

interface ICollectionsContext {
	propertyCollections: IPropertyCollection[];
	showOnlyPropertiesInMyCollection: boolean;
	collectionName: string;
	isCollectionsDataReady: boolean;
	collectionIds: string[];
	updatePropertiesCollectionState: (showOnlyPropertiesInMyCollection: boolean) => void;
}

const MY_COLLECTION_NAME = 'My collection';
const CollectionsContext = createContext<ICollectionsContext>(null);

const CollectionsProvider: FC = ({ children }) => {
	const [propertyCollections, setPropertyCollections] = useState<IPropertyCollection[]>([]);
	const [isCollectionsDataReady, setIsCollectionsDataReady] = useState<boolean>(false);
	const [showOnlyPropertiesInMyCollection, setShowOnlyPropertiesInMyCollection] = useState(
		JSON.parse(LocalStorageApi.getFromLocalStorage(MY_COLLECTION_NAME)) || false
	);

	const updatePropertiesCollectionState = useMemo(() => {
		return (showOnlyPropertiesInMyCollection: boolean): void => {
			LocalStorageApi.saveToLocalStorage(MY_COLLECTION_NAME, showOnlyPropertiesInMyCollection);
			setShowOnlyPropertiesInMyCollection(showOnlyPropertiesInMyCollection);
		};
	}, [setShowOnlyPropertiesInMyCollection]);

	// todo change showOnlyPropertiesInMyCollection in phase 2 to showOnlyPropertiesInMyCollections

	const collectionName: string = propertyCollections?.[0]?.name ?? MY_COLLECTION_NAME;

	const collectionIds: string[] = useMemo(() => {
		if (propertyCollections.length === 0) {
			return [];
		}
		setIsCollectionsDataReady(true);
		return showOnlyPropertiesInMyCollection
			? propertyCollections.map(({ id }) => id)
			: [propertyCollections[0]?.id];
	}, [showOnlyPropertiesInMyCollection, propertyCollections]);

	useEffect(() => {
		const initCollections = async (): Promise<void> => {
			try {
				const collections: IPropertyCollection[] = await CollectionsService.getCollections();
				setPropertyCollections(collections);
			} catch (error) {
				Logger.error('Error getting collections', error);
			}
		};

		initCollections();
	}, []);

	const value = useMemo(() => {
		return {
			propertyCollections,
			showOnlyPropertiesInMyCollection,
			collectionName,
			isCollectionsDataReady,
			collectionIds,
			updatePropertiesCollectionState
		};
	}, [
		propertyCollections,
		showOnlyPropertiesInMyCollection,
		collectionName,
		isCollectionsDataReady,
		collectionIds,
		updatePropertiesCollectionState
	]);

	return <CollectionsContext.Provider value={value}>{children}</CollectionsContext.Provider>;
};

const useCollections = (): ICollectionsContext => useContext(CollectionsContext);

export { CollectionsProvider, useCollections };
