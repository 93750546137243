import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react-lite';

import { FormatHelper } from '@evinced-private/ui-common';

import BaseScanViewV2, {
	ScanTableDefinition
} from '../../../../components/scan-analysis/base/BaseScanViewV2';
import { MAX_RESULTS } from '../../../../consts/Results';
import { IIssuesPaginationResponse } from '../../../../interfaces/IssuesPaginationResponse';
import { SummaryItem } from '../../../../interfaces/SummaryItem';
import scanService from '../../../../services/scan/ScanService';
import { FilterParams } from '../../../../types/FilterParams';
import { ScanAndPropertyIssuesSummary } from '../../../../types/OverviewTypes';
import { PaginationParams } from '../../../../types/PaginationParams';
import ScanBreakdownTableHelperV2 from '../breakdown/ScanBreakdownTableHelperV2';

interface IUrlDrillDownView {
	scanId: string;
	propertyId: string;
	url: string;
	viewId?: string;
}

const defaultTableSort = ScanBreakdownTableHelperV2.getDefaultSort();

const UrlDrillDownView: FC<IUrlDrillDownView> = ({
	scanId,
	propertyId,
	url,
	viewId
}: IUrlDrillDownView) => {
	const [scanResults, setScanResults] = useState<IIssuesPaginationResponse>(null);
	const [totalCount, setTotalCount] = useState<number>(null);
	const [summaryPaneInfo, setSummaryPaneInfo] = useState<ScanAndPropertyIssuesSummary>();

	const screenId = `scan-${scanId}-view-${viewId}-url-${url}-drilldown-view`;

	const loadTableItems = useCallback(
		async (
			scanId: string,
			paginationParams: PaginationParams,
			filtersParams: FilterParams
		): Promise<void> => {
			if (!filtersParams.filterValues) {
				filtersParams.filterValues = {};
			}
			filtersParams.filterValues.urls = [{ value: url, label: url }];
			const response: IIssuesPaginationResponse = await scanService.getScanIssuesV2(
				scanId,
				paginationParams,
				{ ...filtersParams },
				viewId
			);
			setScanResults(response);
		},
		[url, viewId]
	);

	useEffect(() => {
		if (scanResults?.overallTotal) {
			setTotalCount(scanResults.overallTotal);
		}
	}, [scanResults?.overallTotal]);

	const loadSummaryData = useCallback(async (): Promise<void> => {
		const summaryPaneInfo = await scanService.getIssuesSummaryV2({ scanId, viewId, issueUrl: url });
		setSummaryPaneInfo(summaryPaneInfo);
	}, [scanId, url, viewId]);

	const summaryPaneData = useMemo((): SummaryItem[] => {
		if (!summaryPaneInfo) {
			return [];
		}
		return [
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.totalNumberOfIssues),
				label: 'Total issues'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.criticalIssues),
				label: 'Critical issues'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.seriousIssues),
				label: 'Serious issues'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.minorIssues),
				label: 'Minor issues'
			}
		];
	}, [summaryPaneInfo]);

	const tableDefinition = useMemo((): ScanTableDefinition => {
		if (!scanResults) {
			return null;
		}

		const { issues } = scanResults;
		const totalPaginationableResults =
			scanResults.overallTotal > MAX_RESULTS ? MAX_RESULTS : scanResults.overallTotal;
		return ScanBreakdownTableHelperV2.getTableDefinition(
			issues,
			totalCount,
			totalPaginationableResults
		);
	}, [scanResults, totalCount]);

	return (
		<BaseScanViewV2
			viewId={viewId}
			scanId={scanId}
			screenId={screenId}
			summaryPaneItems={summaryPaneData}
			tableDefinition={tableDefinition}
			defaultTableSort={defaultTableSort}
			loadSummaryData={loadSummaryData}
			loadTableItems={loadTableItems}
			propertyId={propertyId}
			renderCsvExportButton={true}
		/>
	);
};

export default observer(UrlDrillDownView);
