import React, { FC } from 'react';

import { EvContactSupportEmailLink } from '@evinced-private/ui-common';

import './GeneralError.scss';

interface IGeneralError {
	title: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any;
	displayContactInfo?: boolean;
}
const GeneralError: FC<IGeneralError> = ({
	title,
	children,
	displayContactInfo = true
}: IGeneralError) => {
	return (
		<div className="general-error">
			<div className="title">{title}</div>
			<div className="body">{children}</div>
			{displayContactInfo && (
				<div className="contact-us">
					Please try to refresh the page. <br />
					If the error persists contact us at <EvContactSupportEmailLink />
				</div>
			)}
		</div>
	);
};

export default GeneralError;
