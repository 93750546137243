import React, { FC } from 'react';

import { BUTTON_TYPES } from '@evinced-private/ui-common';

import ScanCrawlStateHelper from '../../helpers/ScanCrawlStateHelper';
import ScanCrawlTextHelper from '../../helpers/ScanCrawlTextHelper';
import { IProperty } from '../../interfaces/Property';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import logger from '../../services/Logger';
import scansService from '../../services/scan/ScansService';
import urlSetService from '../../services/UrlSetService';
import JobStatusAction from '../job-status-action/JobStatusAction';

import './JobsStatusAction.scss';

interface IJobsStatusActionProps {
	properties: IProperty[];
}

const getJobsStatusText = ({
	runningCrawlsNumber,
	runningScansNumber
}: {
	runningCrawlsNumber?: number;
	runningScansNumber?: number;
}): string => {
	if (runningScansNumber) {
		return ScanCrawlTextHelper.getJobStatus('scan', runningScansNumber);
	}
	if (runningCrawlsNumber) {
		return ScanCrawlTextHelper.getJobStatus('crawl', runningCrawlsNumber);
	}
	return '';
};

const JobsStatusAction: FC<IJobsStatusActionProps> = ({ properties }) => {
	const { isReadOnlyUser } = useUserTenant();

	const runningScansProperties = properties.filter((p) =>
		ScanCrawlStateHelper.isInProgress(p.lastScan?.state)
	);
	const runningCrawlsProperties = properties.filter((p) =>
		ScanCrawlStateHelper.isInProgress(p.lastUrlSet?.state)
	);
	const onCancelScans = async (): Promise<void> => {
		try {
			await Promise.all(
				runningScansProperties.map((property) => scansService.deleteScan(property.lastScan.id))
			);
		} catch (error) {
			logger.error('Error while canceling scans', error);
		}
	};

	const onCancelCrawl = async (): Promise<void> => {
		try {
			await Promise.all(
				runningCrawlsProperties.map((property) =>
					urlSetService.deleteUrlSet(property.lastUrlSet.id)
				)
			);
		} catch (error) {
			logger.error('Error while canceling crawls', error);
		}
	};

	const runningScansNumber = runningScansProperties.length;
	const runningCrawlsNumber = runningCrawlsProperties.length;

	if (!runningScansNumber && !runningCrawlsNumber) {
		return null;
	}

	const getJobStatus = (
		entity: string,
		count: number,
		jobsStatusText: string,
		cancelTask: () => Promise<void>
	): JSX.Element => {
		const title = ScanCrawlTextHelper.getPopupCancelTitle(entity, count);
		const message = ScanCrawlTextHelper.getPopupCancelMessage(entity, count);
		const confirmButtonText = ScanCrawlTextHelper.getConfirmButtonText(entity, count);
		return (
			<JobStatusAction
				popupTitle={title}
				jobsStatusText={jobsStatusText}
				onConfirm={cancelTask}
				promptMessage={message}
				confirmButtonText={confirmButtonText}
				triggerButtonProps={{
					type: BUTTON_TYPES.LINK,
					title: 'Cancel',
					children: 'Cancel'
				}}
				isPopupOpen={!isReadOnlyUser()}
			/>
		);
	};

	return (
		<div className="jobs-status-action">
			{runningCrawlsNumber > 0 &&
				getJobStatus(
					'crawl',
					runningCrawlsNumber,
					getJobsStatusText({ runningCrawlsNumber }),
					onCancelCrawl
				)}
			{runningScansNumber > 0 &&
				getJobStatus(
					'scan',
					runningScansNumber,
					getJobsStatusText({ runningScansNumber }),
					onCancelScans
				)}
		</div>
	);
};

export default JobsStatusAction;
