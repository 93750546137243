import React, { FC } from 'react';

import { BUTTON_TYPES, EvButton } from '@evinced-private/ui-common';

import SCOPE_TYPES from '../../../../types/scope/ScopeTypes';

interface IScopeActionButtonsProps {
	actionsList: SCOPE_TYPES[];
	onBtnClick: (scopeType: SCOPE_TYPES) => void;
}

const ScopeActionButtons: FC<IScopeActionButtonsProps> = ({ actionsList, onBtnClick }) => {
	return (
		<div className="scope-action-buttons">
			{actionsList.map((actionType: SCOPE_TYPES) => (
				<EvButton
					key={actionType}
					className="scope-action-btn"
					title={`Add ${actionType} Rule`}
					type={BUTTON_TYPES.ACTION}
					onClick={() => {
						onBtnClick(actionType);
					}}
				>
					{`Add ${actionType}`}
				</EvButton>
			))}
		</div>
	);
};

export default ScopeActionButtons;
