import React, { FC } from 'react';

import classNames from 'classnames';

import './VerticalSeparator.scss';

interface IVerticalSeparator {
	className?: string;
}
const VerticalSeparator: FC<IVerticalSeparator> = ({ className }) => (
	<div className={classNames('vertical-separator', { [className]: !!className })} />
);

export default VerticalSeparator;
