import React, { FC, useEffect, useState } from 'react';

import { BUTTON_TYPES } from '@evinced-private/ui-common';

import TogglesService, { DevelopmentToggles } from '../../services/TogglesService';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';
import Switch from '../switch/Switch';

import './DevTogglesPopup.scss';

const TOGGLES_DETAILS = [
	{ name: DevelopmentToggles.PROPERTY_JSON_EDITOR, label: 'Allow editing property config by JSON' },
	{
		name: DevelopmentToggles.SHOW_SCAN_URLSET_JSON_CONFIG,
		label: 'Show Scan UrlSet Configuration'
	},
	{ name: DevelopmentToggles.SHOW_URLSET_JSON_CONFIG, label: 'Show UrlSet Configuration' },
	{ name: DevelopmentToggles.SHOW_PRINT_BUTTON, label: 'Show "Print/save as PDF" Button' },
	{ name: DevelopmentToggles.SHOW_CRAWL_FILE_TYPES, label: 'Show Crawl File Types Option' },
	{ name: DevelopmentToggles.SHOW_COMPARE_SCANS, label: 'Show Compare Scans' },
	{ name: DevelopmentToggles.ENABLE_AZURE_INTEGRATION, label: 'Enable Azure Integration' },
	{
		name: DevelopmentToggles.SHOW_COOKIES_CONSENT_SELECTOR,
		label: 'Show cookies consent button selector in login form'
	},
	{
		name: DevelopmentToggles.SEND_USAGE_ANALYTICS,
		label: 'Send usage analytics to Evinced'
	}
];

interface IDevTogglesPopup {
	closePopup: () => void;
	isOpen: boolean;
}

const DevTogglesPopup: FC<IDevTogglesPopup> = ({ closePopup, isOpen }: IDevTogglesPopup) => {
	const [toggles, setToggles] = useState({});

	const setTogglesState = (): void => {
		const newState = {};
		TOGGLES_DETAILS.forEach((toggle) => {
			const state = TogglesService.getToggle(toggle.name, false);
			newState[toggle.name] = state;
		});
		setToggles(newState);
	};

	useEffect(() => {
		setTogglesState();
	}, []);

	const onChange = (type) => {
		return (state) => {
			setToggles({ ...toggles, [type]: state });
		};
	};

	const saveChanges = (): void => {
		TOGGLES_DETAILS.forEach((toggle) => {
			if (toggles[toggle.name]) {
				TogglesService.setToggle(toggle.name, 'true');
			} else {
				TogglesService.removeToggle(toggle.name);
			}
		});
	};

	const onClose = (): void => {
		setTogglesState();
		closePopup();
	};

	const buttons = [
		{
			title: 'Cancel',
			onClick: onClose,
			type: BUTTON_TYPES.SECONDARY
		},
		{
			title: 'Save',
			onClick: () => {
				saveChanges();
				closePopup();
			}
		}
	];
	return (
		<SiteScannerPopup
			title="Dev Toggles"
			isControlled
			isOpen={isOpen}
			onClose={onClose}
			buttons={buttons}
		>
			<div className="popup-form">
				{TOGGLES_DETAILS.map((toggle) => {
					return (
						<div className="popup-form-row" key={toggle.name}>
							<div className="switchWrapper ev-switch-comp">
								<Switch
									dataId="errors"
									isOn={toggles[toggle.name]}
									onChange={onChange(toggle.name)}
								/>
								<label htmlFor="errors">{toggle.label}</label>
							</div>
						</div>
					);
				})}
			</div>
		</SiteScannerPopup>
	);
};

export default DevTogglesPopup;
