import React, { FC } from 'react';

import { EvContactSupportEmailLink } from '@evinced-private/ui-common';

import './AccountPlanInfo.scss';

const CommunityPlanInfo: FC = () => {
	return (
		<div className="account-plan-info">
			<div className="info-paragrah">
				The community license is an ongoing free service with no time limit.
				<br />
				You pledge to not abuse this license, scan only sites that you are permitted to scan, and do
				so only with the intention of doing good.
			</div>
			<div className="info-paragrah">
				Please note the following terms of this license (per month):
				<br />
				<ul className="info-list">
					<li className="info-list-item">Up to 10,000 URLs crawled and 10,000 URLs scanned</li>
					<li className="info-list-item">Each scan is limited to 1,500 pages</li>
					<li className="info-list-item">
						You can run multiple scans across domains and subdomains
					</li>
				</ul>
			</div>
			<div className="info-paragrah">
				If you’re interested in extended capabilities, please don’t hesitate to contact us at
				<EvContactSupportEmailLink /> and we can extend it for you.
			</div>
		</div>
	);
};

export default CommunityPlanInfo;
