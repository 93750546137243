import React, { FC, useMemo } from 'react';

import { EvLoader, FormatHelper } from '@evinced-private/ui-common';

import { roundNumberSupportZero } from '../../../../helpers/dashboard/DashboardHelper';
import { useDashboard } from '../../../../providers/dashboard/DashboardProvider';
import { KPIS } from '../../DashboardConsts';
import { COMPARE_TYPE_AVG_SCORE_KPI_TOOLTIP } from '../../DashboardTypes';

import Kpi from './Kpi';
import VerticalSeparator from './VerticalSeparator';

import './Kpis.scss';

const KPI_VALUES = {
	[KPIS.AVERAGE_SCORE_KPI]: {
		header: 'Average score change'
	},
	[KPIS.TENANT_CRITICAL_ISSUES_KPI]: {
		header: 'Critical issues',
		tooltip: (
			<>
				The number of Critical severity issues detected across all properties scanned within the
				selected time period.
			</>
		)
	},
	[KPIS.PAGES_MONITORED_KPI]: {
		header: 'Pages monitored',
		tooltip: (
			<>
				The latest number of URLS scanned for accessibility in all properties within the selected
				time period.
			</>
		)
	}
};

const liftMsg = (lift: number): string => {
	const roundLiftNumber = roundNumberSupportZero(lift);
	let msg = "Didn't change";
	if (roundLiftNumber > 0) {
		msg = `increased by ${roundLiftNumber}%`;
	} else if (roundLiftNumber < 0) {
		msg = `decreased by ${roundLiftNumber}%`;
	}
	return msg;
};

const Kpis: FC = () => {
	const {
		avgScoreKpi,
		tenantCriticalIssuesKpi,
		pageMonitoredKpi,
		numberOfActiveProperties,
		loadingDuePeriodChangeForKpis,
		liftPeriodCompare
	} = useDashboard();

	const numberOfActivePropertiesKpiSummaryLine = `Across ${
		numberOfActiveProperties || 0
	} active properties`;
	const criticalIssuesKpiSummaryLine = useMemo(
		() =>
			`Out of ${FormatHelper.nFormatter(
				tenantCriticalIssuesKpi?.totalIssues?.totalIssuesCount
			)} total issues`,
		[tenantCriticalIssuesKpi?.totalIssues?.totalIssuesCount]
	);

	return (
		<ul className="kpis" aria-label="Accessibility KPIs">
			<li
				className="avg-score-kpi"
				aria-label={`Average score change ${numberOfActivePropertiesKpiSummaryLine}.
				Score ${liftMsg(avgScoreKpi?.liftPercentage)}`}
			>
				{avgScoreKpi && !loadingDuePeriodChangeForKpis ? (
					<Kpi
						header={KPI_VALUES[KPIS.AVERAGE_SCORE_KPI].header}
						// eslint-disable-next-line react/jsx-no-useless-fragment
						tooltip={<>{COMPARE_TYPE_AVG_SCORE_KPI_TOOLTIP[liftPeriodCompare]}</>}
						summaryLine={numberOfActivePropertiesKpiSummaryLine}
						lift={avgScoreKpi.liftPercentage}
					/>
				) : (
					<EvLoader message="Loading data..." />
				)}
			</li>
			<li aria-hidden="true">
				<VerticalSeparator className="vertical-space" />
			</li>
			<li
				className="critical-issues-kpi"
				aria-label={`Critical issues ${criticalIssuesKpiSummaryLine}.
				Score ${liftMsg(tenantCriticalIssuesKpi?.criticalIssues?.liftPercentage)}`}
			>
				{tenantCriticalIssuesKpi && !loadingDuePeriodChangeForKpis ? (
					<Kpi
						header={KPI_VALUES[KPIS.TENANT_CRITICAL_ISSUES_KPI].header}
						tooltip={KPI_VALUES[KPIS.TENANT_CRITICAL_ISSUES_KPI].tooltip}
						summaryLine={criticalIssuesKpiSummaryLine}
						count={tenantCriticalIssuesKpi.criticalIssues.totalCriticalIssuesCount}
						lift={tenantCriticalIssuesKpi.criticalIssues.liftPercentage}
						falsePositive
					/>
				) : (
					<EvLoader message="Loading data..." />
				)}
			</li>
			<li aria-hidden="true">
				<VerticalSeparator className="vertical-space" />
			</li>
			<li
				className="page-monitored-kpi"
				aria-label={`${
					pageMonitoredKpi?.totalPages
				} pages monitored ${numberOfActivePropertiesKpiSummaryLine}.
				Amount ${liftMsg(pageMonitoredKpi?.liftPercentage)}`}
			>
				{pageMonitoredKpi && !loadingDuePeriodChangeForKpis ? (
					<Kpi
						header={KPI_VALUES[KPIS.PAGES_MONITORED_KPI].header}
						tooltip={KPI_VALUES[KPIS.PAGES_MONITORED_KPI].tooltip}
						summaryLine={numberOfActivePropertiesKpiSummaryLine}
						count={pageMonitoredKpi.totalPages}
						lift={pageMonitoredKpi.liftPercentage}
					/>
				) : (
					<EvLoader message="Loading data..." />
				)}
			</li>
		</ul>
	);
};

export default Kpis;
