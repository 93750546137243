import React, { FC } from 'react';

import { EvChartBottomTick, EvLineChart, EvSection } from '@evinced-private/ui-common';

import { ScoreLineChartData } from '../../interfaces/ScanScore';

import './ScansScoreChart.scss';

interface IScansScoreChart {
	scanScoreData: ScoreLineChartData[];
	title: string;
}

const scanScoreTicks = [0, 20, 40, 60, 80, 100];

const ScansScoreChart: FC<IScansScoreChart> = ({ title, scanScoreData }) => {
	const columnsPattern = [
		{
			name: 'Scan Date',
			field: 'x'
		},
		{
			name: 'Score',
			field: 'y'
		}
	];

	return (
		<EvSection className="score-scans-chart">
			<EvLineChart
				chartTitle={title}
				height={400}
				xAxisLabel="Scan date"
				yAxisLabel="Score"
				lineWidth={2}
				pointSize={8}
				data={scanScoreData}
				labelProps={{
					enablePointLabel: true,
					pointLabel: 'yFormatted',
					useMesh: true,
					data: scanScoreData,
					isInteractive: true
				}}
				margin={{
					top: 20,
					bottom: 80,
					left: 90,
					right: 100
				}}
				axisBottom={{
					tickSize: 0,
					tickPadding: 30,
					legendOffset: -95,
					legendPosition: 'middle',
					renderTick: (tick) => <EvChartBottomTick tick={tick} />
				}}
				axisLeft={{
					legendPosition: 'middle',
					tickValues: scanScoreTicks,
					tickSize: 0,
					tickPadding: 35,
					legendOffset: -80,
					legend: 'Score'
				}}
				yScale={{
					type: 'linear',
					stacked: true,
					min: 0,
					max: 100
				}}
				gridYValues={scanScoreTicks}
				theme={{
					fontSize: 12,
					textColor: '#607085',
					background: 'white',
					fontFamily: 'Outfit,sans-serif!important',
					axis: {
						legend: { text: { fontSize: 12 } }
					}
				}}
				className="scans-score"
				isInteractive={true}
				tableProps={{
					dataType: 'Score',
					columnsPattern
				}}
			/>
		</EvSection>
	);
};

export default ScansScoreChart;
