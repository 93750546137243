import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';

import RoutesHelper from '../../helpers/RoutesHelper';
import IssueDetailsPage from '../issue-details-page/IssueDetailsPage';
import ManageViewsPage from '../manage-views/ManageViewsPage';
import PropertySettingsPage from '../property-settings/PropertySettingsPage';
import ScanRouter from '../scan-analysis/ScanRouter';
import ScanAndTrendsPage from '../scan-and-trends/ScanAndTrendsPage';
import CommonPagesPage from '../scans-comparison/CommonPagesPage';
import ScansComparisonPage from '../scans-comparison/ScansComparisonPage';
import UrlSetPage from '../urlset/UrlSetPage';

const PropertyRouter: FC = () => {
	const basePath = RoutesHelper.getPropertyPagePath(':propertyId');

	const history = useHistory();
	return (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path={RoutesHelper.getPropertyPagePath(':propertyId')}
					render={() => <ScanAndTrendsPage />}
				/>
				<Route
					exact
					path={RoutesHelper.getManageViewsPath(':propertyId')}
					render={() => <ManageViewsPage />}
				/>
				<Route
					exact
					path={RoutesHelper.getPropertyTrendsPath(':propertyId')}
					render={() => <ScanAndTrendsPage />}
				/>
				<Route
					exact
					path={RoutesHelper.getPropertySettingsPagePath(':propertyId')}
					render={() => <PropertySettingsPage />}
				/>
				<Route exact path={`${basePath}/scan-comparison`} render={() => <ScansComparisonPage />} />
				<Route
					exact
					path={`${basePath}/scan-comparison/common-pages`}
					render={() => <CommonPagesPage />}
				/>
				<Route
					exact
					path={RoutesHelper.getUrlSetPagePath(':propertyId', ':urlSetId')}
					component={UrlSetPage}
				/>
				<Route
					exact
					path={`${basePath}/scans/:scanId/issues/:issueId`}
					component={IssueDetailsPage}
				/>
				<Route path={`${basePath}/scans/:pathScanId`} render={() => <ScanRouter />} />
			</Switch>
		</Router>
	);
};

export default PropertyRouter;
