import React, { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

import { EvMainTitle } from '@evinced-private/ui-common';

import ScanUrlsStatusViewV2 from '../../pages/scan-analysis/views/urls-status-drilldown/ScanUrlsStatusViewV2';
import {
	INavigationRoute,
	useNavigation
} from '../../providers/navigationProvider/NavigationProvider';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import EvPropertyViews from '../common/ev-property-views/EvPropertyViews';
import PropertyViewLoadErrorPage from '../common/ev-property-views/PropertyViewLoadErrorPage';

import './DrilldownPage.scss';

const HEADER_TITLE = 'Scanned URLs Information';

const UrlsStatusDrilldownPage: FC = () => {
	const { setCurrentRoute } = useNavigation();
	const { propertyId, scanId } = useParams<{ [key: string]: string }>();
	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const viewId = useMemo(() => properyViewContext?.currentView?.id, [properyViewContext]);
	const viewLoadErr = useMemo(() => properyViewContext?.loadViewErr, [properyViewContext]);

	const currentRoute: INavigationRoute = useMemo(() => {
		if (!propertyId || !scanId) {
			return null;
		}

		return {
			id: propertyId,
			title: HEADER_TITLE
		};
	}, [propertyId, scanId]);

	useEffect(() => {
		if (currentRoute) {
			setCurrentRoute(currentRoute);
		}
		return () => {
			setCurrentRoute(null);
		};
	}, [currentRoute, setCurrentRoute]);

	if (viewLoadErr) {
		return <PropertyViewLoadErrorPage propertyId={propertyId} />;
	}

	return (
		<div className="drilldown-page">
			<div className="drilldown-page-content">
				<div className="header">
					<EvMainTitle className="page-heading">{HEADER_TITLE}</EvMainTitle>
					<div className="filtered-views-controller">
						<EvPropertyViews propertyId={propertyId} />
					</div>
				</div>
				<ScanUrlsStatusViewV2 scanId={scanId} viewId={viewId} />
			</div>
		</div>
	);
};

export default UrlsStatusDrilldownPage;
