/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LocationDescriptorObject } from 'history';

import {
	EvInfoTooltip,
	EvSelect,
	EvSpinner,
	EvToggle,
	OptionType,
	useDebounce,
	useSkipLinks
} from '@evinced-private/ui-common';

import ScansScore from '../../components/charts/ScansScoreChart';
import SeverityTrends from '../../components/charts/severity-trends/SeverityTrends';
import { SEVERITIES_CHART_TYPE } from '../../components/charts/severity-trends/SeverityTrendTypes';
import TotalIssuesTrend from '../../components/charts/total-issues-trend/TotalIssuesTrend';
import NoDataChartWrapper from '../../components/data-displays/overview-charts/no-data-chart-wrapper/NoDataChartWrapper';
import ErrorRect from '../../components/icons/ErrorRect.svg';
import NoTrendsIcon from '../../components/icons/NoTrendsIcon.svg';
import SingleScanChartsScreenV2 from '../../components/single-scan-trends-screen/SingleScanTrendsScreenV2';
import TrendsNoDataIndication from '../../components/trends-no-data-indication/TrendsNoDataIndication';
import DateFormatHelper, { sortByDate } from '../../helpers/DateFormatHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import ScanCrawlStateHelper from '../../helpers/ScanCrawlStateHelper';
import { IScan } from '../../interfaces/Scan';
import { ScanScore, ScoreLineChartData } from '../../interfaces/ScanScore';
import { ITrend } from '../../interfaces/Trend';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import logger from '../../services/Logger';
import scansService from '../../services/scan/ScansService';
import TabsViews from '../../types/TabsViews';

import './TrendsPage.scss';

interface IScanSelectOption extends OptionType {
	simpleLabel: string;
	date: string;
}

const MAX_NUMBER_OF_ISSUES = 10;
const INITIAL_NUMBER_OF_ISSUES = 5;
const NO_DATA_GRAPH_MAIN_TITLE = 'Graph cannot be displayed';
const NO_DATA_GRAPH_SECONDARY_TITLE =
	'Please try selecting a different view or scan(s) to see the graph';
const NO_PAGES_MAIN_TITLE = 'No Pages match';
const NO_PAGES_SECONDARY_TITLE = 'Try switching the active view to a different one.';

enum Modes {
	ALL_PAGES = 'All Pages',
	COMMON = 'Common'
}

interface ITrendsPageProps {
	propertyId: string;
	disableTrends: (val: boolean) => void;
}

type ScansDates = {
	id: string;
	date: string;
};

const TrendsPage: FC<ITrendsPageProps> = ({ propertyId, disableTrends }: ITrendsPageProps) => {
	const history = useHistory();
	const [scanSelectOptions, setScanSelectOptions] = useState<IScanSelectOption[]>(null);
	const [selectedScans, setSelectedScans] = useState<IScanSelectOption[]>(null);
	const [selectedScanIdsForDebounce, setSelectedScanIdsForDebounce] = useState<string[]>(null);
	const [mode, setMode] = useState<string>(Modes.ALL_PAGES);
	const [trends, setTrends] = useState<ITrend[]>(null);
	const [scoreData, setScoreData] = useState<ScoreLineChartData[]>([]);
	const [selectedScansDates, setSelectedScansDates] = useState<ScansDates[]>(null);
	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const [trendsLoadingInProgress, setTrendsLoadingInProgress] = useState<boolean>(false);
	const [showScansScoreLoading, setShowScansScoreLoading] = useState<boolean>(false);
	const [showScansSelectionError, setShowScansSelectionError] = useState<boolean>(false);
	const [errorLoadingTrends, setErrorLoadingTrends] = useState<boolean>(false);
	const [errorLoadingScansList, setErrorScansList] = useState<boolean>(false);
	const [severityChartType, setSeverityChartType] = useState<SEVERITIES_CHART_TYPE>(
		SEVERITIES_CHART_TYPE.TOTAL_ISSUES
	);
	const [pagesInCommonExist, setPagesInCommonExist] = useState<boolean>(true);
	const [noPagesMatch, setNoPagesMatch] = useState<boolean>(false);

	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const currentViewId: string = useMemo(
		() => properyViewContext?.currentView?.id || null,
		[properyViewContext]
	);

	const scansScoreTitle = 'Score';
	const onSingleScanSeverityBarItemClick = useCallback(
		(stateKey, stateValue) => {
			history.push({
				pathname: RoutesHelper.getScanDetailsView(
					propertyId,
					scanSelectOptions[0].value as string,
					TabsViews.BREAKDOWN
				),
				state: {
					[stateKey]: stateValue,
					isTemporary: true
				}
			} as LocationDescriptorObject);
		},
		[scanSelectOptions, history, propertyId]
	);

	const onFiltersClose = (): void => {
		setShowScansSelectionError(false);
	};

	/**
	 * Loads all scans and sets the initlal selected dropdown values.
	 */
	const loadInitialScanTrendsData = useCallback(async (): Promise<void> => {
		try {
			const scans: IScan[] = await scansService.getAllScansSortedByTimeDesc(propertyId);
			const successScans = scans.filter((scan) =>
				ScanCrawlStateHelper.isScanResultsReady(scan?.state)
			);
			const scanOptions: IScanSelectOption[] = successScans.map((scan) => ({
				value: scan.id,
				label: DateFormatHelper.formatDate(scan.createdTime, 'MMM D, YYYY, h:mm a'),
				simpleLabel: DateFormatHelper.formatDate(scan.createdTime, 'MMM D, YYYY'),
				date: scan.createdTime
			}));
			if (scanOptions.length !== 0) {
				const initialselectedScanss = sortByDate(
					scanOptions.slice(0, INITIAL_NUMBER_OF_ISSUES),
					'date'
				);
				setSelectedScans(initialselectedScanss);
			} else {
				setInitialLoading(false);
			}
			setScanSelectOptions(scanOptions);
			setErrorScansList(false);
		} catch (err) {
			setErrorScansList(true);
			logger.error(`Trends Page - Error getting all scans for property ${propertyId}`, err);
		}
	}, [propertyId]);

	const getTrends = useCallback(
		async (scansIds: string[]): Promise<void> => {
			try {
				setTrendsLoadingInProgress(true);
				const trendsData: ITrend[] = await scansService.getTrends(
					scansIds,
					mode === Modes.COMMON,
					currentViewId
				);
				const anyPages: boolean = trendsData.some((trend) => trend.totalPages !== 0);
				if (!anyPages) {
					setNoPagesMatch(true);
				} else {
					setNoPagesMatch(false);
				}

				if (mode === Modes.COMMON) {
					const areThereCommonPages = trendsData.some((trendsItem) => trendsItem.totalPages !== 0);
					setPagesInCommonExist(areThereCommonPages);
				}
				const sortedTrends = sortByDate(trendsData, 'createdTime');
				setTrends(sortedTrends);
				setTrendsLoadingInProgress(false);
				setInitialLoading(false);
				setErrorLoadingTrends(false);
			} catch (err) {
				setErrorLoadingTrends(true);
				logger.error(`Trends Page - Error getting trends data for property ${propertyId}`, err);
			}
		},
		[mode, propertyId, currentViewId]
	);

	const getScansScore = useCallback(
		async (scansIds: string[]): Promise<void> => {
			try {
				setShowScansScoreLoading(true);
				const scansScore: ScanScore[] = await scansService.getScansScore(scansIds, currentViewId);

				if (scansScore) {
					const formattedData = scansScore.map((item) => {
						const itemDate = selectedScansDates?.find((scan) => scan.id === item.scanId).date || '';
						return {
							x: itemDate || '',
							y: Math.round(item?.scoreResult?.score || 0)
						};
					});
					/**
					 * Sort  by date
					 */
					const sortedData = sortByDate(formattedData, 'x');
					const scoreLineChartData = [
						{
							id: 'Score',
							color: '#F97052',
							data: sortedData
						}
					];
					setScoreData(scoreLineChartData);
				}
			} catch (error) {
				logger.error('Unable to get scans scores', error);
			} finally {
				setShowScansScoreLoading(false);
			}
		},
		[selectedScansDates, currentViewId]
	);

	// fetch all scans when page loads
	useEffect(() => {
		disableTrends(false);
		loadInitialScanTrendsData();
	}, [disableTrends, loadInitialScanTrendsData]);

	// use useDebounce hook
	const debouncedScanIds = useDebounce(selectedScanIdsForDebounce, 1000);

	useEffect(() => {
		async function fetchData(): Promise<void> {
			await getTrends(debouncedScanIds);
		}
		if (debouncedScanIds) {
			fetchData();
			getScansScore(debouncedScanIds);
		}
	}, [debouncedScanIds, getTrends, getScansScore]);

	useSkipLinks(!!trends && !!scanSelectOptions);

	// fetch new scans on dropdown change and on initial load
	useEffect(() => {
		if (selectedScans && scanSelectOptions?.length > 1) {
			const scanIds = selectedScans
				.filter((item) => item.value !== '*')
				.map((item) => item.value as string);
			const scansDates = selectedScans
				.filter((item) => item.value !== '*')
				.map((item) => {
					return {
						id: item.value as string,
						date: item.label
					};
				});
			setSelectedScanIdsForDebounce(scanIds);
			setSelectedScansDates(scansDates);
		} else {
			setShowScansSelectionError(false);
		}
	}, [selectedScans, scanSelectOptions]);

	const totalIssuesChartData = useMemo(() => {
		if (!trends) {
			return [];
		}
		return trends.map((item) => ({
			id: item.id,
			barItemCount: item.totalIssues,
			lineItemCount: item.totalPages,
			label: DateFormatHelper.formatDate(item.createdTime, 'MMM D, YYYY, h:mm a')
		}));
	}, [trends]);

	const someItemsSelectedText = useCallback(() => {
		return `${selectedScans[0].simpleLabel} - ${
			selectedScans[selectedScans.length - 1].simpleLabel
		}`;
	}, [selectedScans]);

	const onScanChange = (selectedScans): void => {
		if (selectedScans.length > MAX_NUMBER_OF_ISSUES) {
			setShowScansSelectionError(true);
			return;
		}
		setShowScansSelectionError(false);
		setSelectedScans(sortByDate(selectedScans, 'date'));
		const scanIds = selectedScans.map((scan) => scan.id);
		if (scanIds.length === 0) {
			setTrends([]);
		}
	};

	const getAriaLabelForTriggerButton = (): string => {
		const otherMode = mode === Modes.COMMON ? Modes.ALL_PAGES : Modes.COMMON;
		const action = mode === Modes.COMMON ? 'uncheck' : 'check';
		return `Mapping mode: ${mode}, ${action} this to change to ${otherMode}`;
	};

	const onIssueBarItemClick = (scanId): void => {
		history.push({
			pathname: RoutesHelper.getScanResultsPagePath(propertyId, scanId)
		} as LocationDescriptorObject);
	};

	const onSeverityBarItemClick = (scanId, severity): void => {
		history.push({
			pathname: RoutesHelper.getScanDetailsView(propertyId, scanId, TabsViews.BREAKDOWN),
			state: {
				severity,
				isTemporary: true
			}
		} as LocationDescriptorObject);
	};

	const switchButtonChange = (): void => {
		const newMode = mode === Modes.COMMON ? Modes.ALL_PAGES : Modes.COMMON;
		setMode(newMode);
	};

	const renderFiltersPart = (): JSX.Element => {
		return (
			<>
				{showScansSelectionError && (
					<p className="error-message">{`Trends is limited to ${MAX_NUMBER_OF_ISSUES} scans.`}</p>
				)}
				<div className="filters">
					<div className="scan-data-filter-dropdown">
						<EvSelect
							value={selectedScans || []}
							options={scanSelectOptions || []}
							placeholder="Select scans"
							onChange={onScanChange}
							isSearchable={false}
							isMulti
							someItemsSelectedText={someItemsSelectedText}
							onMenuClose={onFiltersClose}
						/>
					</div>
					<>
						<EvToggle
							ariaLabel={getAriaLabelForTriggerButton()}
							className="switch-all-common"
							onChange={switchButtonChange}
							checked={mode === Modes.ALL_PAGES}
							options={[Modes.ALL_PAGES, Modes.COMMON]}
						/>

						<div className="info-balloon">
							<EvInfoTooltip title="What ‘Common’ stands for?" tooltipPlace="bottom">
								Analyze trends across scans while including <br /> only the pages that were analyzed
								in all <br />
								scans.
							</EvInfoTooltip>
						</div>
						{trendsLoadingInProgress ? (
							<div className="trends-loading-in-progress">
								loading...
								<EvSpinner className="trends-loader" small />
							</div>
						) : null}
					</>
				</div>
			</>
		);
	};

	const renderTotalIssuesTrendOrNoMatch = (): JSX.Element => {
		if (!totalIssuesChartData?.length) {
			return (
				<NoDataChartWrapper
					headerTitle="Total issues across pages"
					mainTitle={NO_DATA_GRAPH_MAIN_TITLE}
					secondaryTitle={NO_DATA_GRAPH_SECONDARY_TITLE}
				/>
			);
		}
		if (noPagesMatch) {
			return (
				<NoDataChartWrapper
					headerTitle="Total issues across pages"
					mainTitle={NO_PAGES_MAIN_TITLE}
					secondaryTitle={NO_PAGES_SECONDARY_TITLE}
				/>
			);
		}
		return (
			<TotalIssuesTrend
				chartData={totalIssuesChartData}
				numberOfAvailableScans={scanSelectOptions?.length}
				onBarItemClick={onIssueBarItemClick}
			/>
		);
	};

	const renderScoreGraph = (): JSX.Element => {
		if (!scoreData?.length) {
			return (
				<NoDataChartWrapper
					headerTitle={scansScoreTitle}
					mainTitle={NO_DATA_GRAPH_MAIN_TITLE}
					secondaryTitle={NO_DATA_GRAPH_SECONDARY_TITLE}
				/>
			);
		}
		if (showScansScoreLoading) {
			return (
				<div className="scans-score-loading-in-progress">
					loading scans score...
					<EvSpinner className="scans-score-loader" small />
				</div>
			);
		}
		if (noPagesMatch) {
			return (
				<NoDataChartWrapper
					headerTitle={scansScoreTitle}
					mainTitle={NO_PAGES_MAIN_TITLE}
					secondaryTitle={NO_PAGES_SECONDARY_TITLE}
				/>
			);
		}
		return <ScansScore title={scansScoreTitle} scanScoreData={scoreData} />;
	};
	const renderSeveritiesOrNoMatch = (): JSX.Element => {
		if (!trends?.length) {
			return (
				<NoDataChartWrapper
					headerTitle="Breakdown by Severity"
					mainTitle={NO_DATA_GRAPH_MAIN_TITLE}
					secondaryTitle={NO_DATA_GRAPH_SECONDARY_TITLE}
				/>
			);
		}
		if (noPagesMatch) {
			return (
				<NoDataChartWrapper
					headerTitle="Breakdown by Severity"
					mainTitle={NO_PAGES_MAIN_TITLE}
					secondaryTitle={NO_PAGES_SECONDARY_TITLE}
				/>
			);
		}
		if (trends?.length > 0) {
			return (
				<SeverityTrends
					scanTrendsData={trends}
					chartType={severityChartType}
					setChartType={setSeverityChartType}
					onBarItemClick={onSeverityBarItemClick}
				/>
			);
		}
		return null;
	};

	const renderGraphsPart = (): JSX.Element => {
		return (
			<>
				{renderTotalIssuesTrendOrNoMatch()}
				{renderScoreGraph()}
				{renderSeveritiesOrNoMatch()}
			</>
		);
	};

	const renderNoCommonPages = (): JSX.Element => {
		return (
			<TrendsNoDataIndication
				title="No common pages"
				customIcon={NoTrendsIcon}
				renderTextMessage={() => (
					<>The selected scans have no pages in common within their scanned page list.</>
				)}
			/>
		);
	};

	if (errorLoadingTrends || errorLoadingScansList) {
		return (
			<div className="trends-page">
				<TrendsNoDataIndication customIcon={ErrorRect} />
			</div>
		);
	}

	// special case of only 1 scan
	if (scanSelectOptions?.length === 1) {
		return (
			<SingleScanChartsScreenV2
				viewId={currentViewId}
				scanId={scanSelectOptions[0].value as string}
				propertyId={propertyId}
				onBarItemClick={onSingleScanSeverityBarItemClick}
			/>
		);
	}

	if (initialLoading) {
		return (
			<div className="trends-page">
				<EvSpinner />
			</div>
		);
	}

	return (
		<div className="trends-page">
			{renderFiltersPart()}
			{mode === Modes.COMMON && !pagesInCommonExist ? renderNoCommonPages() : renderGraphsPart()}
		</div>
	);
};

export default TrendsPage;
