import React, { FC, useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies,import/no-unresolved
import { useLocation } from 'react-router-dom';

import { NewEvincedLogo } from '@evinced-private/ui-common';

import { isLocalhost } from 'src/helpers/UrlLocalhostHelper';
import { useUserTenant } from 'src/providers/userTenantProvider/UserTenantProvider';
import { initSentryUser } from 'src/services/SentryService';

import RoutesHelper from '../../helpers/RoutesHelper';
import useUniversalLogin from '../../hooks/useUniversalLogin';
import EvHeader from '../common/ev-header/EvHeader';
import HeaderNavigationBreadcrumbs from '../header-navigation/HeaderNavigationBreadcrumbs';

interface IHeaderProps {
	isAuthenticatedPage: boolean;
}

const Header: FC<IHeaderProps> = ({ isAuthenticatedPage }: IHeaderProps): JSX.Element => {
	const [shouldShowInviteOption, setShouldShowInviteOption] = useState<boolean>(false);
	const location = useLocation();
	const { isAuthenticated } = useUniversalLogin();
	const { tenant, user } = useUserTenant();

	useEffect(() => {
		if (!isLocalhost) {
			return;
		}

		if (user && tenant) {
			initSentryUser(user, tenant);
		}
	}, [user, tenant]);

	const updateShouldShowInviteOption = useCallback((): void => {
		const isUserAuthenticated = isAuthenticated();
		if (!isAuthenticatedPage || !isUserAuthenticated) {
			setShouldShowInviteOption(false);
			return;
		}
		setShouldShowInviteOption(!!tenant);
	}, [isAuthenticated, isAuthenticatedPage, tenant]);

	useEffect(() => {
		if (shouldShowInviteOption) {
			return;
		}
		updateShouldShowInviteOption();
	}, [location, shouldShowInviteOption, updateShouldShowInviteOption]);

	return (
		<EvHeader
			isAuthenticatedPage={isAuthenticated}
			logoIcon={NewEvincedLogo}
			onLogoClickUrl={RoutesHelper.getHomepagePath()}
			className="site-scanner-header"
		>
			<HeaderNavigationBreadcrumbs />
		</EvHeader>
	);
};

export default Header;
