import ServerApi from '../api/ServerApi';
import { callGETAndCache } from '../api/ServerCacheApi';
import API_URL_PATHS from '../consts/ApiUrlPaths';
import { IPropertyCollection } from '../types/CollectionType';

import logger from './Logger';

const { COLLECTIONS_URL_PATH } = API_URL_PATHS;

const getCollections = async (): Promise<IPropertyCollection[]> => {
	try {
		return await callGETAndCache(COLLECTIONS_URL_PATH);
	} catch (error) {
		logger.error(`Error in getCollections with error: ${error}`);
		return [];
	}
};

const getCollectionById = async (collectionId: string): Promise<IPropertyCollection> => {
	try {
		return await callGETAndCache(`${COLLECTIONS_URL_PATH}/${collectionId}`);
	} catch (error) {
		logger.error(
			`Error in collection service in getCollectionById with collectionId: ${collectionId}, with error: ${error}`
		);
		throw new Error(
			`Error in collection service in getCollectionById with collectionId: ${collectionId}, with error: ${error}`
		);
	}
};

const getPropertiesByCollectionId = async (collectionId: string): Promise<string[]> => {
	try {
		const result = await callGETAndCache(`${COLLECTIONS_URL_PATH}/${collectionId}/properties`);
		return result?.property_ids || [];
	} catch (error) {
		logger.error(
			`Error in collection service in getPropertiesByCollectionId with collectionId: ${collectionId}, with error: ${error}`
		);
		throw new Error(
			`Error in collection service in getPropertiesByCollectionId with collectionId: ${collectionId}, with error: ${error}`
		);
	}
};

const addPropertyToCollectionById = async (
	collectionId: string,
	propertyId: string
): Promise<string> => {
	try {
		const body = { propertyIds: [propertyId] };
		return await ServerApi.apiCall('POST', `${COLLECTIONS_URL_PATH}/${collectionId}/properties`, {
			data: body
		});
	} catch (error) {
		logger.error(
			`Error in collection service in addPropertyToCollectionById with collectionId: ${collectionId}, propertyId: ${propertyId}, with error: ${error}`
		);
		throw new Error(
			`Error in collection service in addPropertyToCollectionById with collectionId: ${collectionId}, propertyId: ${propertyId}, with error: ${error}`
		);
	}
};

const deletePropertyFromCollectionById = async (
	collectionId: string,
	propertyId: string
): Promise<string> => {
	try {
		return await ServerApi.apiCall(
			'DELETE',
			`${COLLECTIONS_URL_PATH}/${collectionId}/properties/${propertyId}`
		);
	} catch (error) {
		logger.error(
			`Error in collection service in deletePropertyFromCollectionById with collectionId: ${collectionId}, propertyId: ${propertyId}, with error: ${error}`
		);
		throw new Error(
			`Error in collection service in deletePropertyFromCollectionById with collectionId: ${collectionId}, propertyId: ${propertyId}, with error: ${error}`
		);
	}
};

export default {
	getCollections,
	getCollectionById,
	getPropertiesByCollectionId,
	addPropertyToCollectionById,
	deletePropertyFromCollectionById
};
