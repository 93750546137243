import React, { FC } from 'react';

import { BUTTON_TYPES } from '@evinced-private/ui-common';

import PropertyHelper from '../../../helpers/PropertyHelper';
import { IProperty } from '../../../interfaces/Property';
import TogglesService, { DevelopmentToggles } from '../../../services/TogglesService';
import CreatePropertyFromJsonPopup from '../../create-property/CreatePropertyFromJsonPopup';
import EditPropertyPopup from '../../edit-property/EditPropertyPopup';

import './PropertyJsonEditor.scss';

interface IPropertySettingsTitleProps {
	propertyId: string;
	goToProperty: (propertyId: string) => void;
	editProperty: (propertyForSave: IProperty, shouldCrawl: boolean) => void;
}

const PropertySettingsTitle: FC<IPropertySettingsTitleProps> = ({
	propertyId,
	goToProperty,
	editProperty
}: IPropertySettingsTitleProps) => {
	const showPropertyJsonConfig = TogglesService.getToggle(DevelopmentToggles.PROPERTY_JSON_EDITOR);

	if (!showPropertyJsonConfig) {
		return null;
	}
	if (PropertyHelper.isPropertyInDraftMode(propertyId)) {
		return (
			<div className="json-button-container">
				<CreatePropertyFromJsonPopup
					triggerButtonProps={{
						type: BUTTON_TYPES.ACTION,
						title: 'Create from JSON',
						children: 'Create from JSON',
						className: 'json-config'
					}}
					onPropertyCreated={goToProperty}
				/>
			</div>
		);
	}
	return (
		<EditPropertyPopup
			propertyId={propertyId}
			triggerButtonProps={{
				type: BUTTON_TYPES.ACTION,
				title: 'JSON Editor',
				children: 'JSON Editor',
				className: 'json-config'
			}}
			onSaveChanges={(propertyToSave) => {
				editProperty(propertyToSave, false);
			}}
		/>
	);
};

export default PropertySettingsTitle;
