import React, { FC, useCallback, useState } from 'react';

import { EvLoader } from '@evinced-private/ui-common';

import { useDashboard } from '../../../../providers/dashboard/DashboardProvider';
import { DEFAULT_SORT_OPTION } from '../../DashboardConsts';
import { TSortOption } from '../../DashboardTypes';

import TopImpactPropertiesHeader from './TopImpactPropertiesHeader';
import TopImpactingPropertiesTable from './TopImpactPropertiesTable';

import './TopImpactingProperties.scss';

const TopImpactingProperties: FC = () => {
	const [withViews, setWithViews] = useState(false);
	const [sortBy, setSortBy] = useState<[TSortOption]>([DEFAULT_SORT_OPTION]);
	const [loading, setLoading] = useState<boolean>(false);
	const { activePropertiesData, updateActivePropertiesData, loadingDuePeriodChangeForTable } =
		useDashboard();

	const getTableData = useCallback(
		async ({
			sortByValue,
			withViewsValue
		}: {
			sortByValue: [TSortOption];
			withViewsValue: boolean;
		}): Promise<void> => {
			setLoading(true);
			await updateActivePropertiesData({
				views: withViewsValue,
				sortField: sortByValue?.[0].sortBy,
				sortDirection: sortByValue?.[0].orderBy,
				pageNumber: 0,
				pageSize: 5
			});
			setLoading(false);
		},
		[updateActivePropertiesData, setLoading]
	);

	const onSortChange = (sortByValue): void => {
		setSortBy(sortByValue);
		getTableData({ sortByValue, withViewsValue: withViews });
	};

	const onWithViewsChange = (withViewsValue: boolean): void => {
		setWithViews(withViewsValue);
		getTableData({ withViewsValue, sortByValue: sortBy });
	};

	if (!activePropertiesData) {
		return <EvLoader message="Loading data..." />;
	}

	return (
		<div className="top-impacting-properties">
			<TopImpactPropertiesHeader
				withViews={withViews}
				onWithViewsChange={onWithViewsChange}
				sortBy={sortBy}
				onSortByChange={onSortChange}
			/>
			<TopImpactingPropertiesTable
				activePropetiesData={activePropertiesData}
				withViews={withViews}
				sortByColumn={sortBy}
				loading={loading || loadingDuePeriodChangeForTable}
			/>
		</div>
	);
};

export default TopImpactingProperties;
