import React from 'react';

import { EvIcon } from '@evinced-private/ui-common';

import RoutesHelper from '../../../helpers/RoutesHelper';
import EvLinkLocal from '../../common/ev-link-local/EvLinkLocal';
import NotFoundIcon from '../../icons/NotFoundIcon.svg';

import './NotFound404Page.scss';

export default (): JSX.Element => {
	return (
		<div className="error-404">
			<div className="error-404-content">
				<EvIcon icon={NotFoundIcon} />
				<div className="text-messge">
					<p>Sorry, we couldn&apos;t find what you were looking for...</p>
					<EvLinkLocal
						url={RoutesHelper.getPropertiesPagePath()}
						className="back"
						linkText="Back to All Properties"
					/>
				</div>
			</div>
		</div>
	);
};
