import React, { FC, useState } from 'react';

import { EvToggle } from '@evinced-private/ui-common';

import PropertyHelper from '../../../helpers/PropertyHelper';
import PropertySourceHelper from '../../../helpers/PropertySourceHelper';
import { IProperty } from '../../../interfaces/Property';
import { useNotifications } from '../../../providers/notificationsProvider/NotificationsConsumer';
import logger from '../../../services/Logger';
import propertiesService from '../../../services/PropertiesService';
import URL_SET_SOURCES from '../../../types/UrlSetSources';
import ChangePropertySourceConfirmPopup from '../ChangePropertySourceConfirmPopup';

interface IPropertySettingsSourcePickerProps {
	sourceMode: URL_SET_SOURCES;
	originalProperty: IProperty;
	property: IProperty;
	setLoading: (state: boolean) => void;
	getPropertyData: (sourceMode: URL_SET_SOURCES) => Promise<void>;
	setPropertySourceMode: (source: URL_SET_SOURCES) => void;
}

const PropertySettingsSourcePicker: FC<IPropertySettingsSourcePickerProps> = ({
	sourceMode,
	originalProperty,
	property,
	setLoading,
	getPropertyData,
	setPropertySourceMode
}: IPropertySettingsSourcePickerProps) => {
	const [isPopupLoading, setIsPopupLoading] = useState<boolean>(false);
	const [isPopupOpen, setPopupOpen] = useState(false);
	const [isCrawlRunning, setIsCrawlRunning] = useState<boolean>(false);
	const notificationsContext = useNotifications();
	const isListMode = PropertySourceHelper.isListMode(sourceMode);

	const updateIsCrawlRunningState = (): Promise<void> => {
		if (!property.id) {
			// no need to check if a crawl exists becuase the property isn't created yet
			return Promise.resolve();
		}
		return propertiesService.isCrawlCurrentlyRunning(property.id).then((result) => {
			setIsCrawlRunning(result);
		});
	};

	const onChangeToDiscoveryMode = (): void => {
		const shouldOpenConfirmPopup = !PropertyHelper.isPropertyEmpty(property, isListMode);

		if (shouldOpenConfirmPopup) {
			setPopupOpen(true);
		} else {
			setLoading(true);
			setPropertySourceMode(URL_SET_SOURCES.URL_DISCOVERY);
		}
	};

	const onChangeToListMode = (): void => {
		const shouldOpenConfirmPopup = !PropertyHelper.isPropertyEmpty(property, isListMode);

		if (!shouldOpenConfirmPopup && !isCrawlRunning) {
			setLoading(true);
			setPropertySourceMode(URL_SET_SOURCES.UPLOAD);
		} else {
			setPopupOpen(true);

			if (property.id) {
				setIsPopupLoading(true);
				updateIsCrawlRunningState()
					.then(() => {
						setIsPopupLoading(false);
					})
					.catch((err) => {
						notificationsContext.alert({
							errorMessage: 'Error while trying to change mode.',
							serverError: err
						});
					});
			}
		}
	};

	const onCrawlCanceled = async (onClose): Promise<void> => {
		getPropertyData(URL_SET_SOURCES.UPLOAD)
			.catch((err) => {
				logger.error('Error cancelling crawl', err);
			})
			.finally(() => {
				onClose();
			});
	};

	const getAriaLabelForTriggerButton = (): string => {
		const { currentMode, otherMode } = PropertySourceHelper.getSourceDisplayText(sourceMode);
		const checkText = isListMode ? 'uncheck' : 'check';
		return `Mapping mode: ${currentMode}, ${checkText} this to change to ${otherMode}`;
	};

	const onConfirm = (): void => {
		setLoading(true);
		setPropertySourceMode(isListMode ? URL_SET_SOURCES.URL_DISCOVERY : URL_SET_SOURCES.UPLOAD);
		setPopupOpen(false);
	};

	return (
		<>
			<div className="property-source-selector">
				<div className="new-label">Mapping via</div>
				<EvToggle
					ariaLabel={getAriaLabelForTriggerButton()}
					checked={!isListMode}
					onChange={() => {
						if (isListMode) {
							onChangeToDiscoveryMode();
						} else {
							onChangeToListMode();
						}
					}}
					options={['Crawl', 'List']}
				/>
			</div>
			<ChangePropertySourceConfirmPopup
				originalProperty={originalProperty}
				sourceMode={sourceMode}
				isOpen={isPopupOpen}
				isCrawlRunning={isCrawlRunning}
				isPopupLoading={isPopupLoading}
				setIsOpen={setPopupOpen}
				onConfirm={onConfirm}
				onCrawlCanceled={onCrawlCanceled}
			/>
		</>
	);
};

export default PropertySettingsSourcePicker;
