import React, { FC } from 'react';

import { EvSelect, EvSwitch, EvTitle } from '@evinced-private/ui-common';

import { PROPERTIES_INFO_SORT_OPTIONS } from '../../DashboardConsts';
import { TPropertiesInfoSortOptions } from '../../DashboardTypes';
import VerticalSeparator from '../kpis/VerticalSeparator';

import './TopImpactPropertiesHeader.scss';

interface ITopImpactPropertiesHeader {
	withViews: boolean;
	onWithViewsChange: (isWithView: boolean) => void;
	sortBy: TPropertiesInfoSortOptions;
	onSortByChange: (TPropertiesInfoSortOptions) => void;
}

const tableTitle = 'Highlighted properties';
const tableTooltip =
	'The properties that showed the largest change in percentage, critical issues, and/or pages scanned within the selected time period.';
const TopImpactPropertiesHeader: FC<ITopImpactPropertiesHeader> = ({
	withViews,
	onWithViewsChange,
	sortBy,
	onSortByChange
}) => {
	return (
		<div className="top-impact-properties-header">
			<EvTitle
				titleText={tableTitle}
				MoreInfoContent={<div>{tableTooltip}</div>}
				headingLevel={3}
				className="title-header"
			/>
			<div className="update-actions">
				<EvSwitch
					onChange={onWithViewsChange}
					checked={withViews}
					label="Include Views"
					className="include-views"
				/>
				<VerticalSeparator className="sort-actions-separator" />
				<div className="sort-by-selection">
					<div className="sort-by-label">Sort by</div>
					<EvSelect
						onChange={(value) => onSortByChange([value])}
						options={PROPERTIES_INFO_SORT_OPTIONS}
						value={sortBy}
						className="sort-by-select-dropdown"
						placeholder=""
						ariaLabel="Sort by"
					/>
				</div>
			</div>
		</div>
	);
};

export default TopImpactPropertiesHeader;
