import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { EvIcon, EvSpinner } from '@evinced-private/ui-common';

import logger from '../../../../services/Logger';
import ScreenshotServiceV2, { ScreenshotDetails } from '../../../../services/ScreenshotServiceV2';
import NoPreviewImage from '../../../icons/NoPreviewImage.svg';

import './ScreenshotCellFormatter.scss';

interface IScreenshotCellFormatterProps {
	cellDescriptor: string;
	screenshotDetails: ScreenshotDetails;
	imageContainerClassName?: string;
	screenshotImgClassName?: string;
	noCrop?: boolean;
}
const ScreenshotCellFormatter: FC<IScreenshotCellFormatterProps> = ({
	screenshotDetails,
	cellDescriptor,
	imageContainerClassName,
	screenshotImgClassName,
	noCrop = false
}: IScreenshotCellFormatterProps) => {
	const [isLoading, setLoading] = useState(true);
	const [image, setImage] = useState<string>(null);
	const isMounted = useRef(false);

	const resolveImage = useCallback(async (): Promise<void> => {
		try {
			const screenshotImageData = screenshotDetails
				? await ScreenshotServiceV2.getIssueOnlyScreenshot(screenshotDetails, 300, noCrop)
				: null;
			// use isMounted to avoid setting state of an unmounted component
			if (!isMounted.current) {
				return;
			}
			setImage(screenshotImageData);
		} catch (e) {
			logger.warn(e);
			setImage(null);
		}
		setLoading(false);
	}, [screenshotDetails, isMounted, noCrop]);

	// resolving image when url/selector is changed.
	// this makes the cell re-render when paginating
	useEffect(() => {
		isMounted.current = true;
		if (!image) {
			resolveImage();
		}
		return () => {
			isMounted.current = false;
		};
	}, [image, resolveImage]);

	const renderImagePlaceholder = (): JSX.Element => {
		return <EvIcon icon={NoPreviewImage} title="not available" className="preview-not-available" />;
	};

	const renderImageButton = (): JSX.Element => {
		if (!image) {
			return renderImagePlaceholder();
		}

		return (
			<img
				src={image}
				alt={`${cellDescriptor} issue screenshot`}
				aria-label={`${cellDescriptor} issue details`}
				className={classNames('screenshot-cell-img', {
					[screenshotImgClassName]: !!screenshotImgClassName
				})}
			/>
		);
	};

	return (
		<div className="screenshot-cell">
			<div
				className={classNames('image-container', {
					[imageContainerClassName]: !!imageContainerClassName
				})}
			>
				{isLoading ? <EvSpinner /> : renderImageButton()}
			</div>
		</div>
	);
};

export default ScreenshotCellFormatter;
