import _ from 'lodash';

import ServerApi from '../api/ServerApi';
import API_URL_PATHS from '../consts/ApiUrlPaths';
import { addCollectionsToUrlParams } from '../helpers/CollectionsHelper';
import propertyHelper from '../helpers/PropertyHelper';
import PropertySourceHelper from '../helpers/PropertySourceHelper';
import ScanCrawlStateHelper from '../helpers/ScanCrawlStateHelper';
import { IProperty } from '../interfaces/Property';
import { IUrlSet } from '../interfaces/UrlSet';
import URL_SET_SOURCES from '../types/UrlSetSources';

import logger from './Logger';
import urlSetService, { CreateUrlSetRequestBody } from './UrlSetService';

const { PROPERTIES_URL_PATH } = API_URL_PATHS;

async function getProperty(id: string): Promise<IProperty> {
	const url = `${PROPERTIES_URL_PATH}/${id}?metadata=false`;
	return ServerApi.apiCall('GET', url).then((property) => {
		delete property.urlSetId; // temporary until server stops sending property.urlSetId
		return property;
	});
}

async function createPropertyFromJSON(propertyData: IProperty): Promise<IProperty> {
	const { scheduledJobConfiguration, urlSetConfiguration } = propertyData;
	const body: IProperty = {
		urlSetConfiguration: {
			...urlSetConfiguration,
			source: URL_SET_SOURCES.URL_DISCOVERY
		},
		name: propertyData.name,
		...(scheduledJobConfiguration && { scheduledJobConfiguration })
		// the JSON editor can only create discover properties, (it's disabled in UPLOAD properties).
	};

	return ServerApi.apiCall('POST', `${PROPERTIES_URL_PATH}`, { data: body });
}

async function createUploadProperty(propertyData: IProperty): Promise<IProperty> {
	const { name, scheduledJobConfiguration, urls } = propertyData;
	const body: IProperty = {
		name,
		scanConfiguration: {},
		urlSetConfiguration: {
			source: URL_SET_SOURCES.UPLOAD
		},
		...(scheduledJobConfiguration && { scheduledJobConfiguration })
	};

	const property = await ServerApi.apiCall('POST', PROPERTIES_URL_PATH, { data: body });
	const urlSetConfig: CreateUrlSetRequestBody = {
		propertyId: property.id,
		urls
	};
	await urlSetService.createUrlSet(urlSetConfig);
	return getProperty(property.id);
}

async function createDiscoveryProperty(
	propertyData: IProperty,
	shouldCrawl: boolean
): Promise<IProperty> {
	logger.info(`Saving new property: ${propertyData.name}`);
	const { scanConfiguration, urlSetConfiguration, scheduledJobConfiguration } = propertyData;
	const body: IProperty = {
		name: propertyData.name,
		scanConfiguration: scanConfiguration || {},
		urlSetConfiguration,
		...(scheduledJobConfiguration && { scheduledJobConfiguration })
	};

	let propertyResponsePromise = ServerApi.apiCall('POST', PROPERTIES_URL_PATH, { data: body });
	if (shouldCrawl) {
		const propertyResponse = await propertyResponsePromise;
		const propertyId = propertyResponse.id;
		const createUrlSetRequestBody = { propertyId };
		await urlSetService.createUrlSet(createUrlSetRequestBody);
		propertyResponsePromise = getProperty(propertyId);
	}
	return propertyResponsePromise;
}

async function createNewProperty(
	propertyData: IProperty,
	shouldCrawl: boolean,
	isListMode = false
): Promise<IProperty> {
	if (isListMode) {
		return createUploadProperty(propertyData);
	}
	return createDiscoveryProperty(propertyData, shouldCrawl);
}

async function updateProperty(propertyData: IProperty): Promise<IProperty> {
	const url = `${PROPERTIES_URL_PATH}/${propertyData.id}`;
	return ServerApi.apiCall('PUT', url, { data: propertyData });
}

async function getAllProperties(
	collectionIds: string[] = [],
	showOnlyPropertiesInMyCollection = false
): Promise<IProperty[]> {
	const url: string = addCollectionsToUrlParams(
		PROPERTIES_URL_PATH,
		collectionIds,
		showOnlyPropertiesInMyCollection,
		true
	);

	return ServerApi.apiCall('GET', url);
}

async function deleteProperty(id: string): Promise<null> {
	const url = `${PROPERTIES_URL_PATH}/${id}`;
	return ServerApi.apiCall('DELETE', url);
}
async function isPdfMode(propertyId: string): Promise<boolean> {
	if (!propertyId) {
		return false;
	}
	const propertyData = await getProperty(propertyId);
	return propertyHelper.isPdfMode(propertyData);
}

async function isCrawlCurrentlyRunning(propertyId: string): Promise<boolean> {
	if (!propertyId) {
		return false;
	}
	const propertyData = await getProperty(propertyId);
	const state = propertyData?.lastUrlSet?.state;
	return ScanCrawlStateHelper.isInProgress(state);
}

const getPropertyData = async (propertyId): Promise<IProperty | void> => {
	if (propertyHelper.isPropertyInDraftMode(propertyId)) {
		return null;
	}

	logger.debug('Getting info for property with ID: ', propertyId);

	return getProperty(propertyId).then((propertyFromServer) => {
		const isListMode = PropertySourceHelper.isListMode(
			propertyFromServer.urlSetConfiguration.source
		);
		// if listmode, get property urls and add them to the property
		if (isListMode) {
			return urlSetService
				.getUrlSetsUrls(propertyFromServer.scanConfiguration.urlSetId)
				.then((urls) => {
					propertyFromServer.urls = urls;
					// remove leftovers from previous 'DISCOVERY' source
					delete propertyFromServer.urlSetConfiguration.discoveryConfiguration;
					return propertyFromServer;
				});
		}
		// if discovery mode, ensure property have a default grouping rule
		propertyFromServer = propertyHelper.ensurePropertyHasDefaultRule(propertyFromServer);

		return propertyFromServer;
	});
};

const createUrlSetAndUpdateProperty = async (
	propertyToSave: IProperty,
	isListMode: boolean
): Promise<IProperty> => {
	const isCrawlRunning = await isCrawlCurrentlyRunning(propertyToSave.id);

	// if crawl is running, stop it before creating a new one
	if (isCrawlRunning) {
		await urlSetService.deleteUrlSet(propertyToSave.scanConfiguration.urlSetId);
	}

	await updateProperty(propertyToSave);
	const createUrlSetRequestBody: CreateUrlSetRequestBody = { propertyId: propertyToSave.id };
	if (isListMode) {
		createUrlSetRequestBody.urls = propertyToSave.urls;
	}
	await urlSetService.createUrlSet(createUrlSetRequestBody);
	return getProperty(propertyToSave.id);
};

async function getUrlSetByPropertyId(propertyId: string): Promise<IUrlSet> {
	const propertyData = await getProperty(propertyId);
	if (!propertyData.scanConfiguration.urlSetId) {
		return null;
	}
	return urlSetService.getUrlSet(propertyData.scanConfiguration.urlSetId);
}

const changePropertySourceModeToList = async (property: IProperty): Promise<IProperty> => {
	const { seeds } = property.urlSetConfiguration.discoveryConfiguration;
	let urls = [];
	if (property.scanConfiguration?.urlSetId) {
		const currentDiscoveryUrlSetUrls = await urlSetService.getUrlSetsUrls(
			property.scanConfiguration?.urlSetId
		);

		if (currentDiscoveryUrlSetUrls.length > 0) {
			urls = currentDiscoveryUrlSetUrls;
		}
	} else if (seeds) {
		urls = seeds.map((seed) => seed.url);
	}

	return {
		id: property.id,
		name: property.name,
		urlSetConfiguration: {
			source: URL_SET_SOURCES.UPLOAD
		},
		urls,
		scanConfiguration: property.scanConfiguration ?? {}
	};
};

const changePropertySourceModeToDiscovery = (property: IProperty): IProperty => {
	let newProperty: IProperty = propertyHelper.getDefaultPropertyFromTemplate();
	_.merge(newProperty, {
		id: property.id,
		name: property.name,
		scanConfiguration: property.scanConfiguration ?? {},
		urlSetConfiguration: {
			source: URL_SET_SOURCES.URL_DISCOVERY,
			discoveryConfiguration: {
				seeds: property.urls ? property.urls.map((url) => ({ url })) : []
			}
		}
	});
	newProperty = propertyHelper.ensurePropertyHasDefaultRule(newProperty);
	return newProperty;
};

const changePropertySourceMode = async (
	property: IProperty,
	newSource: URL_SET_SOURCES
): Promise<IProperty> => {
	const isCurrentlyInListMode = PropertySourceHelper.isListMode(
		property.urlSetConfiguration?.source
	);
	const shouldBeInListMode = PropertySourceHelper.isListMode(newSource);

	// when changing from discovery to upload, copy all existing seeds
	if (shouldBeInListMode && !isCurrentlyInListMode) {
		return changePropertySourceModeToList(property);
	}

	// when changing from upload to discovery, copy all existing urls to seeds
	if (!shouldBeInListMode && isCurrentlyInListMode) {
		const newProperty = propertyHelper.ensurePropertyHasDefaultRule(property);
		return changePropertySourceModeToDiscovery(newProperty);
	}

	return property;
};

const propertiesService = {
	createNewProperty,
	createPropertyFromJSON,
	updateProperty,
	createUrlSetAndUpdateProperty,
	getAllProperties,
	getProperty,
	getPropertyData,
	deleteProperty,
	getUrlSetByPropertyId,
	changePropertySourceMode,
	isCrawlCurrentlyRunning,
	isPdfMode
};
export default propertiesService;
