/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { FC } from 'react';

import { EvLift, EvLoader, EvTableColumn, SORT_ORDER } from '@evinced-private/ui-common';

import { ALL_CONTENT_VIEW_LABEL } from '../../../../components/common/ev-property-views/consts/PropertyViews';
import SiteScannerTable from '../../../../components/common/site-scanner-table/SiteScannerTable';
import { roundNumberSupportZero } from '../../../../helpers/dashboard/DashboardHelper';
import FormatHelper from '../../../../helpers/FormatHelper';
import ScoreHelper from '../../../../helpers/ScoreHelper';
import { getLabel, PROPERTIES_INFO_TYPES } from '../../DashboardConsts';
import { TActiveProperty, TPropertiesInfoSortOptions } from '../../DashboardTypes';

import PropertViewLink from './PropertViewLink';

interface ITopImpactingPropertiesTable {
	activePropetiesData: TActiveProperty[];
	withViews: boolean;
	sortByColumn: TPropertiesInfoSortOptions;
	loading: boolean;
}

type TTableData = {
	[PROPERTIES_INFO_TYPES.PROPERTY_VIEW_NAME]: {
		propertyName: string;
		propertyId: string;
		viewName: string;
		viewId: string;
	};
	[PROPERTIES_INFO_TYPES.SCORE_LIFT]: number;
	[PROPERTIES_INFO_TYPES.SCORE]: number;
	[PROPERTIES_INFO_TYPES.CRITICAL_ISSUES_COUNT]: number;
	[PROPERTIES_INFO_TYPES.PAGES_COUNT]: number;
};

const tableTitle = 'highlighted properties';

const TopImpactingPropertiesTable: FC<ITopImpactingPropertiesTable> = ({
	activePropetiesData,
	withViews,
	sortByColumn,
	loading
}) => {
	if (loading) {
		return <EvLoader message="Loading data..." />;
	}

	const getTableData = (): TTableData[] => {
		if (!activePropetiesData) {
			return [];
		}
		return activePropetiesData.map((activeProp) => ({
			id: `${activeProp.property.id}${activeProp.view?.id}`,
			[PROPERTIES_INFO_TYPES.PROPERTY_VIEW_NAME]: {
				propertyName: activeProp.property.name,
				propertyId: activeProp.property.id,
				viewName: activeProp.view?.name,
				viewId: activeProp.view?.id
			},
			[PROPERTIES_INFO_TYPES.SCORE_LIFT]: activeProp.score?.liftPercentage,
			[PROPERTIES_INFO_TYPES.SCORE]: activeProp.score?.score,
			[PROPERTIES_INFO_TYPES.CRITICAL_ISSUES_COUNT]: activeProp.criticalIssuesCount,
			[PROPERTIES_INFO_TYPES.PAGES_COUNT]: activeProp.pagesCount
		}));
	};

	const getHeaderStyle = (headerName): object => {
		if (sortByColumn[0].sortBy === headerName) {
			return { color: '#131516' };
		}
		return null;
	};

	const getColumns = (): EvTableColumn[] => {
		return [
			{
				dataField: PROPERTIES_INFO_TYPES.PROPERTY_VIEW_NAME,
				text: getLabel(PROPERTIES_INFO_TYPES.PROPERTY_VIEW_NAME),
				sort: false,
				style: { maxWidth: '270px' },
				formatter: ({
					propertyName,
					propertyId,
					viewName = ALL_CONTENT_VIEW_LABEL,
					viewId = 'all'
				}: {
					propertyName: string;
					propertyId: string;
					viewName?: string;
					viewId?: string;
				}) => {
					return (
						<PropertViewLink
							propertyId={propertyId}
							viewId={viewId}
							propertyName={propertyName}
							viewName={viewName}
							withViews={withViews}
						/>
					);
				}
			},
			{
				dataField: PROPERTIES_INFO_TYPES.SCORE_LIFT,
				text: getLabel(PROPERTIES_INFO_TYPES.SCORE_LIFT),
				headerStyle: getHeaderStyle(PROPERTIES_INFO_TYPES.SCORE_LIFT),
				sort: false,
				formatter: (lift: number) => {
					const calculatedLift = roundNumberSupportZero(lift);
					return <EvLift liftNumber={calculatedLift} small={true} success={Number(lift) > 0} />;
				}
			},
			{
				dataField: PROPERTIES_INFO_TYPES.SCORE,
				text: getLabel(PROPERTIES_INFO_TYPES.SCORE_100),
				headerStyle: getHeaderStyle(PROPERTIES_INFO_TYPES.SCORE),
				style: { textAlign: 'center' },
				sort: false,
				formatter: (score: number) => {
					if (!score && score !== 0) {
						return 'N/A';
					}
					return ScoreHelper.roundScore(score);
				}
			},
			{
				dataField: PROPERTIES_INFO_TYPES.CRITICAL_ISSUES_COUNT,
				text: getLabel(PROPERTIES_INFO_TYPES.CRITICAL_ISSUES_COUNT),
				headerStyle: {
					...getHeaderStyle(PROPERTIES_INFO_TYPES.CRITICAL_ISSUES_COUNT),
					paddingLeft: '2px'
				},
				style: { textAlign: 'center' },
				sort: false,
				formatter: (criticalIssuesCount: number) => {
					return FormatHelper.nFormatter(criticalIssuesCount);
				}
			},
			{
				dataField: PROPERTIES_INFO_TYPES.PAGES_COUNT,
				text: getLabel(PROPERTIES_INFO_TYPES.PAGES_COUNT),
				headerStyle: { width: '30px' },
				style: { textAlign: 'right', paddingRight: '5px' },
				sort: false,
				formatter: (pagesCount: number) => {
					return FormatHelper.nFormatter(pagesCount);
				}
			}
		];
	};

	const tableData = getTableData();

	return (
		<SiteScannerTable
			tableId="top-five-highlighted-properties-table"
			className="highlighted-properties-table"
			tableData={tableData}
			totalCount={tableData.length}
			columns={getColumns()}
			options={{
				title: tableTitle,
				caption: tableTitle,
				dataType: tableTitle,
				defaultSorted: [
					{
						dataField: PROPERTIES_INFO_TYPES.SCORE_LIFT,
						order: SORT_ORDER.DESC
					}
				]
			}}
			showInfoRow={false}
			lightMode={true}
		/>
	);
};

export default TopImpactingPropertiesTable;
