import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EvErrorCard } from '@evinced-private/ui-common';

import IssueDetailsBlock from '../../components/issue-details-block/IssueDetailsBlock';

import './IssueDetailsPage.scss';

const IssueDetailsPage: FC = () => {
	const [error, setError] = useState(null);
	const { issueId, scanId } = useParams<{
		issueId?: string;
		propertyId?: string;
		scanId?: string;
	}>();

	useEffect(() => {
		// setting white background to main container since it is the only page with such a colour
		const app = document.getElementsByClassName('app')[0] as HTMLElement;
		app.style.backgroundColor = 'white';
		// setting boxShadow to header since it is the only page with such a header style
		const header = document.getElementsByClassName('ev-header')[0] as HTMLElement;
		header.style.boxShadow = '0px 1px 5px rgba(51, 64, 85, 0.1)';
	}, []);

	return (
		<div className="issue-details-page">
			{error ? (
				<EvErrorCard title="Error" contactUsMessage={error?.error?.message} />
			) : (
				<IssueDetailsBlock issueId={issueId} scanId={scanId} setError={setError} isPage={true} />
			)}
		</div>
	);
};

export default IssueDetailsPage;
