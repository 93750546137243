import React from 'react';

import {
	BUTTON_TYPES,
	DropdownOptionType,
	EvDropdownMenu,
	EvIcon,
	EvTableColumn,
	IDropdownMenuOption
} from '@evinced-private/ui-common';

import { formatDate } from '../../helpers/DateFormatHelper';
import { IPropertyViewRow } from '../../interfaces/PropertyViews';
import EditViewButton from '../edit-view-popup/EditViewButton';
import EllipsisIcon from '../icons/EllipsisIcon.svg';

const renderLastUpdatedCell = (cell: string, row: IPropertyViewRow): string => {
	return row.updatedTime ? formatDate(cell) : 'N/A';
};

const renderNameCell = (cell: string, row: IPropertyViewRow): JSX.Element => {
	return (
		<EditViewButton
			propertyId={row.propertyId}
			view={row}
			type={BUTTON_TYPES.ICON}
			innerText={cell}
		/>
	);
};

const renderActionsCell = (cell: string, row: IPropertyViewRow): JSX.Element => {
	const options: IDropdownMenuOption[] = [
		{
			title: 'Edit',
			id: 'edit-view',
			type: DropdownOptionType.CUSTOM,
			renderOption: () => (
				<EditViewButton
					propertyId={row.propertyId}
					view={row}
					type={BUTTON_TYPES.ICON}
					innerText="Edit"
				/>
			)
		}
	];

	return (
		<div className="actions-cell">
			<EvDropdownMenu
				id={`menu-${row.id}`}
				options={options}
				triggerButtonProps={{
					type: BUTTON_TYPES.ICON,
					ariaHaspopup: 'menu',
					title: 'Property View Actions',
					children: (
						<div className="menu-trigger-icon">
							<EvIcon icon={EllipsisIcon} small />
						</div>
					)
				}}
			/>
		</div>
	);
};

export const getViewsTableColumns = (): EvTableColumn[] => {
	return [
		{
			dataField: 'name',
			text: 'Name',
			style: { width: '60%' },
			headerStyle: { width: '60%' },
			sort: true,
			formatter: renderNameCell
		},
		{
			dataField: 'updatedTime',
			text: 'Updated',
			style: { width: '30%' },
			headerStyle: { width: '30%' },
			sort: true,
			formatter: renderLastUpdatedCell
		},
		{
			dataField: 'actions',
			text: 'Actions',
			style: { width: '10%' },
			classes: 'actions-cell-td',
			headerStyle: { width: '10%' },
			sort: false,
			formatter: renderActionsCell
		}
	];
};

export const getFilterViews = (
	views: IPropertyViewRow[],
	searchFilter: string
): IPropertyViewRow[] => {
	return views.filter((property) => property.searchStr.includes(searchFilter.toLowerCase()));
};
