import React, { FC, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';

import { getCurrentTab, hasEnoughActiveProperties } from '../../helpers/dashboard/DashboardHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import { useDashboard } from '../../providers/dashboard/DashboardProvider';
import PropertiesPageV2 from '../properties/PropertiesPageV2';

import InfoLine from './components/info-line/InfoLine';
import DashboardV2 from './DashboardV2';
import HomePageNavigation from './HomePageNavigation';
import { HOME_PAGES } from './HomePageTypes';

import './HomePage.scss';

const HomePage: FC = () => {
	const location = useLocation();
	const { numberOfRunningScans, numberOfActiveProperties } = useDashboard();

	const currentTab = useMemo(() => getCurrentTab(location.pathname), [location.pathname]);

	const renderPage = useCallback((): JSX.Element => {
		switch (currentTab) {
			case HOME_PAGES.ALL_PROPERTIES:
				return <PropertiesPageV2 />;
			case HOME_PAGES.DASHBOARD:
			default:
				return <DashboardV2 />;
		}
	}, [currentTab]);

	const isPageDashboard = useMemo(
		() => location.pathname !== RoutesHelper.getPropertiesPagePath(),
		[location.pathname]
	);

	return (
		<div className="home-page">
			<div className="navigation-bar-container">
				<HomePageNavigation />
				{isPageDashboard &&
					numberOfRunningScans > 0 &&
					hasEnoughActiveProperties(numberOfActiveProperties) && (
						<div className="info-line">
							<InfoLine msg={`${numberOfRunningScans} scans in progress`} />
						</div>
					)}
			</div>
			{renderPage()}
		</div>
	);
};

export default HomePage;
