import React, { FC, ReactNode, useMemo } from 'react';

import { EvContactSupportEmailLink, EvErrorCard } from '@evinced-private/ui-common';

import { ACTIVATION_ERROR } from '../../services/TenantsService';
import HappyLockIcon from '../icons/HappyLockIcon.svg';

interface IProductActivationErrorProps {
	errorType: ACTIVATION_ERROR;
}

const ProductActivationError: FC<IProductActivationErrorProps> = ({
	errorType
}: IProductActivationErrorProps) => {
	const cardDetails: { title: string; content: ReactNode } = useMemo(() => {
		if (errorType === ACTIVATION_ERROR.NOT_A_COMPANY_EMAIL) {
			return {
				title: 'Product access denied',
				content: (
					<>
						This product can be activated only for users which provided a company based email.
						<br />
						For a free license based on personal email, please apply our Community Program. <br />
						For more information, please contact us at <EvContactSupportEmailLink />
					</>
				)
			};
		}
		if (errorType === ACTIVATION_ERROR.LICENSE_EXPIRED) {
			return {
				title: 'Product trial expired',
				content: (
					<>
						Seems like the product was already activated for this account and your trial period has
						expired.
						<br />
						For more information, please contact us at <EvContactSupportEmailLink />
					</>
				)
			};
		}
		// GENERAL
		return {
			title: 'Product Activation Error',
			content: (
				<>
					We&apos;re having issues with activating your account. For more information, please
					contact us at <EvContactSupportEmailLink />
				</>
			)
		};
	}, [errorType]);

	return (
		<div className="product-activation-page">
			<EvErrorCard
				title={cardDetails.title}
				contactUsMessage={cardDetails.content}
				mainImage={HappyLockIcon}
			/>
		</div>
	);
};

export default ProductActivationError;
