import React, { createContext, FC, useContext } from 'react';

import TablesStore from '../../stores/TablesStore';

const TablesStoreContext = createContext<TablesStore>(new TablesStore());

const TablesStoreProvider: FC<{ store: TablesStore }> = ({ store, children }) => {
	return <TablesStoreContext.Provider value={store}>{children}</TablesStoreContext.Provider>;
};

const useTablesStore = (): TablesStore => {
	return useContext(TablesStoreContext);
};

export { TablesStoreProvider, useTablesStore };
