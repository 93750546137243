import React, { FC, useMemo } from 'react';

import { BUTTON_TYPES, EvButton, EvIcon } from '@evinced-private/ui-common';

import defaultGroupingRuleUrl from '../../consts/defaultGroupingRuleUrl';
import queryParamsOptions from '../../consts/queryParamsOptions';
import PropertyHelper from '../../helpers/PropertyHelper';
import { IGroupingRule } from '../../interfaces/GroupingRule';
import { IProperty } from '../../interfaces/Property';
import EditIcon from '../icons/EditIcon.svg';

import './GroupingRuleLine.scss';

interface IGroupingRulesLine {
	property?: IProperty;
	setRuleForEdit: (data: IGroupingRule) => void;
	title?: string;
	showSampleSize?: boolean;
}

const GroupingRulesLine: FC<IGroupingRulesLine> = ({
	title,
	property,
	showSampleSize = true,
	setRuleForEdit
}) => {
	const groupingRules = PropertyHelper.getPropertyDiscoveryGroupingRules(property);

	const defaultRule = useMemo(() => {
		return groupingRules.find((rule) => rule.urlPattern === defaultGroupingRuleUrl);
	}, [groupingRules]);

	const attributeType = useMemo(() => {
		return defaultRule?.ignoreFragment && defaultRule?.caseSensitive ? 'default' : 'custom';
	}, [defaultRule]);

	let queryParams = queryParamsOptions.INCLUDE_ALL;
	if (defaultRule?.ignoreQueryParameters) {
		queryParams =
			defaultRule.excludedQueryParameters && defaultRule.excludedQueryParameters.length > 0
				? queryParamsOptions.LIST
				: queryParamsOptions.IGNORE_ALL;
	}
	const maxSamplesCount = `${defaultRule?.maxSamplesCount ?? 'Unlimited'}`;
	return (
		<div className="grouping-rules-line">
			{title}
			<div className="rule-line default-rule">
				<div className="rule-info">
					<div className="rule-field">
						<div className="prop-name">Default rule</div>
					</div>
					<div className="rule-field">
						<div className="prop-name">URL:</div>
						<div className="prop-value" title={defaultRule?.urlPattern}>
							{defaultRule?.urlPattern || '*'}
						</div>
					</div>
					{showSampleSize && (
						<div className="rule-field">
							<div className="prop-name">Sampling:</div>
							<div className="prop-value" title={maxSamplesCount}>
								{maxSamplesCount}
							</div>
						</div>
					)}
					<div className="rule-field">
						<div className="prop-name long-name">Query parameters:</div>
						<div className="prop-value" title={queryParams}>
							{queryParams}
						</div>
					</div>
					<div className="rule-field">
						<div className="prop-name">Attributes:</div>
						<div className="prop-value" title={attributeType}>
							{attributeType}
						</div>
					</div>
				</div>
				<div className="rule-actions">
					<EvButton
						type={BUTTON_TYPES.ICON}
						title="edit"
						className="rule-action"
						onClick={() => {
							setRuleForEdit(defaultRule);
						}}
					>
						<EvIcon icon={EditIcon} />
					</EvButton>
				</div>
			</div>
		</div>
	);
};

export default GroupingRulesLine;
