import React, { FC } from 'react';

import { OptionType } from '@evinced-private/ui-common';

import { IScopeRuleUIFormat } from '../../../../interfaces/ScopeRuleUIFormat';
import SCOPE_TYPES from '../../../../types/scope/ScopeTypes';
import IssueTypesRuleRow from '../scope-rule-row/IssueTypesRuleRow';
import UrlRuleRow from '../scope-rule-row/UrlRuleRow';

interface IScopeRulesListProps {
	rulesList: IScopeRuleUIFormat[];
	issueTypesList: OptionType[];
	onRemove: (id: string) => void;
	onRuleChange: (rule: IScopeRuleUIFormat) => void;
}

const ScopeRulesList: FC<IScopeRulesListProps> = ({
	rulesList,
	issueTypesList,
	onRemove,
	onRuleChange
}) => {
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{rulesList?.map((rule: IScopeRuleUIFormat) => {
				if (rule.scopeType === SCOPE_TYPES.URL) {
					return (
						<UrlRuleRow
							key={rule.id}
							rule={rule}
							onRuleChange={onRuleChange}
							onRemove={() => {
								onRemove(rule.id);
							}}
						/>
					);
				}
				if (rule.scopeType === SCOPE_TYPES.ISSUE_TYPES) {
					return (
						<IssueTypesRuleRow
							key={rule.id}
							rule={rule}
							issueTypesList={issueTypesList}
							onRuleChange={onRuleChange}
							onRemove={() => {
								onRemove(rule.id);
							}}
						/>
					);
				}
				return null;
			})}
		</>
	);
};

export default ScopeRulesList;
