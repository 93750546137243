import React, {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';

import { PRODUCT_TYPES, UsageEventsService } from '@evinced-private/ui-common';

import ServerApi from '../../api/ServerApi';
import { USAGE_METRICS } from '../../consts/usageMetrics';
import EnvironmentHelper from '../../helpers/EnvironmentHelper';
import { ITenant, ProductType, TenantProduct } from '../../interfaces/Tenant';
import { IUser, ROLE_TYPE_ENTITY } from '../../interfaces/User';
import AuthenticationService from '../../services/AuthenticationService';
import TenantsService from '../../services/TenantsService';
import TogglesService, { DevelopmentToggles } from '../../services/TogglesService';
import UserService from '../../services/UserService';

interface IUserTenant {
	user: IUser;
	tenant: ITenant;
	hasAdminPermissions: () => boolean;
	isReadOnlyUser: () => boolean;
	updateUserTenant: (force?: boolean) => Promise<void>;
	getProductIdByType: (type: ProductType) => string;
}

const UserTenantContext = createContext<IUserTenant>(null);

const UserTenantProvider: FC = ({ children }) => {
	const [user, setUser] = useState<IUser>(null);
	const [tenant, setTenant] = useState<ITenant>(null);

	const getProductIdByType = useCallback(
		(productType: ProductType): string => {
			const product = tenant?.products?.find(
				(product: TenantProduct) => product.type === productType
			);
			return product?.id;
		},
		[tenant]
	);

	const updateUserTenant = useCallback(
		async (force = false): Promise<void> => {
			if (AuthenticationService.isLoggedIn()) {
				if (!user || force) {
					const currentUser = await UserService.getUserMetadata();
					setUser(currentUser);
				}
				if (!tenant || force) {
					const currentTenant = await TenantsService.getUserTenant();
					setTenant(currentTenant);
				}
			}
		},
		[user, tenant]
	);

	const onLoad = useCallback(() => updateUserTenant(), [updateUserTenant]);

	const hasAdminPermissions = useCallback(
		(): boolean => user && user.role?.type === ROLE_TYPE_ENTITY.TENANT_ADMIN,
		[user]
	);

	const initUsageEvents = useCallback(async () => {
		const sendEventToggle =
			TogglesService.getToggle(DevelopmentToggles.SEND_USAGE_ANALYTICS) !== false ||
			EnvironmentHelper.isDev();
		UsageEventsService.init({
			userId: user?.id,
			tenantId: tenant?.id,
			productName: PRODUCT_TYPES.SCANNER,
			// eslint-disable-next-line no-undef
			productVersion: UI_VERSION,
			isProductionEnv: EnvironmentHelper.isProduction(),
			/* TODO: sendEventToggle temporary. change real/parsed BE value when
			ready or remove this param and parse it in the service */
			sendEventToggle,
			serverApi: ServerApi
		});
		if (user?.id && tenant?.id) {
			UsageEventsService.sendEvent(USAGE_METRICS.USER_ACTIVITY, {
				activity: USAGE_METRICS.USER_ACTIVITY
			});
		}
	}, [user?.id, tenant?.id]);

	const isReadOnlyUser = useCallback(
		(): boolean => !user || (user && user.role?.type === ROLE_TYPE_ENTITY.READ_ONLY),
		[user]
	);

	useEffect(() => {
		onLoad();
	}, [onLoad]);

	useEffect(() => {
		initUsageEvents();
	}, [initUsageEvents]);

	const value = useMemo(() => {
		return {
			user,
			tenant,
			hasAdminPermissions,
			updateUserTenant,
			isReadOnlyUser,
			getProductIdByType
		};
	}, [user, tenant, hasAdminPermissions, updateUserTenant, isReadOnlyUser, getProductIdByType]);

	return <UserTenantContext.Provider value={value}>{children}</UserTenantContext.Provider>;
};

const useUserTenant = (): IUserTenant => useContext(UserTenantContext);

export { UserTenantProvider, useUserTenant };
