import _ from 'lodash';

import { EVINCED_SEVERITIES_IDS } from '@evinced-private/ui-common';

import {
	SEVERITIES_CHART_TYPE,
	SeverityTrendsDatum
} from '../components/charts/severity-trends/SeverityTrendTypes';
import { ITrend } from '../interfaces/Trend';

import DateFormatHelper from './DateFormatHelper';
import MathHelper from './MathHelper';

const calculatePercentage = (severityCount: number, total: number): number => {
	if (!severityCount) {
		return 0;
	}
	return MathHelper.calcPercentage(severityCount, total, false);
};

const calcAvgPerPage = (severityCount: number, pagesCount: number): number => {
	if (!severityCount || !pagesCount) {
		return 0;
	}
	return severityCount / pagesCount;
};

// We use the EVINCED_SEVERITIES enum here to get the colors and patterns for the severities
// the colorHelper utility uses them to match severity to color
const createChartDatum = (
	scanId: string,
	total: number,
	date: Date,
	critical: number,
	serious: number,
	bestPractices: number,
	needsReview: number,
	minor: number,
	moderate: number
): SeverityTrendsDatum => {
	return {
		scanId,
		total,
		date: DateFormatHelper.formatDate(date, 'MMM D, YYYY, h:mm a'),
		...(critical && { [EVINCED_SEVERITIES_IDS.CRITICAL]: critical }),
		...(serious && { [EVINCED_SEVERITIES_IDS.SERIOUS]: serious }),
		...(bestPractices && { [EVINCED_SEVERITIES_IDS.BEST_PRACTICE]: bestPractices }),
		...(needsReview && { [EVINCED_SEVERITIES_IDS.NEEDS_REVIEW]: needsReview }),
		...(minor && { [EVINCED_SEVERITIES_IDS.MINOR]: minor }),
		...(moderate && { [EVINCED_SEVERITIES_IDS.MODERATE]: moderate })
	};
};

const keepOneDecimal = (x): number => {
	// keep 1 decimal point
	// based on https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
	return Math.round(x * 10) / 10;
};

const getPercentageChartData = (scanTrendsData: ITrend[]): SeverityTrendsDatum[] => {
	return scanTrendsData.map((scanData): SeverityTrendsDatum => {
		const { critical, serious, bestPractice, needsReview, minor, moderate } = scanData.issues;
		const { totalIssues, id } = scanData;
		const severitiesValues = [
			keepOneDecimal(calculatePercentage(critical, totalIssues)),
			keepOneDecimal(calculatePercentage(serious, totalIssues)),
			keepOneDecimal(calculatePercentage(bestPractice, totalIssues)),
			keepOneDecimal(calculatePercentage(needsReview, totalIssues)),
			keepOneDecimal(calculatePercentage(minor, totalIssues)),
			keepOneDecimal(calculatePercentage(moderate, totalIssues))
		];
		const sum = _.sum(severitiesValues);
		const remainder = keepOneDecimal(sum - 100);
		// remainder can be -100 in case all severity values = 0
		if (remainder !== 0 && Math.abs(remainder) !== 100) {
			const maxValue = _.max(severitiesValues);
			const maxValueIndex = severitiesValues.lastIndexOf(maxValue);
			// subtract remainder from biggest severity value
			severitiesValues[maxValueIndex] = keepOneDecimal(severitiesValues[maxValueIndex] - remainder);
		}
		const [
			criticalValue,
			seriousValue,
			bestPracticeValue,
			needsReviewValue,
			minorValue,
			moderateValue
		] = severitiesValues;

		return createChartDatum(
			id,
			100,
			scanData.createdTime,
			criticalValue,
			seriousValue,
			bestPracticeValue,
			needsReviewValue,
			minorValue,
			moderateValue
		);
	});
};

const getAvgPerPageCharttData = (scanTrendsData: ITrend[]): SeverityTrendsDatum[] => {
	return scanTrendsData.map((scanData): SeverityTrendsDatum => {
		const { critical, serious, bestPractice, needsReview, minor, moderate } = scanData.issues;
		const { totalPages, id } = scanData;
		const criticalAvg = calcAvgPerPage(critical, totalPages);
		const seriousAvg = calcAvgPerPage(serious, totalPages);
		const bestPracticesAvg = calcAvgPerPage(bestPractice, totalPages);
		const needsReviewAvg = calcAvgPerPage(needsReview, totalPages);
		const minorAvg = calcAvgPerPage(minor, totalPages);
		const moderateAvg = calcAvgPerPage(moderate, totalPages);
		const total =
			criticalAvg + seriousAvg + bestPracticesAvg + needsReviewAvg + minorAvg + moderateAvg;
		return createChartDatum(
			id,
			total,
			scanData.createdTime,
			criticalAvg,
			seriousAvg,
			bestPracticesAvg,
			needsReviewAvg,
			minorAvg,
			moderateAvg
		);
	});
};

const getTotalIssuesChartData = (scanTrendsData: ITrend[]): SeverityTrendsDatum[] => {
	return scanTrendsData.map((scanData): SeverityTrendsDatum => {
		const { critical, serious, bestPractice, needsReview, minor, moderate } = scanData.issues;
		const { totalIssues, id } = scanData;
		return createChartDatum(
			id,
			totalIssues,
			scanData.createdTime,
			critical,
			serious,
			bestPractice,
			needsReview,
			minor,
			moderate
		);
	});
};

const getChartDataByType = (
	scanTrendsData: ITrend[],
	chartType: SEVERITIES_CHART_TYPE
): SeverityTrendsDatum[] => {
	switch (chartType) {
		case SEVERITIES_CHART_TYPE.PERCENTAGE: {
			return getPercentageChartData(scanTrendsData);
		}
		case SEVERITIES_CHART_TYPE.AVERAGE_PER_PAGE: {
			return getAvgPerPageCharttData(scanTrendsData);
		}
		// TOTAL_ISSUES chart
		default: {
			return getTotalIssuesChartData(scanTrendsData);
		}
	}
};

export default { getChartDataByType, keepOneDecimal };
