import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { isNumber } from 'lodash';

import {
	BUTTON_TYPES,
	DropdownOptionType,
	EvButton,
	EvConfirm,
	EvDropdownMenu,
	EvIcon,
	EvLift,
	EvLinkTypes,
	EvSpinner,
	EvTableColumn,
	SORT_ORDER,
	TOAST_STATUS
} from '@evinced-private/ui-common';

import { SITE_SCANNER_APP_ID } from '../../consts/dom-consts';
import ApiErrorHelper from '../../helpers/ApiErrorHelper';
import { formatShortMonthWithoutTimeDatetime } from '../../helpers/DateFormatHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import ScanCrawlStateHelper from '../../helpers/ScanCrawlStateHelper';
import ScoreHelper from '../../helpers/ScoreHelper';
import useInterval from '../../hooks/useInterval';
import { IProperty } from '../../interfaces/Property';
import { IScan } from '../../interfaces/Scan';
import { IServerError } from '../../interfaces/ServerError';
import { IUrlSet } from '../../interfaces/UrlSet';
import { useDashboard } from '../../providers/dashboard/DashboardProvider';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import { useTablesStore } from '../../providers/tablesProvider/TablesProvider';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import CollectionsService from '../../services/CollectionsService';
import logger from '../../services/Logger';
import propertiesService from '../../services/PropertiesService';
import { IPropertyCollection } from '../../types/CollectionType';
import URL_SET_SOURCES from '../../types/UrlSetSources';
import EvLinkLocal from '../common/ev-link-local/EvLinkLocal';
import renderNumberCellFormatter from '../common/ev-table/cell-formatters/numberCellFormatter';
import SiteScannerTable from '../common/site-scanner-table/SiteScannerTable';
import EllipsisIcon from '../icons/EllipsisIcon.svg';
import JobsStatusAction from '../jobs-status-row-action/JobsStatusAction';
import NoDataInCollection from '../no-data-in-collection/NoDataInCollection';
import StartCrawlPopup from '../start-crawl-popup/StartCrawlPopup';
import StartScanPopup from '../start-scan-popup/StartScanPopup';
import StatusIcon from '../status-icon/StatusIcon';

import './PropertiesTable.scss';

const screenId = 'properties-table';
const REFRESH_TABLE_INTERVAL = 20000;
const REMOVE_FROM_TEXT = 'Remove from';
const ADD_TO_TEXT = 'Add to';
const PROPERTY_REMOVED_TEXT = 'Property removed from collection';
const PROPERTY_ADDED_TEXT = 'Property added to collection';

type PropertyRowData = {
	name: string;
	createdTime: string;
	id: string;
	index: number;
	lastScan: IScan;
	lastUrlSet: IUrlSet;
	searchStr: string;
	collections: IPropertyCollection[];
};

interface IPropertiesTableProps {
	searchFilter: string;
	propertyCollections: IPropertyCollection[];
	showOnlyPropertiesInMyCollection: boolean;
	collectionName: string;
	collectionIds: string[];
}

const PropertiesTableV2: FC<IPropertiesTableProps> = ({
	searchFilter,
	propertyCollections,
	showOnlyPropertiesInMyCollection,
	collectionName,
	collectionIds
}) => {
	const [isLoading, setLoading] = useState(true);
	const [tableData, setTableData] = useState<PropertyRowData[]>([]);
	const [allProperties, setAllProperties] = useState<IProperty[]>([]);
	const [runGetPropertiesInterval, setRunGetPropertiesInterval] = useState(true);
	const notificationsContext = useNotifications();
	const tablesStore = useTablesStore();
	const { refreshDashboardData } = useDashboard();
	const { isReadOnlyUser } = useUserTenant();
	const changedFilter = useRef(true);

	useEffect(() => {
		changedFilter.current = true;
	}, [searchFilter]);

	useEffect(() => {
		let data: PropertyRowData[] = allProperties.map((property, index) => {
			return {
				name: property.name,
				createdTime: property.createdTime,
				id: property.id,
				index,
				lastScan: property.lastScan,
				lastUrlSet: property.lastUrlSet,
				searchStr: `${property.name} ${formatShortMonthWithoutTimeDatetime(
					property.createdTime
				)} ${formatShortMonthWithoutTimeDatetime(property.lastScan)} ${
					property.lastUrlSet?.totalUrlsCount
				}`,
				scoreResult: {
					score: property?.scoreResult?.score,
					lift: property?.scoreResult?.lift,
					liftPercentage: property?.scoreResult?.liftPercentage
				},
				collections: property?.collections
			};
		});
		if (data.length) {
			data = data.filter((item) =>
				item.searchStr.toLowerCase().includes(searchFilter.toLowerCase())
			);
			if (changedFilter?.current) {
				changedFilter.current = false;
				const { sizePerPage } = tablesStore.getPagination(screenId);
				tablesStore.updatePagination(screenId, { page: 1, sizePerPage });
			}

			if (showOnlyPropertiesInMyCollection) {
				data = data.filter((item) => {
					return item.collections?.some(
						(collection: IPropertyCollection) => collection.id === collectionIds[0]
					);
				});
			}
		}

		setTableData(data);
	}, [
		searchFilter,
		allProperties,
		tablesStore,
		propertyCollections,
		showOnlyPropertiesInMyCollection,
		collectionIds
	]);

	const setPropertiesToState = useCallback((result = []): void => {
		setAllProperties([...result]);
		setLoading(false);
	}, []);

	const isNoDataInSelectedCollection: boolean = useMemo(() => {
		return (
			!searchFilter &&
			showOnlyPropertiesInMyCollection &&
			propertyCollections?.length &&
			!tableData.length
		);
	}, [searchFilter, showOnlyPropertiesInMyCollection, tableData, propertyCollections]);

	const getAllProperties = useCallback(
		(isOnMount?: boolean): Promise<void> => {
			setLoading(!isOnMount);
			return propertiesService
				.getAllProperties(collectionIds, showOnlyPropertiesInMyCollection)
				.then(setPropertiesToState)
				.catch((err: IServerError) => {
					setRunGetPropertiesInterval(false);
					const errMessage = ApiErrorHelper.getErrorMessage(err);
					const msg = `An error occurred while trying to load properties list.\n Message: ${errMessage}`;
					logger.error(msg, err);
					if (isOnMount) {
						notificationsContext.alert({
							title: 'Error loading properties list',
							serverError: err
						});
					}
					setLoading(false);
				});
		},
		[notificationsContext, setPropertiesToState, collectionIds, showOnlyPropertiesInMyCollection]
	);

	useEffect(() => {
		getAllProperties();
	}, [getAllProperties]);

	useInterval(
		() => getAllProperties(true),
		runGetPropertiesInterval ? REFRESH_TABLE_INTERVAL : null
	);

	const onDeleteProperty = (idToDelete, closeModal): void => {
		setLoading(true);
		propertiesService
			.deleteProperty(idToDelete)
			.then(() => {
				getAllProperties().then(closeModal);
			})
			.catch((err: IServerError) => {
				const msg = `An error while trying to delete property ${idToDelete}.`;
				logger.error(msg, err);
				closeModal();
				notificationsContext.alert({
					errorMessage: msg,
					serverError: err,
					onOK: () => getAllProperties()
				});
			});
	};

	const renderScanPopup = (property, accessibilityProps, close): JSX.Element => {
		return (
			<StartScanPopup
				triggerButtonProps={{
					type: BUTTON_TYPES.ICON,
					title: 'Scan Now',
					children: 'Scan',
					accessibilityProps
				}}
				onScanCreated={() => {
					logger.info(`Scan created for property ${property.id}`);
					getAllProperties();
					close();
				}}
				propertyId={property.id}
				urlSet={property.lastUrlSet}
			/>
		);
	};

	const renderCollectionAction = (
		property: IProperty,
		accessibilityProps: Record<string, string>,
		onDropdownOpenStateChange: (isOpen: boolean) => void
	): JSX.Element => {
		const { id, collections } = property;
		// todo itay - change logic in phase 2 of collections
		const selectedCollectionId: string = propertyCollections?.[0]?.id;
		const isPropertyAlreadyInCollection = collections?.some(
			(collection: IPropertyCollection) => collection.id === selectedCollectionId
		);
		const buttonText = `${
			isPropertyAlreadyInCollection ? REMOVE_FROM_TEXT : ADD_TO_TEXT
		} "${collectionName}"`;

		const clickAndNotify = async (): Promise<void> => {
			const action = isPropertyAlreadyInCollection
				? CollectionsService.deletePropertyFromCollectionById
				: CollectionsService.addPropertyToCollectionById;

			try {
				await action(selectedCollectionId, id);
				onDropdownOpenStateChange(false);
				notifyAction(true);
				refreshDashboardData();
				await getAllProperties();
			} catch (error) {
				notifyAction(false, error);
			}
		};

		const notifyAction = (isSuccess: boolean, error?: IServerError): void => {
			const announcement = isPropertyAlreadyInCollection
				? PROPERTY_REMOVED_TEXT
				: PROPERTY_ADDED_TEXT;
			if (isSuccess) {
				notificationsContext.toast({
					show: true,
					status: TOAST_STATUS.SUCCESS,
					announcement
				});
			} else {
				notificationsContext.alert({
					errorMessage: `Error occurred while trying to ${buttonText}`,
					serverError: error
				});
			}
		};

		return (
			<EvButton
				type={BUTTON_TYPES.ICON}
				title={buttonText}
				accessibilityProps={accessibilityProps}
				onClick={clickAndNotify}
			>
				{buttonText}
			</EvButton>
		);
	};

	const renderCrawlPopup = (property, accessibilityProps, close): JSX.Element => {
		if (property.lastUrlSet && property.lastUrlSet.source === URL_SET_SOURCES.UPLOAD) {
			return (
				<EvButton
					type={BUTTON_TYPES.ICON}
					customTooltip={{ renderTooltip: true, place: 'left' }}
					disabled
					title="Crawl is disabled in List Mode. The property is configured with a predefined list of URLs."
					accessibilityProps={accessibilityProps}
				>
					Crawl
				</EvButton>
			);
		}

		return (
			<StartCrawlPopup
				triggerButtonProps={{
					type: BUTTON_TYPES.ICON,
					title: 'Crawl Now',
					children: 'Crawl',
					accessibilityProps
				}}
				onCrawlCreated={() => {
					getAllProperties();
					close();
				}}
				propertyId={property.id}
			/>
		);
	};

	const renderConfirmDeletePropertyPopup = (row, accessibilityProps): JSX.Element => {
		return (
			<EvConfirm
				appElement={SITE_SCANNER_APP_ID}
				triggerButtonProps={{
					type: BUTTON_TYPES.ICON,
					title: 'Archive property',
					children: 'Archive property',
					accessibilityProps
				}}
				approveButtonText="Archive"
				title="Confirm archive property"
				promptMessage={`Are you sure you want to archive ${row.name} ?`}
				onConfirm={(closeModal) => {
					onDeleteProperty(row.id, closeModal);
				}}
			/>
		);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const renderActionsCell = (cell, property): any => {
		const isViewScanResultsEnabled =
			property.lastScan && ScanCrawlStateHelper.isScanResultsReady(property.lastScan.state);

		return (
			<div className="actions-cell">
				<EvDropdownMenu
					id={`menu-${property.id}`}
					triggerButtonProps={{
						type: BUTTON_TYPES.ICON,
						ariaHaspopup: 'menu',
						title: `${property.name} actions`,
						children: (
							<div className="menu-trigger-icon">
								<EvIcon icon={EllipsisIcon} small />
							</div>
						)
					}}
					options={[
						!isReadOnlyUser() && {
							id: 'property-settings',
							url: RoutesHelper.getPropertySettingsPagePath(property.id),
							title: 'Property settings',
							type: DropdownOptionType.LINK
						},
						{
							id: 'collection-action',
							renderOption: (accessibilityProps, onDropdownOpenStateChange) => {
								return renderCollectionAction(
									property,
									accessibilityProps,
									onDropdownOpenStateChange
								);
							},
							type: DropdownOptionType.CUSTOM
						},
						{
							id: 'view-scan-results',
							url:
								isViewScanResultsEnabled &&
								RoutesHelper.getScanResultsPagePath(property.id, property.lastScan.id),
							title: 'Scan results',
							disabled: !isViewScanResultsEnabled,
							type: DropdownOptionType.LINK
						},
						!isReadOnlyUser() && {
							id: 'crawl-property',
							renderOption: (accessibilityProps, close) => {
								return renderCrawlPopup(property, accessibilityProps, close);
							},
							type: DropdownOptionType.CUSTOM
						},
						!isReadOnlyUser() && {
							id: 'scan-property',
							renderOption: (accessibilityProps, close) => {
								return renderScanPopup(property, accessibilityProps, close);
							},
							type: DropdownOptionType.CUSTOM
						},
						!isReadOnlyUser() && {
							id: 'delete-property',
							renderOption: (accessibilityProps) => {
								return renderConfirmDeletePropertyPopup(property, accessibilityProps);
							},
							type: DropdownOptionType.CUSTOM
						}
					].filter((e) => e)}
				/>
			</div>
		);
	};

	const renderDateStatusCol = (cell, property: IProperty, type): JSX.Element | string => {
		if (!cell || !cell.state) {
			return 'N/A';
		}
		const isCanceled = ScanCrawlStateHelper.isCanceled(cell.state);
		if (isCanceled) {
			return 'N/A';
		}

		const isScanStatus = type === 'lastScan';
		const isScanResultsReady = ScanCrawlStateHelper.isScanResultsReady(cell.state);
		const isScanReady = isScanStatus && isScanResultsReady;

		return (
			<div className={`date-status-cell ${isScanStatus ? 'scan-status' : ''}`}>
				{!isScanReady && <StatusIcon scanState={cell.state} />}
				<div className="time">{formatShortMonthWithoutTimeDatetime(cell.createdTime)}</div>
				{isScanReady && (
					<Link
						className="view-scan-link"
						aria-label={`navigate to ${property.name} last scan results`}
						to={RoutesHelper.getScanResultsPagePath(property.id, cell.id)}
					>
						View scan
					</Link>
				)}
			</div>
		);
	};

	const renderLiftCell = (cell, property: IProperty): JSX.Element => {
		const { scoreResult } = property;
		const liftPercentage = scoreResult?.liftPercentage;
		const roundedValue = (Math.ceil(liftPercentage * 10) / 10).toFixed(1);
		let roundedValueNumber = Number(roundedValue);
		if (
			!liftPercentage ||
			!isNumber(liftPercentage) ||
			roundedValueNumber === 0 ||
			!roundedValueNumber
		) {
			roundedValueNumber = null;
		}
		const isPositiveChange: boolean = roundedValueNumber > 0;
		return <EvLift liftNumber={liftPercentage} small success={isPositiveChange} />;
	};

	const getColumns = (): EvTableColumn[] => {
		const columns = [
			{
				dataField: 'name',
				text: 'Name',
				style: { maxWidth: '240px', width: '240px' },
				headerStyle: { maxWidth: '240px', width: '240px' },
				headerClasses: 'filter-cell',
				// eslint-disable-next-line react/no-unstable-nested-components
				formatter: (cell, row) => {
					return (
						<EvLinkLocal
							id={cell}
							type={EvLinkTypes.DARK}
							url={RoutesHelper.getPropertyPagePath(row.id)}
							linkText={cell}
							pageId={screenId}
						/>
					);
				},
				sort: true
			},
			{
				dataField: 'scoreResult.score',
				text: 'Score',
				style: { maxWidth: '100px', width: '100px' },
				headerStyle: { maxWidth: '100px', width: '100px' },
				sortValue: (cell) => (cell && isNumber(cell) ? ScoreHelper.roundScore(cell) : 0),
				// eslint-disable-next-line react/no-unstable-nested-components
				formatter: (cell: IScan, property: IProperty) => {
					if (property?.scoreResult?.score === undefined) {
						return <div>N/A</div>;
					}
					const formattedScore = ScoreHelper.roundScore(property?.scoreResult?.score || 0);
					return renderNumberCellFormatter(formattedScore);
				},

				sort: true
			},
			{
				dataField: 'scoreResult.liftPercentage',
				text: 'Score Change',
				style: { maxWidth: '150px', width: '150px' },
				headerStyle: { maxWidth: '150px', width: '150px' },
				sortValue: (cell) => (cell && isNumber(cell) ? cell : 0),
				sort: true,
				formatter: (cell: IScan, property: IProperty) => {
					return renderLiftCell(cell, property);
				}
			},
			{
				dataField: 'lastUrlSet.totalUrlsCount',
				text: 'Pages',
				style: { maxWidth: '150px', width: '150px' },
				headerStyle: { maxWidth: '150px', width: '150px' },
				sort: true,
				formatter: renderNumberCellFormatter
			},
			{
				dataField: 'lastScan.issuesCount',
				text: 'Issues',
				style: { maxWidth: '150px', width: '150px' },
				headerStyle: { maxWidth: '150px', width: '150px' },
				sort: true,
				formatter: renderNumberCellFormatter
			},
			{
				dataField: 'lastUrlSet',
				text: 'Last crawl',
				style: { maxWidth: '170px', width: '170px' },
				headerStyle: { maxWidth: '170px', width: '170px' },
				sortValue: (cell: IUrlSet) => (cell && cell.createdTime) || '',
				sort: true,
				formatter: (cell: IUrlSet, property: IProperty) => {
					return renderDateStatusCol(cell, property, 'lastUrlSet');
				}
			},
			{
				dataField: 'lastScan',
				text: 'Last scan',
				style: { maxWidth: '170px', width: '170px' },
				headerStyle: { maxWidth: '170px', width: '170px' },
				sortValue: (cell) => (cell && cell.createdTime) || '',
				sort: true,
				formatter: (cell: IScan, property: IProperty) => {
					return renderDateStatusCol(cell, property, 'lastUrlSet');
				}
			},
			{
				dataField: 'actions',
				text: 'Actions',
				style: { maxWidth: '80px', width: '80px' },
				headerStyle: { maxWidth: '80px', width: '80px' },
				classes: 'actions-cell-td',
				formatter: renderActionsCell
			}
		];

		return columns;
	};

	if (isLoading) {
		return <EvSpinner />;
	}
	if (isNoDataInSelectedCollection) {
		return <NoDataInCollection />;
	}
	return (
		<SiteScannerTable
			tableId={screenId}
			totalCount={tableData.length}
			tableData={tableData}
			className="properties-table"
			columns={getColumns()}
			topRowActions={<JobsStatusAction properties={allProperties} />}
			alignActionsRight={false}
			isTableLoading={isLoading}
			options={{
				title: 'Properties',
				pagination: true,
				defaultSorted: [
					{
						dataField: 'lastScan',
						order: SORT_ORDER.DESC
					}
				],
				dataType: 'properties',
				caption: 'Properties table'
			}}
		/>
	);
};

export default PropertiesTableV2;
