import psl from 'psl';

import Logger from '../services/Logger';

const removeAllTrailingSlashes = (url: string): string => {
	return url.replace(/\/+$/, '/');
};

const formatURL = (url: string): string => {
	let formatted = url.trim();
	formatted = removeAllTrailingSlashes(formatted);
	formatted = formatted.trim(); // second trim in case there was a space before the trailing slash
	return formatted;
};

const getBaseURL = (): string => {
	const { protocol, hostname, port } = window.location;
	const portPart = port ? `:${port}` : '';
	return `${protocol}//${hostname}${portPart}`;
};
const normalizeUrlFromUserInput = (url: string): string => {
	let res = formatURL(url);
	if (!res.startsWith('https://') && !res.startsWith('http://')) {
		res = `https://${res}`;
	}
	return res;
};

const getDomain = (url: string): string => {
	// taking care of urls that have no http/https at the beginning
	const normalizedUrl = normalizeUrlFromUserInput(url);
	return new URL(normalizedUrl).hostname;
};

const isIpAddress = (hostname: string): boolean => {
	// IPv4 - XXX.XXX.XXX.XXX
	const IPv4Regex = '^(\\d){1,3}\\.(\\d){1,3}\\.(\\d){1,3}\\.(\\d){1,3}$';
	// IPv6 - XXX.XXX.XXX.XXX.XXX.XXX
	const IPv6Regex = '^(\\d){1,3}\\.(\\d){1,3}\\.(\\d){1,3}\\.(\\d){1,3}\\.(\\d){1,3}\\.(\\d){1,3}$';
	if (hostname.match(IPv4Regex) || hostname.match(IPv6Regex)) {
		return true;
	}
	return false;
};

const getDomainMainPartFromEmail = (email: string): string => {
	const splitEmail = email?.split('@');
	if (!email || splitEmail.length !== 2) {
		Logger.error('Email parsing failed, check if email is valid', email);
		return null;
	}

	// in the psl library, sld represents the main part of a domain,
	// for example: gmail in www.gmail.com
	return psl.parse(splitEmail[1])?.sld;
};

const getDomainWithoutSubdomainFromEmail = (email: string): string => {
	const splitEmail = email?.split('@');
	if (!email || splitEmail.length !== 2) {
		Logger.error('Email parsing failed, check if email is valid', email);
		return null;
	}

	// in the psl library, domain represents the host without the subdomain,
	// for example: gmail.com in www.gmail.com
	return psl.parse(splitEmail[1])?.domain;
};

const hasHTTPSchema = (url: string): boolean => {
	return url.startsWith('http://') || url.startsWith('https://');
};

export default {
	formatURL,
	getBaseURL,
	normalizeUrlFromUserInput,
	getDomain,
	isIpAddress,
	getDomainMainPartFromEmail,
	getDomainWithoutSubdomainFromEmail,
	hasHTTPSchema
};
