import './Switch.scss';

import PropTypes from 'prop-types';
import React from 'react';

const Switch = (props) => {
	const { isOn } = props;
	return (
		<div
			role="none"
			className="custom-toggle"
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<input
				type="checkbox"
				id={props.dataId}
				checked={isOn}
				onChange={(e) => props.onChange(e.target.checked)}
			/>
			<div className="switch-view" role="none" />
		</div>
	);
};

Switch.propTypes = {
	dataId: PropTypes.string.isRequired,
	isOn: PropTypes.bool,
	onChange: PropTypes.func.isRequired
};
export default Switch;
