import { action, makeObservable, observable, ObservableMap } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { IFilterOptions } from '../components/data-filter/DataFilterHelper';

export default class CommonFiltersStore {
	constructor() {
		makeObservable(this, {
			commonFiltersStates: observable,
			updateSelectedFilters: action
		});

		makePersistable(this, {
			name: 'CommonFiltersStore',
			properties: ['commonFiltersStates'],
			storage: window.localStorage
		});
	}

	commonFiltersStates: ObservableMap<string, IFilterOptions> = new ObservableMap<
		string,
		IFilterOptions
	>();

	updateSelectedFilters(filterId: string, filterValues: IFilterOptions): void {
		this.commonFiltersStates.set(filterId, { ...filterValues });
	}

	getFilterValue(filterId: string): IFilterOptions {
		return this.commonFiltersStates.get(filterId);
	}
}
