import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { EvButton, EvCard, EvRadioGroup, EvSection, EvSpinner } from '@evinced-private/ui-common';

import FullPageError from '../../components/full-page-error/FullPageError';
import ApiErrorHelper from '../../helpers/ApiErrorHelper';
import InvitationTokenHelper from '../../helpers/InvitationTokenHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import { IUserInvitation } from '../../interfaces/UserInvitation';
import Logger from '../../services/Logger';
import TenantsService from '../../services/TenantsService';
import userInvitationsService from '../../services/UserInvitationService';

import './InvitationActivationPage.scss';

const InvitationActivationPage: FC = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [activationInProcess, setActivationInProcess] = useState<boolean>(false);
	const [error, setError] = useState(null);
	const [pendingInvitations, setPendingInvitations] = useState<IUserInvitation[]>(null);
	const [selectedInvitationToken, setSelectedInvitationToken] = useState(null);
	const history = useHistory();

	const acceptInvitation = async (): Promise<void> => {
		setActivationInProcess(true);
		try {
			await userInvitationsService.acceptInvitation(selectedInvitationToken);
			history.push(RoutesHelper.getHomepagePath());
		} catch (e) {
			const errorMsg = ApiErrorHelper.getErrorMessage(e);
			Logger.error('Error when validating token', errorMsg);
			setError(errorMsg);
		} finally {
			setActivationInProcess(false);
		}
	};

	const getInvitations = useCallback(async (): Promise<void> => {
		try {
			const userTenant = await TenantsService.getUserTenant();
			const pendingInvitations = await userInvitationsService.getAllUserPendingInvitations();
			setPendingInvitations(pendingInvitations);

			if (pendingInvitations.length === 0 || !!userTenant) {
				history.push(RoutesHelper.getHomepagePath());
				return;
			}
			// set the first option selected by default
			setSelectedInvitationToken(pendingInvitations[0].token);

			// try to set the token from URL as the selected one
			// (if it exists and appears on the invitations list)
			const invitaitonTokenFromUrl = InvitationTokenHelper.getInvitationTokenFromUrl();
			if (
				invitaitonTokenFromUrl &&
				pendingInvitations.some((i) => i.token === invitaitonTokenFromUrl)
			) {
				setSelectedInvitationToken(invitaitonTokenFromUrl);
			}
		} catch (e) {
			const errorMsg = ApiErrorHelper.getErrorMessage(e);
			setError(errorMsg);
		} finally {
			setLoading(false);
		}
	}, [history]);

	useEffect(() => {
		getInvitations();
	}, [getInvitations]);

	if (loading) {
		return <EvSpinner />;
	}

	if (error) {
		return <FullPageError title="Could not activate your account" />;
	}

	const getActionButtons = (): JSX.Element => {
		return (
			<div className="invitation-activation-footer">
				<EvButton
					title="connect account"
					onClick={acceptInvitation}
					className="connect-account-btn"
					loading={activationInProcess}
				>
					Connect Account
				</EvButton>
			</div>
		);
	};

	const renderInviterDetails = (invitation: IUserInvitation): JSX.Element => {
		return (
			<div className="inviter-details">
				<div className="tenant-name">{invitation.tenantName}</div>
				<div className="inviter-email">(Invited by {invitation.ownerEmail})</div>
			</div>
		);
	};
	const renderCardContent = (): JSX.Element => {
		if (pendingInvitations.length === 1) {
			return (
				<div className="single-invitation-mode">
					<div className="upper-text">Your user will be connected to the following account:</div>
					<div className="inviter-info">{renderInviterDetails(pendingInvitations[0])}</div>
				</div>
			);
		}
		const radioOptions = pendingInvitations.map((i) => ({
			label: i.tenantName,
			isChecked: selectedInvitationToken === i.token,
			onClick: () => setSelectedInvitationToken(i.token),
			component: renderInviterDetails(i)
		}));
		return (
			<div className="multiple-invitations-mode">
				<div className="inviter-info">
					<EvRadioGroup
						title="Please select to which account your user will be connected"
						options={radioOptions}
						ariaLabel="User connection account"
					/>
				</div>
			</div>
		);
	};
	return (
		<div className="invitation-activation-page">
			<EvCard
				title="Welcome to Evinced!"
				footer={getActionButtons()}
				className="invitation-activation-card"
				headingLevel={1}
			>
				<EvSection ariaLabel="main-section" className="invitation-activation-info">
					{renderCardContent()}
				</EvSection>
			</EvCard>
		</div>
	);
};

export default InvitationActivationPage;
