import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';

import {
	BarDatum,
	ChartsHelper,
	EvBarChart,
	EvChartBottomTick,
	EvChartTooltip,
	EvincedColors,
	EvSpinner,
	FormatHelper
} from '@evinced-private/ui-common';

import ChartsRenderingHelper from '../../../helpers/charts/ChartsRenderingHelper';
import SeverityTrendsHelper from '../../../helpers/SeverityTrendsHelper';
import { ITrend } from '../../../interfaces/Trend';
import { SEVERITIES_CHART_TYPE, SeverityTrendsDatum } from '../severity-trends/SeverityTrendTypes';

import './SeverityTrendChart.scss';

interface ISeverityTrendChart {
	scanTrendsData: ITrend[];
	chartType: SEVERITIES_CHART_TYPE;
	onBarItemClick: (scanId: string, severity: string) => void;
}

const patterns = EvincedColors.severitiesPatterns;

const keys = patterns.map((l) => l.id);

const SeverityTrendChart: FC<ISeverityTrendChart> = ({
	scanTrendsData,
	chartType,
	onBarItemClick
}) => {
	const [chartData, setChartData] = useState<SeverityTrendsDatum[]>(null);

	useEffect(() => {
		setChartData(SeverityTrendsHelper.getChartDataByType(scanTrendsData, chartType));
	}, [chartType, scanTrendsData]);

	const getGridYValues = (barData: BarDatum[], chartType: SEVERITIES_CHART_TYPE): number[] => {
		if (chartType === SEVERITIES_CHART_TYPE.PERCENTAGE) {
			return [0, 20, 40, 60, 80, 100];
		}
		const field = 'total';
		const maxDatum = _.maxBy(barData, field);
		const maxValue = Number(maxDatum?.[field] || 0);
		return ChartsHelper.getYAxisValues(maxValue);
	};

	const yAxisValues = useMemo(() => getGridYValues(chartData, chartType), [chartData, chartType]);
	const renderTick = useCallback(
		(axisPosition) => ChartsRenderingHelper.renderTickInKFormat(yAxisValues, axisPosition),
		[yAxisValues]
	);

	const getTooltipValue = (value: number): string | number => {
		if (chartType === SEVERITIES_CHART_TYPE.TOTAL_ISSUES) {
			return value;
		}
		if (chartType === SEVERITIES_CHART_TYPE.AVERAGE_PER_PAGE) {
			return SeverityTrendsHelper.keepOneDecimal(value);
		}
		// PERCENTAGE type
		return `${value}%`;
	};

	const tooltipRenderer = (d): JSX.Element => {
		return <EvChartTooltip label={d.id} value={getTooltipValue(d.value)} />;
	};

	const onBarClick = useCallback(
		(barData): void => {
			const activeBar = patterns.find((pattern) => pattern.id === barData.id);
			onBarItemClick(barData.data.scanId, activeBar.label);
		},
		[onBarItemClick]
	);

	if (!chartData) {
		return <EvSpinner />;
	}

	return (
		<EvBarChart
			title="Breakdown by Severity"
			className="severity-trend-chart"
			data={chartData}
			keys={keys}
			indexBy="date"
			patterns={patterns}
			height={400}
			onBarClick={onBarClick}
			isComplex
			withFiltering
			isFocusable
			barDesignProps={{
				margin: { top: 40, right: 0, bottom: 50, left: 70 },
				enableLabel: false,
				innerPadding: 2,
				padding: 0.6,
				tooltip: tooltipRenderer,
				animate: false,
				axisTop: null,
				axisBottom: {
					tickSize: 0,
					tickPadding: 25,
					renderTick: (tick) => <EvChartBottomTick tick={tick} />
				},
				axisLeft: {
					tickSize: 0,
					tickPadding: 10,
					tickRotation: 0,
					legend: 'Issues',
					legendOffset: -65,
					legendPosition: 'middle',
					// add percentage for percentage type
					...(chartType === SEVERITIES_CHART_TYPE.PERCENTAGE
						? { format: FormatHelper.addPercentSign }
						: {}),
					// add xxK to other types
					...(chartType !== SEVERITIES_CHART_TYPE.PERCENTAGE
						? { renderTick: renderTick('axisLeft') }
						: {})
				}
			}}
		/>
	);
};

export default SeverityTrendChart;
