import React, { FC, useEffect, useState } from 'react';

import { EvLoader } from '@evinced-private/ui-common';

import { useDashboard } from '../../../../providers/dashboard/DashboardProvider';
import { TQuickWins } from '../../DashboardTypes';
import Box from '../box/Box';
import InfoLine from '../info-line/InfoLine';

import QuickWin from './QuickWin';
import QuickWinsPagination from './QuickWinsPagination';

import './QuickWins.scss';

const QuickWins: FC = () => {
	const { quickWins } = useDashboard();
	const [currentPageNumber, setCurrentPageNumber] = useState(1);
	const [currentPages, setCurrentPages] = useState<TQuickWins>(null);

	const orderedQuickWins: TQuickWins =
		quickWins &&
		quickWins.sort((win1, win2) => win2.criticalIssuesPercentage - win1.criticalIssuesPercentage);

	useEffect(() => {
		if (orderedQuickWins) {
			if (currentPageNumber === 1) {
				setCurrentPages(orderedQuickWins.slice(0, 4));
			}
			if (currentPageNumber === 2) {
				setCurrentPages(orderedQuickWins.slice(4));
			}
		}
	}, [currentPageNumber, orderedQuickWins]);

	if (!quickWins) {
		return <EvLoader message="Loading data..." />;
	}

	if (orderedQuickWins.length === 0) {
		return (
			<Box className="no-quick-wins-msg">
				<InfoLine msg="We don’t have enough data for this section." />
			</Box>
		);
	}

	return (
		<div className="quick-wins">
			{orderedQuickWins.length > 4 && (
				<QuickWinsPagination
					className="quick-wins-pagination"
					currentPage={currentPageNumber}
					setCurrentPage={setCurrentPageNumber}
				/>
			)}
			{currentPages && (
				<div className="quick-wins-page">
					{currentPages.map((qw, ind) => (
						<QuickWin
							key={qw.property.id}
							quickWin={qw}
							spaceToRight={ind !== currentPages.length - 1}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default QuickWins;
