import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';

import ComponentDrilldownPage from '../../components/drilldown/ComponentDrilldownPage';
import IssueTypeDrilldownPage from '../../components/drilldown/IssueTypeDrilldownPage';
import UrlDrilldownPage from '../../components/drilldown/UrlDrilldownPage';
import UrlsStatusDrilldownPage from '../../components/drilldown/UrlsStatusDrilldownPage';
import TabsViews from '../../types/TabsViews';

import ScanAnalysisPage from './ScanAnalysisPage';

const ScanRouter: FC = () => {
	const history = useHistory();
	const basePath = '/properties/:propertyId/scans/:scanId';
	return (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path={`${basePath}/overview`}
					render={() => <ScanAnalysisPage view={TabsViews.OVERVIEW} />}
				/>
				<Route
					exact
					path={`${basePath}/pages`}
					render={() => <ScanAnalysisPage view={TabsViews.PAGES} />}
				/>
				<Route
					exact
					path={`${basePath}/issue-types`}
					render={() => <ScanAnalysisPage view={TabsViews.ISSUE_TYPES} />}
				/>
				<Route
					exact
					path={`${basePath}/all-issues`}
					render={() => <ScanAnalysisPage view={TabsViews.BREAKDOWN} />}
				/>
				<Route
					exact
					path={`${basePath}/components`}
					render={() => <ScanAnalysisPage view={TabsViews.COMPONENTS} />}
				/>
				<Route
					exact
					path={`${basePath}`}
					render={() => <ScanAnalysisPage view={TabsViews.OVERVIEW} />}
				/>
				<Route exact path={`${basePath}/overview`} component={ScanAnalysisPage} />
				<Route
					exact
					path={`${basePath}/components/:componentId`}
					render={() => <ComponentDrilldownPage />}
				/>
				<Route exact path={`${basePath}/urls-status`} render={() => <UrlsStatusDrilldownPage />} />
				<Route exact path={`${basePath}/page`} render={() => <UrlDrilldownPage />} />
				<Route
					exact
					path={`${basePath}/issue-types/:issueType`}
					render={() => <IssueTypeDrilldownPage />}
				/>
				<Route render={() => <ScanAnalysisPage view={TabsViews.OVERVIEW} />} />
			</Switch>
		</Router>
	);
};

export default ScanRouter;
