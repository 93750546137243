import React, { FC } from 'react';

import classNames from 'classnames';

import { EvTitle } from '@evinced-private/ui-common';

import CollectionsSwitch from '../../components/common/collections-switch/CollectionsSwitch';
import { hasEnoughActiveProperties } from '../../helpers/dashboard/DashboardHelper';
import { useCollections } from '../../providers/collections/CollectionsProvider';
import { useDashboard } from '../../providers/dashboard/DashboardProvider';

import Box from './components/box/Box';
import Kpis from './components/kpis/Kpis';
import VerticalSeparator from './components/kpis/VerticalSeparator';
import NoDataErrorMessage from './components/no-data-error-message/NoDataErrorMessage';
import PerformanceOverTimeSection from './components/performance-over-time/PerformanceOverTimeSection';
import QuickWins from './components/quick-wins/QuickWins';
import ScoreAcrossProperties from './components/score-across-properties/ScoreAcrossProperties';
import SelectLiftPriod from './components/select-lift-priod/SelectLiftPriod';
import TopImpactingProperties from './components/top-impacting-properties/TopImpactingProperties';

import './Dashboard.scss';

const quickWinSuggestionsTooltip =
	"We've automatically identified places in your code where a single change can have a big improvement.";

const DASHBOARD_SECTIONS = {
	overview: {
		title: 'Account summary'
	},
	quickWin: {
		title: 'Quick win suggestions',
		// eslint-disable-next-line react/jsx-no-useless-fragment
		tooltip: <>{quickWinSuggestionsTooltip}</>
	},
	performanceOverTime: {
		title: 'Performance over time'
	}
};

const DashboardV2: FC = () => {
	const { numberOfActiveProperties } = useDashboard();
	const { showOnlyPropertiesInMyCollection, collectionName, updatePropertiesCollectionState } =
		useCollections();

	return (
		<div className="dashboard">
			<div className="dashboard-header">
				<div className="ev-filters-wrapper">
					<EvTitle
						titleText={DASHBOARD_SECTIONS.overview.title}
						className="dashboard-section-title"
					/>
				</div>
				<div className="header-right">
					<div className="collections-switch">
						<CollectionsSwitch
							showOnlyPropertiesInMyCollection={showOnlyPropertiesInMyCollection}
							setShowOnlyPropertiesInMyCollection={updatePropertiesCollectionState}
							collectionName={collectionName}
						/>
					</div>
					<VerticalSeparator />
					<SelectLiftPriod />
				</div>
			</div>
			{!hasEnoughActiveProperties(numberOfActiveProperties) ? (
				<NoDataErrorMessage showOnlyPropertiesInMyCollection={showOnlyPropertiesInMyCollection} />
			) : (
				<>
					<section className={classNames('dashboard-section', 'first')}>
						<Box className="kpi-wrapper">
							<Kpis />
						</Box>
						<div className="progress">
							<Box className="top-impact-properties-wrapper">
								<TopImpactingProperties />
							</Box>
							<Box className="score-across-properties-wrapper">
								<ScoreAcrossProperties />
							</Box>
						</div>
					</section>
					<section className="dashboard-section">
						<EvTitle
							titleText={DASHBOARD_SECTIONS.quickWin.title}
							MoreInfoContent={DASHBOARD_SECTIONS.quickWin.tooltip}
							className="dashboard-section-title"
						/>
						<Box className="quick-wins-wrapper">
							<QuickWins />
						</Box>
					</section>
					<section className="dashboard-section">
						<EvTitle
							titleText={DASHBOARD_SECTIONS.performanceOverTime.title}
							className="dashboard-section-title"
						/>
						<Box className="performance-overtime-wrapper">
							<PerformanceOverTimeSection />
						</Box>
					</section>
				</>
			)}
		</div>
	);
};

export default DashboardV2;
