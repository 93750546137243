import React, { FC } from 'react';

import { EvInfoTooltip, EvSwitch, useDebounce } from '@evinced-private/ui-common';

import { useTablesStore } from '../../../providers/tablesProvider/TablesProvider';

const screenId = 'properties-table';

interface ICollectionsSwitch {
	showOnlyPropertiesInMyCollection: boolean;
	collectionName: string;
	setShowOnlyPropertiesInMyCollection: (value: boolean) => void;
	isDisabled?: boolean;
}

const CollectionsSwitch: FC<ICollectionsSwitch> = ({
	setShowOnlyPropertiesInMyCollection,
	showOnlyPropertiesInMyCollection,
	collectionName,
	isDisabled
}) => {
	const tablesStore = useTablesStore();
	const debouncedShowOnlyPropertiesInMyCollection = useDebounce(
		showOnlyPropertiesInMyCollection,
		300
	);
	const collectionsSwitchLabel = `Filter by "${collectionName}"`;

	const updateShowCollectionsTableToggle = (value: boolean): void => {
		const { sizePerPage } = tablesStore.getPagination(screenId);
		tablesStore.updatePagination(screenId, { page: 1, sizePerPage });
		setShowOnlyPropertiesInMyCollection(value);
	};

	return (
		<>
			<span>{collectionsSwitchLabel}</span>
			<EvInfoTooltip title={collectionsSwitchLabel} tooltipPlace="top">
				Collect and track properties. To add a property, click “Add
				<br />
				to {collectionName}” under the actions menu.
				<br />
				Active properties, those scanned within the last 90 days.
			</EvInfoTooltip>
			<EvSwitch
				disabled={isDisabled}
				onChange={updateShowCollectionsTableToggle}
				checked={debouncedShowOnlyPropertiesInMyCollection}
				ariaLabel={collectionsSwitchLabel}
			/>
		</>
	);
};

export default CollectionsSwitch;
