import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
	EvCheckbox,
	EvIcon,
	EvRadioGroup,
	EvSection,
	EvSelect,
	EvSwitch,
	EvTitle,
	OptionType
} from '@evinced-private/ui-common';

import SchedulingHelper, {
	monthsDayOptions,
	timeOptions,
	timezoneOptions,
	weekdayOptions
} from '../../../../helpers/SchedulingHelper';
import { IProperty } from '../../../../interfaces/Property';
import { IPropertyError } from '../../../../interfaces/PropertyError';
import {
	FrequencyDto,
	IScheduledJobConfiguration,
	JobTypeDto
} from '../../../../interfaces/ScheduledJobConfiguration';
import { SetPropertyType } from '../../../../pages/property-settings/PropertySettingsPageTypes';
import URL_SET_SOURCES from '../../../../types/UrlSetSources';
import BlueInfoIcon from '../../../icons/BlueInfoIcon.svg';

import './SchedulingSettings.scss';

const SCHEDULING_SETTINGS_TITLE = 'scheduling-settings-title';
const SCHEDULING_SETTINGS_DESCRIPTION = 'scheduling-settings-description';

interface ISchedulingSettingsProps {
	property: IProperty;
	propertyError?: IPropertyError;
	setProperty: SetPropertyType;
}

const monthlySelectionWarning =
	'If there are less days in a month, the scan will run on the last day of the month.';

const SchedulingSettings: FC<ISchedulingSettingsProps> = ({
	property,
	propertyError,
	setProperty
}: ISchedulingSettingsProps) => {
	const initialConfig = property.scheduledJobConfiguration;

	/** State */
	const [schedulingEnabled, setSchedulingEnabled] = useState<boolean>(
		initialConfig?.active || false
	);
	const [includeCrawl, setIncludeCrawl] = useState(
		initialConfig?.jobType !== JobTypeDto.SCAN &&
			// set to false if property is in list mode
			property?.urlSetConfiguration?.source === URL_SET_SOURCES.URL_DISCOVERY
	);
	const [selectedFrequency, setSelectedFrequency] = useState<FrequencyDto>(
		initialConfig?.frequency || FrequencyDto.WEEKLY
	);
	const [selectedWeekDay, setSelectedWeekDay] = useState<OptionType>(
		weekdayOptions.find((d) => d.value === initialConfig?.wdays?.[0]) || weekdayOptions[0]
	);

	const initialTime = initialConfig?.hours
		? SchedulingHelper.getTimeValue(initialConfig?.hours[0], initialConfig?.minutes[0])
		: null;

	const [selectedTime, setSelectedTime] = useState<OptionType>(
		timeOptions.find((t) => t.value === initialTime) || timeOptions.find((t) => t.value === '10:00')
	);
	const [selectedTimezone, setSelectedTimezone] = useState<OptionType>(
		SchedulingHelper.getInitialSelectedTimezone(initialConfig?.timezone)
	);

	const [selectedMonthDays, setSelectedMonthDays] = useState<OptionType[]>(
		initialConfig?.mdays
			? monthsDayOptions.filter((m) => initialConfig.mdays?.includes(m.value as number))
			: [monthsDayOptions[0]]
	);

	const createUpdatedConfig = useCallback((): IScheduledJobConfiguration => {
		const scheduledJobConfig: IScheduledJobConfiguration = initialConfig
			? { ...initialConfig }
			: {};
		scheduledJobConfig.active = schedulingEnabled;
		scheduledJobConfig.frequency = selectedFrequency;
		const { minutes, hours } = SchedulingHelper.parseTimeStr(selectedTime.value as string);
		scheduledJobConfig.hours = [hours];
		scheduledJobConfig.minutes = [minutes];
		scheduledJobConfig.jobType = includeCrawl ? JobTypeDto.CRAWL_AND_SCAN : JobTypeDto.SCAN;
		scheduledJobConfig.timezone = selectedTimezone.value as string;

		if (scheduledJobConfig.frequency === FrequencyDto.MONTHLY) {
			scheduledJobConfig.wdays = null;
			scheduledJobConfig.mdays = selectedMonthDays.map((o) => o.value as number);
		} else {
			scheduledJobConfig.wdays = [selectedWeekDay.value as number];
			scheduledJobConfig.mdays = null;
		}
		return scheduledJobConfig;
	}, [
		includeCrawl,
		selectedFrequency,
		selectedMonthDays,
		schedulingEnabled,
		selectedTime,
		selectedTimezone,
		selectedWeekDay,
		initialConfig
	]);

	useEffect(() => {
		if (initialConfig || schedulingEnabled) {
			const updatedConfig = createUpdatedConfig();
			setProperty((property: IProperty) => {
				property.scheduledJobConfiguration = updatedConfig;
				return property;
			});
		}
	}, [createUpdatedConfig, setProperty, schedulingEnabled, initialConfig]);

	/** rendering functions  */

	const renderHeader = (): JSX.Element => {
		const helpTooltipContent: JSX.Element = (
			<>Set a start time and interval of when and how often scans will automatically be executed.</>
		);
		return (
			<div className="section-header">
				<div className="title">
					<EvTitle
						titleText="Schedule Scans"
						MoreInfoContent={helpTooltipContent}
						id={SCHEDULING_SETTINGS_TITLE}
						descriptionId={SCHEDULING_SETTINGS_DESCRIPTION}
					/>
					<div className="actions">
						<EvSwitch
							ariaLabel={`${schedulingEnabled ? 'Disable' : 'Enable'} crawl scheduling`}
							checked={schedulingEnabled}
							onChange={() => {
								setSchedulingEnabled((prev) => !prev);
							}}
						/>
					</div>
				</div>
			</div>
		);
	};

	const renderIncludeCrawlCheckbox = (): JSX.Element => {
		if (property?.urlSetConfiguration?.source === URL_SET_SOURCES.UPLOAD) {
			// no need to show toggle in list mode
			return null;
		}
		return (
			<EvCheckbox
				isChecked={includeCrawl}
				label="Include crawl"
				ariaLabel="Include crawl"
				onClick={() => {
					setIncludeCrawl((prev) => !prev);
				}}
				isDisabled={!schedulingEnabled}
			/>
		);
	};

	const renderFrequencySelection = (): JSX.Element => {
		const radioGroupOptions = [
			{
				label: 'Week',
				isChecked: selectedFrequency === FrequencyDto.WEEKLY,
				onClick: () => setSelectedFrequency(FrequencyDto.WEEKLY)
			},
			{
				label: 'Month',
				isChecked: selectedFrequency === FrequencyDto.MONTHLY,
				onClick: () => setSelectedFrequency(FrequencyDto.MONTHLY)
			}
		];
		return (
			<div className="frequency-selection">
				<EvRadioGroup
					title="Every"
					className="scheduling-frequency-radio-group"
					name="frequency-selection-radio"
					options={radioGroupOptions}
					isDisabled={!schedulingEnabled}
					ariaLabel="Scheduling Frequency Selection"
				/>
			</div>
		);
	};

	const renderFirstDropdown = (): JSX.Element => {
		if (selectedFrequency === FrequencyDto.MONTHLY) {
			return (
				<EvSelect
					isMulti={true}
					isSearchable={false}
					className="day-in-month-select"
					placeholder="Day in month"
					value={selectedMonthDays}
					options={monthsDayOptions}
					onChange={(selectedItem) => {
						setSelectedMonthDays(selectedItem);
					}}
					isDisabled={!schedulingEnabled}
				/>
			);
		}

		return (
			<EvSelect
				isMulti={false}
				isSearchable={false}
				placeholder="Week day"
				className="day-in-week-select"
				value={[selectedWeekDay]}
				options={weekdayOptions}
				onChange={(selectedItem) => {
					setSelectedWeekDay(selectedItem);
				}}
				isDisabled={!schedulingEnabled}
			/>
		);
	};

	const renderDateSelection = (): JSX.Element => {
		return (
			<div className="date-selection">
				<p className="text">On</p>
				{renderFirstDropdown()}
				<EvSelect
					isMulti={false}
					isSearchable={false}
					placeholder="Time"
					className="time-select"
					value={[selectedTime]}
					options={timeOptions}
					onChange={(selectedItem) => {
						setSelectedTime(selectedItem);
					}}
					isDisabled={!schedulingEnabled}
				/>

				<EvSelect
					isMulti={false}
					isSearchable={true}
					placeholder="Timezone"
					className="timezone-select"
					value={[selectedTimezone]}
					options={timezoneOptions}
					onChange={(selectedItem) => {
						setSelectedTimezone(selectedItem);
					}}
					isDisabled={!schedulingEnabled}
				/>
			</div>
		);
	};

	const renderMonthWarning = (): JSX.Element => {
		if (selectedFrequency !== FrequencyDto.MONTHLY) {
			return null;
		}
		return (
			<div className="month-selection-warning">
				<EvIcon icon={BlueInfoIcon} />
				{monthlySelectionWarning}
			</div>
		);
	};

	const renderContent = (): JSX.Element => {
		return (
			<div className="scheduling-content">
				{renderIncludeCrawlCheckbox()}
				{renderFrequencySelection()}
				{renderDateSelection()}
				{renderMonthWarning()}
			</div>
		);
	};

	const error = useMemo(() => {
		return propertyError && <div className="tab-error">{propertyError.message}</div>;
	}, [propertyError]);

	return (
		<EvSection
			className="scheduling-settings-section"
			ariaLabelledby={SCHEDULING_SETTINGS_TITLE}
			ariaDescribedby={SCHEDULING_SETTINGS_DESCRIPTION}
		>
			<div className="scheduling-settings-content">
				{renderHeader()}
				<div className="section-body">
					{renderContent()}
					{error}
				</div>
			</div>
		</EvSection>
	);
};

export default SchedulingSettings;
