/* eslint-disable no-mixed-spaces-and-tabs */
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { EvSpinner, PendoService } from '@evinced-private/ui-common';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import ApiErrorHelper from '../../helpers/ApiErrorHelper.ts';
import EnvironmentHelper from '../../helpers/EnvironmentHelper.ts';
import InvitationTokenHelper from '../../helpers/InvitationTokenHelper.ts';
import RoutesHelper from '../../helpers/RoutesHelper.ts';
import AuthenticationService from '../../services/AuthenticationService.ts';
import Logger from '../../services/Logger.ts';
import loginRedirectionService from '../../services/LoginRedirectionService.ts';
import tenantsService from '../../services/TenantsService.ts';
import userInvitationsService from '../../services/UserInvitationService.ts';
import FullPageError from '../full-page-error/FullPageError.tsx';

const pendoApiKey = process.env.PENDO_API_KEY;

const getCurrentRelativeUrl = () => {
	let url = window.location.pathname;
	const params = window.location.search;
	const urlParams = new URLSearchParams(params);
	const searchParams = urlParams.toString();
	if (searchParams) {
		url += `?${searchParams}`;
	}
	return url;
};

const saveCurrentUrlForRedirection = async () => {
	const redirectToUrl = getCurrentRelativeUrl();
	loginRedirectionService.setRedirectToPath(redirectToUrl);
};

/**
 *
 * @returns {hasTenant: boolean, hasAccessToProduct: boolean,
 *  hasPendingInvitations: boolean }
 */
const getUserStatus = async () => {
	const userTenant = await tenantsService.getUserTenant();
	const hasAccessToProduct = userTenant
		? await tenantsService.hasAccessToProduct(userTenant)
		: false;
	const invitaitonTokenFromUrl = InvitationTokenHelper.getInvitationTokenFromUrl();
	const pendingInvitations = await userInvitationsService.getAllUserPendingInvitations();

	return {
		hasTenant: !!userTenant,
		hasAccessToProduct,
		hasPendingInvitations: !!invitaitonTokenFromUrl || pendingInvitations.length > 0
	};
};

const initializePendo = () => {
	if (!EnvironmentHelper.isCI()) {
		PendoService.init(
			{
				visitorId: AuthenticationService.getEvincedUserId(),
				email: AuthenticationService.getUserEmail(),
				accountId: AuthenticationService.getUserEmailDomain()
			},
			pendoApiKey,
			Logger
		);
	}
};

function PrivateRoute({ component: RouteComponent, ...rest }) {
	const [isLoading, setLoading] = useState(true);
	const [userStatus, setUserStatus] = useState({
		hasTenant: false,
		hasAccessToProduct: false,
		hasPendingInvitations: false
	});
	const [error, setError] = useState(null);
	const { location } = rest;

	const checkIfUserHasTenant = useCallback(async () => {
		// short circuit - no need to check if there's a tenant on each route if we know they have
		if (userStatus.hasTenant && userStatus.hasAccessToProduct) {
			return;
		}
		setLoading(true);

		try {
			const updatedUserStatus = await getUserStatus();
			if (!_.isEqual(updatedUserStatus, userStatus)) {
				setUserStatus(updatedUserStatus);
			}
		} catch (error) {
			Logger.error('Error getting tenant', error);
			setError(
				`An error occurred while retrieving your account information. ${ApiErrorHelper.getErrorMessage(
					error
				)}`
			);
		} finally {
			setLoading(false);
		}
	}, [userStatus]);

	useEffect(() => {
		checkIfUserHasTenant();
	}, [checkIfUserHasTenant]);

	if (isLoading) {
		return <EvSpinner />;
	}
	if (error) {
		return <FullPageError title="Product Loading Error" />;
	}
	initializePendo();
	return (
		<Route
			{...rest}
			location={location}
			render={(props) => {
				if (!AuthenticationService.isLoggedIn()) {
					return (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: props.location },
								search: props.location.search
							}}
						/>
					);
				}

				// handling account activation and product invitation
				const { pathname } = location;
				const { hasTenant, hasAccessToProduct, hasPendingInvitations } = userStatus;

				if (!hasTenant || !hasAccessToProduct) {
					let redirectTo = '';
					if (hasTenant && !hasAccessToProduct) {
						redirectTo = RoutesHelper.getProductActivationPage();
					} else if (hasPendingInvitations) {
						redirectTo = RoutesHelper.getInvitationActivationPage();
					} else {
						redirectTo = RoutesHelper.getNoTenantPage();
					}
					// no need for redirect if the user is already in that path
					if (pathname !== redirectTo) {
						return (
							<Redirect
								to={{
									pathname: redirectTo,
									state: { from: props.location },
									search: props.location.search
								}}
							/>
						);
					}
				}
				return <RouteComponent {...props} />;
			}}
		/>
	);
}

PrivateRoute.propTypes = {
	component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	location: PropTypes.object
};

export default withAuthenticationRequired(PrivateRoute, {
	onBeforeAuthentication: saveCurrentUrlForRedirection,
	onRedirecting: () => {
		return <EvSpinner />;
	}
});
