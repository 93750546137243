import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import {
	BUTTON_TYPES,
	EvButton,
	EvConfirm,
	EvIcon,
	EvLink,
	EvTextInput,
	EvTitle,
	TITLE_MODES
} from '@evinced-private/ui-common';

import debounce from 'lodash/debounce';

import { SITE_SCANNER_APP_ID } from '../../consts/dom-consts';
import domainInputPlaceholder from '../../consts/domainPlaceholderText';
import ApiErrorHelper from '../../helpers/ApiErrorHelper';
import PropertyHelper from '../../helpers/PropertyHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import UrlNormalizingHelper from '../../helpers/UrlNormalizingHelper';
import UrlValidationHelper from '../../helpers/UrlValidationHelper';
import { IValidationResult } from '../../interfaces/ValidationResult';
import LimitsService from '../../services/LimitsService';
import Logger from '../../services/Logger';
import propertiesService from '../../services/PropertiesService';
import UrlValidationService from '../../services/UrlValidationService';
import AllowedDomainsError from '../AllowedDomainsError';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';
import NavigateBackIcon from '../icons/NavigateBackIcon.svg';

import './CreatePropertyPopup.scss';

interface ICreatePropertyPopupProps {
	isOpen: boolean;
	onClose: () => void;
}
const enum CREATION_STEPS {
	ENTER_URL,
	CONFIRM
}

const CreatePropertyPopup: FC<ICreatePropertyPopupProps> = ({
	isOpen = false,
	onClose
}: ICreatePropertyPopupProps) => {
	const history = useHistory();
	const [step, setStep] = useState<CREATION_STEPS>(CREATION_STEPS.ENTER_URL);
	const [url, setUrl] = useState<string>(null);
	const [isNextEnabled, setNextEnabled] = useState<boolean>(false);
	const [normalizedUrl, setNormalizedUrl] = useState<string>('');
	const [error, setError] = useState<string>(null);
	const [domainError, setDomainError] = useState(null);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

	const validateUrl = useCallback((url): void => {
		const urlValidationResult = UrlValidationHelper.validateUrlFormat(url);
		if (!urlValidationResult.isValid) {
			setError(urlValidationResult.errorMessages[0]); // show one error at a time
			setNextEnabled(false);
		} else {
			setError(null);
			setNextEnabled(true);
		}
		setDomainError(null);
	}, []);

	const debouncedUrlValidation = debounce(validateUrl, 500);

	useEffect(() => {
		if (url) {
			debouncedUrlValidation(url);
		}
	}, [url, debouncedUrlValidation]);

	const renderFirstStep = (): JSX.Element => {
		return (
			<div className="create-property-popup-step">
				<EvTitle titleText="Enter Site To Test" mode={TITLE_MODES.MAIN} />
				<div className="create-property-popup-content">
					<div className="instructions">Enter a URL or domain name you wish to test.</div>
					<EvTextInput
						ariaLabel="Enter a url"
						isRequired
						error={error}
						placeholder={domainInputPlaceholder}
						value={url}
						onChange={setUrl}
						isDisabled={isLoading}
					/>
					{domainError && (
						<AllowedDomainsError serverError={domainError} showServerErrorMessage={false} />
					)}
				</div>
			</div>
		);
	};

	const renderDomainScopeText = (): JSX.Element => {
		const domains = PropertyHelper.getDefaultDomains(url);
		if (domains.length === 1) {
			return (
				<div className="domain-scope-text">
					and remain in the context of <div className="domain">{domains[0]}</div> domain.
				</div>
			);
		}
		return (
			<div className="domain-scope-text">
				and remain in the context of <div className="domain">{domains[0]}</div> and{' '}
				<div className="domain">{domains[0]}</div>
				domains.
			</div>
		);
	};

	const renderConfirmStep = (): JSX.Element => {
		return (
			<div className="create-property-popup-step">
				<EvTitle titleText="Ready?" mode={TITLE_MODES.MAIN} />
				<div className="create-property-popup-content">
					<div className="instructions">
						We will start at{' '}
						<EvLink url={normalizedUrl} openInNewTab={true} linkText={normalizedUrl} />
						<br />
						Crawl up to a {LimitsService.getLimits().maxPagesCount} pages
						<br />
						{renderDomainScopeText()}
						<br />
						<br />
						You can change the above configuration in the advanced settings section.
					</div>
				</div>
			</div>
		);
	};

	const createNewProperty = async (): Promise<void> => {
		setLoading(true);
		const property = PropertyHelper.getDefaultPropertyFromTemplate(url);
		try {
			const createdProperty = await propertiesService.createNewProperty(property, true);
			setLoading(false);
			history.push(RoutesHelper.getPropertyPagePath(createdProperty.id));
		} catch (error) {
			// a temp workaround to show the unclear: {{domain}} is of an unallowed domain
			const message = ApiErrorHelper.getErrorMessage(error) || '';
			const isErrorRelatedToDomains = ApiErrorHelper.isErrorRelatedToDomains(error);

			if (isErrorRelatedToDomains) {
				setDomainError(error);
				setError(message);
			} else {
				setDomainError(null);
				setError(message || 'Error occured while creating a property');
				Logger.error('Error creating property', error);
			}

			setStep(CREATION_STEPS.ENTER_URL);
			setLoading(false);
		}
	};

	const nextButtonClicked = async (): Promise<void> => {
		setLoading(true);
		const normalizedUrl = UrlNormalizingHelper.normalizeUrlFromUserInput(url);
		setNormalizedUrl(normalizedUrl);
		try {
			const validationResult: IValidationResult = await UrlValidationService.getUrlValidationInfo(
				normalizedUrl
			);
			if (!validationResult.valid) {
				setError(validationResult.message || 'Url is not valid');
				return;
			}
			setStep(CREATION_STEPS.CONFIRM);
		} catch (err) {
			Logger.error(
				'An error occured while using the validation API, allowing the user to continue as a workaround',
				err
			);
			setStep(CREATION_STEPS.CONFIRM);
		} finally {
			setLoading(false);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getButtons = (): any[] => {
		const advancedSettingsButton = {
			title: 'Advanced Settings',
			onClick: () => {
				history.push(RoutesHelper.getNewPropertyCreationPagePath(url));
			},
			type: BUTTON_TYPES.SECONDARY,
			loading: isLoading
		};

		if (step === CREATION_STEPS.ENTER_URL) {
			return [
				advancedSettingsButton,
				{
					title: 'Next',
					disabled: !isNextEnabled,
					onClick: nextButtonClicked,
					loading: isLoading
				}
			];
		}
		return [
			{
				title: 'Back',
				wrapperClassName: 'create-property-back-btn',
				render: (): JSX.Element => {
					return (
						<EvButton
							key="Back"
							title="Back"
							type={BUTTON_TYPES.ICON}
							onClick={() => {
								setStep(CREATION_STEPS.ENTER_URL);
							}}
						>
							<EvIcon icon={NavigateBackIcon} />
						</EvButton>
					);
				}
			},
			advancedSettingsButton,
			{
				title: 'Start Crawling',
				onClick: () => {
					createNewProperty();
				},
				loading: isLoading
			}
		];
	};

	// reset state when popup closes
	useEffect(() => {
		if (!isOpen) {
			setStep(CREATION_STEPS.ENTER_URL);
			setUrl(null);
			setNextEnabled(false);
			setNormalizedUrl('');
			setError(null);
			setDomainError(null);
		}
	}, [isOpen]);

	return (
		<>
			<SiteScannerPopup
				isControlled
				isOpen={isOpen}
				isCentered
				onClose={() => {
					if (url) {
						setConfirmModalOpen(true);
					} else {
						onClose();
					}
				}}
				title={`Create new property - step ${step}`}
				isSrOnly
				className="create-property-popup"
				buttons={getButtons()}
			>
				{step === CREATION_STEPS.ENTER_URL && renderFirstStep()}
				{step === CREATION_STEPS.CONFIRM && renderConfirmStep()}
			</SiteScannerPopup>
			<EvConfirm
				appElement={SITE_SCANNER_APP_ID}
				title="Confirm close"
				approveButtonText="Confirm"
				promptMessage="You have unsaved changes, are you sure you want to go back?"
				isOpen={isConfirmModalOpen}
				isControlled={true}
				onConfirm={(closeModal) => {
					onClose();
					closeModal();
				}}
				onClose={() => setConfirmModalOpen(false)}
			/>
		</>
	);
};

export default CreatePropertyPopup;
