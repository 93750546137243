import { ILoginConfiguration } from '../interfaces/LoginConfiguration';
import LOGIN_METHOD from '../interfaces/LoginMethod';
import { IProperty } from '../interfaces/Property';
import { IPropertyError } from '../interfaces/PropertyError';
import { IScopeRule } from '../interfaces/ScopeRule';
import { IUrlSetDiscoveryConfiguration } from '../interfaces/UrlSetDiscoveryConfiguration';
// import LimitsService from '../services/LimitsService';
import SCOPE_ACTIONS from '../types/scope/ScopeActions';
import SCOPE_TYPES from '../types/scope/ScopeTypes';
import SECTION_TYPES from '../types/SettingsSectionTypes';

import PropertyHelper from './PropertyHelper';
import urlDiscoveryScopeHelper from './UrlDiscoveryScopeHelper';

export function isRegexValid(str: string): boolean {
	try {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const regex = new RegExp(str);
		return !!str;
	} catch {
		return false;
	}
}

export type FormError = { sectionId: SECTION_TYPES; message: string };

/**
 * returns true if there are 2 or more rules with same type and same url (text value)
 */
function areThereDuplicateScopeRules(
	discoveryConfiguration: IUrlSetDiscoveryConfiguration
): boolean {
	const allRules: IScopeRule[] = urlDiscoveryScopeHelper.getAllRules(discoveryConfiguration);
	const allCount = allRules.length;
	const uniqueUrlCount = Array.from(
		new Set(allRules.map((r) => JSON.stringify({ url: r.url, type: r.scopeType })))
	).length;
	return allCount !== uniqueUrlCount;
}

function isThereAnEmptyRule(discoveryConfiguration: IUrlSetDiscoveryConfiguration): boolean {
	const allRules: IScopeRule[] = urlDiscoveryScopeHelper.getAllRules(discoveryConfiguration);
	return allRules.some((r) => r.url === '' || !r.url);
}

function validateLoginFormConfiguration(
	loginConfiguration: ILoginConfiguration,
	errors: IPropertyError[]
): void {
	const { loginFormConfiguration } = loginConfiguration;
	if (
		!loginFormConfiguration.loginURL ||
		!loginFormConfiguration.usernameSelector ||
		!loginFormConfiguration.userName ||
		!loginFormConfiguration.passwordSelector ||
		!loginFormConfiguration.password ||
		!loginFormConfiguration.loginButtonSelector
	) {
		errors.push({
			sectionId: SECTION_TYPES.LOGIN,
			message: 'All fields must be filled when choosing form login option'
		});
	}
}

function validateLoginCookies(
	loginConfiguration: ILoginConfiguration,
	errors: IPropertyError[]
): void {
	if (!loginConfiguration.cookies) {
		errors.push({
			sectionId: SECTION_TYPES.LOGIN,
			message: 'Cookies must be set when choosing cookies login option'
		});
	}
}

function validateLoginConfiguration(property: IProperty, errors: IPropertyError[]): void {
	const loginConfiguration =
		property.urlSetConfiguration?.discoveryConfiguration?.loginConfiguration;
	if (loginConfiguration) {
		if (loginConfiguration.loginMethod === LOGIN_METHOD.LOGIN_FORM) {
			validateLoginFormConfiguration(loginConfiguration, errors);
		} else if (loginConfiguration.loginMethod === LOGIN_METHOD.COOKIES) {
			validateLoginCookies(loginConfiguration, errors);
		}
	}
}

export function validateProperty(property: IProperty, isListMode: boolean): FormError[] {
	const errors = [];
	if (!property.name) {
		errors.push({ sectionId: SECTION_TYPES.NAME, message: 'Property name should not be empty' });
	}

	if (isListMode) {
		if (!property.urls || property.urls.length === 0) {
			errors.push({ sectionId: SECTION_TYPES.SEEDS, message: 'Please insert at least 1 URL' });
		}
		return errors;
	}

	const seeds = PropertyHelper.getPropertyDiscoverySeeds(property);
	if (seeds.length === 0) {
		errors.push({ sectionId: SECTION_TYPES.SEEDS, message: 'Please insert at least 1 seed' });
	}

	const discoveryConfiguration = property?.urlSetConfiguration?.discoveryConfiguration;

	if (areThereDuplicateScopeRules(discoveryConfiguration)) {
		errors.push({
			sectionId: SECTION_TYPES.SCOPE,
			message: 'Please make sure there are no duplicate rules'
		});
	}

	if (isThereAnEmptyRule(discoveryConfiguration)) {
		errors.push({
			sectionId: SECTION_TYPES.SCOPE,
			message: 'Empty rules are not allowed'
		});
	}

	const allowedPatterns = urlDiscoveryScopeHelper.getDetailedRuleList(
		discoveryConfiguration,
		SCOPE_TYPES.URL,
		SCOPE_ACTIONS.INCLUDE
	);

	const allowedDomains = urlDiscoveryScopeHelper.getDetailedRuleList(
		discoveryConfiguration,
		SCOPE_TYPES.DOMAIN,
		SCOPE_ACTIONS.INCLUDE
	);

	if (!allowedPatterns.length && !allowedDomains.length) {
		errors.push({
			sectionId: SECTION_TYPES.SCOPE,
			message: 'Please insert at least 1 URL Regex or Domain rule.'
		});
	}

	const allowedLanguages = urlDiscoveryScopeHelper.getDetailedRuleList(
		discoveryConfiguration,
		SCOPE_TYPES.LANG,
		SCOPE_ACTIONS.INCLUDE
	);

	if (!allowedLanguages.length) {
		errors.push({
			sectionId: SECTION_TYPES.SCOPE,
			message: 'Please insert at least 1 language rule, use .* to include all.'
		});
	}

	const maxPages = PropertyHelper.getMaxPagesCount(property);
	const groupingRules = PropertyHelper.getPropertyDiscoveryGroupingRules(property);
	const errorGroupingRule = groupingRules.find((r) => r.maxSamplesCount > maxPages);

	if (errorGroupingRule) {
		errors.push({
			sectionId: SECTION_TYPES.GROUPING,
			message: 'Grouping rule maximal sample counts must be less than the total pages count'
		});
	}

	// TODO: will be added with server support https://evinced.atlassian.net/browse/EP-1232
	// const { maxPagesCount: pagesLimit } = LimitsService.getLimits();
	// const maxPagesTooHigh = pagesLimit < maxPages;

	// if (maxPagesTooHigh) {
	// 	errors.push({
	// 		sectionId: SECTION_TYPES.GROUPING,
	// 		message: `Grouping rule maximal sample counts must be less than ${pagesLimit}`
	// 	});
	// }

	validateLoginConfiguration(property, errors);

	return errors;
}

export default {
	validateProperty,
	isRegexValid
};
