import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LocationDescriptorObject } from 'history';

import {
	EVINCED_SEVERITIES,
	EvSpinner,
	FormatHelper,
	useSkipLinks
} from '@evinced-private/ui-common';

import ComponentsChartsV2 from '../../../../components/charts/ComponentsChartsV2';
import IssuesChartsV2 from '../../../../components/charts/IssuesChartsV2';
import PagesChartsV2 from '../../../../components/charts/PagesChartsV2';
import { ALL_CONTENT_VIEW_ID } from '../../../../components/common/ev-property-views/consts/PropertyViews';
import NoPagesMatchErrorPage from '../../../../components/common/ev-property-views/NoPagesMatchErrorPage';
import SummaryPane from '../../../../components/common/summary-pane/SummaryPane';
import GeneralError from '../../../../components/general-error/GeneralError';
import ApiErrorHelper from '../../../../helpers/ApiErrorHelper';
import RoutesHelper from '../../../../helpers/RoutesHelper';
import ScanAndPropertyResultsHelper from '../../../../helpers/ScanAndPropertyResultsHelper';
import ScoreHelper from '../../../../helpers/ScoreHelper';
import { IIssuesOverviewResponse } from '../../../../interfaces/IssuesOverviewResponse';
import { ScanScore } from '../../../../interfaces/ScanScore';
import { SummaryItem } from '../../../../interfaces/SummaryItem';
import Logger from '../../../../services/Logger';
import scanService, { ScanOverviewResponse } from '../../../../services/scan/ScanService';
import scansService from '../../../../services/scan/ScansService';
import { ComponentOverviewResponse, PagesOverviewResponse } from '../../../../types/OverviewTypes';
import TabsViews from '../../../../types/TabsViews';

import MostFrequestIssuesTableV2 from './tables/top-issues/MostFrequentIssuesTableV2';
import TopPagesByIssueCountTableV2 from './tables/top-pages/TopPagesByIssueCountTableV2';

import './ScanOverviewView.scss';

interface IScanOverviewViewProps {
	scanId: string;
	propertyId: string;
	viewId?: string;
	renderActionButtons?: () => JSX.Element;
}

const ScanOverviewViewV2: FC<IScanOverviewViewProps> = ({
	scanId,
	propertyId,
	viewId,
	renderActionButtons
}: IScanOverviewViewProps) => {
	const history = useHistory();
	const [pagesOverview, setPagesOverview] = useState<PagesOverviewResponse>(null);
	const [issuesOverview, setIssuesOverview] = useState<IIssuesOverviewResponse>(null);
	const [componentsOverview, setComponentsOverview] = useState<ComponentOverviewResponse>(null);
	const [scanScore, setScanScore] = useState<number>(null);
	const [error, setError] = useState<string>(null);
	const [isLoading, setIsLoading] = useState(true);

	const totalCriticalIssues = useMemo(
		() => ScanAndPropertyResultsHelper.getIssuesCount(issuesOverview, EVINCED_SEVERITIES.CRITICAL),
		[issuesOverview]
	);

	const hideCharts = useMemo(
		() =>
			viewId &&
			viewId !== ALL_CONTENT_VIEW_ID &&
			pagesOverview?.overview?.pagesWithIssues === 0 &&
			issuesOverview?.issuesCount === 0,
		[viewId, pagesOverview, issuesOverview]
	);

	useEffect(() => {
		const loadScanOverview = async (): Promise<void> => {
			setIsLoading(true);
			try {
				const scanOverview: ScanOverviewResponse = await scanService.getScanOverview(
					scanId,
					viewId
				);
				const scanScore: ScanScore[] = await scansService.getScansScore([scanId], viewId);
				setIssuesOverview(scanOverview.issues);
				setPagesOverview(scanOverview.pages);
				setComponentsOverview(scanOverview.components);
				setScanScore(ScoreHelper.roundScore(scanScore[0]?.scoreResult?.score) || 0);
			} catch (error) {
				Logger.error('Error occured while getting scan data', error);
				setError(
					`Error occured while getting scan data. ${ApiErrorHelper.getErrorMessage(error) ?? ''}`
				);
			} finally {
				if (viewId !== undefined) {
					setIsLoading(false);
				}
			}
		};
		loadScanOverview();
	}, [scanId, viewId]);

	// updating skip links data when page is loaded
	useSkipLinks(!isLoading);

	if (error) {
		return (
			<div className="scan-overview-view">
				<GeneralError title="Error loading scan">{error}</GeneralError>
			</div>
		);
	}

	if (isLoading) {
		return (
			<div className="scan-overview-view">
				<EvSpinner />
			</div>
		);
	}

	const getSummaryPaneDataV2 = (): SummaryItem[] => {
		const summary: SummaryItem[] = [
			{
				value: `${scanScore}`,
				label: 'Score',
				isDashedBorder: true
			},
			{
				value: FormatHelper.formatNumber(pagesOverview?.overview.requested),
				label: 'Pages requested',
				link: RoutesHelper.getScanResultUrlsStatusPath(propertyId, scanId)
			},
			{
				value: FormatHelper.formatNumber(pagesOverview?.overview.scanned),
				label: 'Pages scanned',
				link: RoutesHelper.getScanResultUrlsStatusPath(propertyId, scanId)
			},
			{
				value: FormatHelper.formatNumber(pagesOverview?.overview.failed),
				label: 'Pages failed',
				link: RoutesHelper.getScanResultUrlsStatusPath(propertyId, scanId)
			}
		];

		const filteredOut = pagesOverview?.overview.filteredOut;
		// don't show this on old scans
		if (filteredOut > 0) {
			summary.push({
				value: FormatHelper.formatNumber(filteredOut),
				label: 'Pages duplicated',
				link: RoutesHelper.getScanResultUrlsStatusPath(propertyId, scanId)
			});
		}

		return summary;
	};

	const redirectToPage = (stateKey, stateValue, tabView: TabsViews): void => {
		history.push({
			pathname: RoutesHelper.getScanDetailsView(propertyId, scanId, tabView),
			state: {
				[stateKey]: stateValue,
				isTemporary: true
			}
		} as LocationDescriptorObject);
	};

	const redirectToPagesPage = (stateKey, stateValue): void => {
		redirectToPage(stateKey, stateValue, TabsViews.PAGES);
	};

	const redirectToAllIssuesPage = (stateKey, stateValue): void => {
		redirectToPage(stateKey, stateValue, TabsViews.BREAKDOWN);
	};

	const redirectToComponentsPage = (componentId): void => {
		history.push({
			pathname: RoutesHelper.getComponentDrilldownPath(propertyId, scanId, componentId),
			state: {
				isTemporary: true
			}
		} as LocationDescriptorObject);
	};

	return (
		<div className="scan-overview-view">
			<SummaryPane
				items={getSummaryPaneDataV2()}
				sectionLabel="Scan summary"
				className="overview-summary-pane"
				renderActionButtons={renderActionButtons}
			/>

			{hideCharts ? (
				<>
					<PagesChartsV2
						viewId={viewId}
						pagesSeverities={pagesOverview.severities}
						pagesScanned={pagesOverview.overview.scanned}
						totalIssues={issuesOverview.issuesCount}
						pagesWithIssues={pagesOverview.overview.pagesWithIssues}
						onBarItemClick={redirectToPagesPage}
					/>
					<NoPagesMatchErrorPage />
				</>
			) : (
				<>
					<PagesChartsV2
						pagesSeverities={pagesOverview.severities}
						pagesScanned={pagesOverview.overview.scanned}
						totalIssues={issuesOverview.issuesCount}
						pagesWithIssues={pagesOverview.overview.pagesWithIssues}
						onBarItemClick={redirectToPagesPage}
					/>
					<IssuesChartsV2
						severitiesData={issuesOverview.severities}
						totalCriticalIssues={totalCriticalIssues}
						totalIssues={issuesOverview.issuesCount}
						onTableLinkItemClick={redirectToAllIssuesPage}
					/>
					<ComponentsChartsV2
						scanId={scanId}
						propertyId={propertyId}
						componentsCountHash={componentsOverview}
						totalCriticalIssues={totalCriticalIssues}
						totalIssues={issuesOverview.issuesCount}
						groupedIssues={issuesOverview.groupedIssuesCount}
						onComponentTableLinkClick={redirectToComponentsPage}
					/>
					<div className="overview-tables-section">
						<MostFrequestIssuesTableV2
							scanId={scanId}
							propertyId={propertyId}
							issueSeverities={issuesOverview.severities}
						/>
						<TopPagesByIssueCountTableV2 scanId={scanId} propertyId={propertyId} />
					</div>
				</>
			)}
		</div>
	);
};

export default ScanOverviewViewV2;
