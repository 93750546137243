import React, { FC } from 'react';

import classNames from 'classnames';

import { EvTitle, TITLE_MODES } from '@evinced-private/ui-common';

import './NoDataIndication.scss';

interface INoDataIndicationProps {
	title: string;
	renderTextMessage: () => JSX.Element;
	className?: string;
	renderMessageAction?: () => JSX.Element;
	renderIcon?: () => JSX.Element;
	titleMode?: TITLE_MODES;
}

const NoDataIndication: FC<INoDataIndicationProps> = ({
	title,
	renderTextMessage,
	className,
	renderMessageAction,
	renderIcon,
	titleMode = TITLE_MODES.MAIN
}: INoDataIndicationProps): JSX.Element => {
	const classes = classNames('no-data-indication', {
		[className]: !!className
	});
	return (
		<div className={classes}>
			<div className="no-data-indication-content">
				{renderIcon?.()}
				<EvTitle titleText={title} mode={titleMode} />
				<div className="message-text">{renderTextMessage()}</div>
				{renderMessageAction && <div className="message-action">{renderMessageAction()}</div>}
			</div>
		</div>
	);
};

export default NoDataIndication;
