import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { EvContactSupportEmailLink, EvSpinner } from '@evinced-private/ui-common';

import { MAIN_TITLE } from 'src/consts/Title';

import SiteScannerHeader from '../../components/header/SiteScannerHeader';
import InvitationTokenHelper, {
	EMAIL_QUERY_PARAM,
	TOKEN_QUERY_PARAM
} from '../../helpers/InvitationTokenHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import Logger from '../../services/Logger';
import LoginRedirectionService from '../../services/LoginRedirectionService';
import userInvitationsService, {
	PUBLIC_INVITATON_STATE
} from '../../services/UserInvitationService';

import './InvitationRedirectionPage.scss';

const InvitationRedirectionPage: FC = () => {
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState(null);
	const GENERAL_ERROR = 'This account cannot be activated.';

	const showError = (): void => {
		setError(GENERAL_ERROR);
		setLoading(false);
	};

	const checkToken = useCallback(async (): Promise<void> => {
		const token = InvitationTokenHelper.getInvitationTokenFromUrl();
		if (!token) {
			showError();
			return;
		}
		const email = InvitationTokenHelper.getEmailFromUrl();
		Logger.info(`Checking user-Invitation: Token: ${token}, Email: ${email}`);
		try {
			const invitationState = await userInvitationsService.getInvitationTokenPublicState(token);
			const emailParam = encodeURIComponent(email);
			const query = `${TOKEN_QUERY_PARAM}=${token}&${EMAIL_QUERY_PARAM}=${emailParam}`;

			let path = null;
			if (invitationState.state === PUBLIC_INVITATON_STATE.SIGNED_UP) {
				path = `${RoutesHelper.getLoginPath()}?${query}`;
			} else {
				path = `${RoutesHelper.getSignupPath()}?${query}`;
			}

			LoginRedirectionService.setRedirectToPath(RoutesHelper.getInvitationActivationPage(token));
			history.push(path);
		} catch (e) {
			Logger.error('Error when validating token', e);
			showError();
		}
	}, [history]);

	useEffect(() => {
		checkToken();
	}, [checkToken]);

	const renderContent = (): JSX.Element => {
		if (loading) {
			return (
				<div className="invitation-redirection-page">
					<EvSpinner />
				</div>
			);
		}

		// should get here only if there was an error
		return (
			<div className="invitation-redirection-page">
				<div className="error-title">Could not activate your account</div>
				<div className="error-body">{error}</div>
				<div className="contact-support">
					For more information, please contact us at <EvContactSupportEmailLink />
				</div>
			</div>
		);
	};

	return (
		<>
			<SiteScannerHeader isAuthenticatedPage={false} />
			<main className="main-section" aria-labelledby={MAIN_TITLE}>
				{renderContent()}
			</main>
		</>
	);
};

export default InvitationRedirectionPage;
