import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { EvSection } from '@evinced-private/ui-common';

import { SummaryItem } from '../../../interfaces/SummaryItem';

import './SummaryPane.scss';

interface ISummaryPaneProps {
	items: SummaryItem[];
	sectionLabel?: string;
	labeledById?: string;
	className?: string;
	shouldHaveSkipLink?: boolean;
	renderActionButtons?: () => JSX.Element;
}

const SummaryPane: FC<ISummaryPaneProps> = ({
	items,
	className,
	sectionLabel = '',
	labeledById = '',
	shouldHaveSkipLink = true,
	renderActionButtons
}: ISummaryPaneProps) => {
	const renderPaneContent = (value, label): JSX.Element => (
		<div className="summary-pane-content">
			<div className="value" aria-hidden="true">
				{value}
			</div>
			<div className="label" aria-hidden="true">
				{label}
			</div>
		</div>
	);

	const renderSummaryItem = ({ label, value, link, isDashedBorder }: SummaryItem): JSX.Element => {
		return (
			<li
				className={classNames('summary-pane-item', { 'is-dashed': isDashedBorder })}
				key={label}
				aria-label={`${value} ${label}`}
			>
				{link ? (
					<Link to={link} aria-label={`${label} details`}>
						{renderPaneContent(value, label)}
					</Link>
				) : (
					renderPaneContent(value, label)
				)}
			</li>
		);
	};
	return (
		<EvSection
			ariaLabel={sectionLabel}
			ariaLabelledby={labeledById}
			skipLinkId={shouldHaveSkipLink ? 'summary' : null}
		>
			<ul className={`summary-pane ${className}`}>
				{items.map(renderSummaryItem)}
				{renderActionButtons?.()}
			</ul>
		</EvSection>
	);
};

export default SummaryPane;
