import React, { FC } from 'react';

import ScanCrawlStateHelper from '../../../../helpers/ScanCrawlStateHelper';
import SCAN_STATE from '../../../../interfaces/ScanState';
import StatusIcon from '../../../status-icon/StatusIcon';

import './StatusFormatter.scss';

interface IStatusFormatterProps {
	scanState: SCAN_STATE;
}

const StatusFormatter: FC<IStatusFormatterProps> = ({ scanState }: IStatusFormatterProps) => {
	if (!scanState) {
		return <>N/A</>;
	}
	const isCanceled = ScanCrawlStateHelper.isCanceled(scanState);
	if (isCanceled) {
		return <>N/A</>;
	}

	let cellText: string = scanState;

	if (
		ScanCrawlStateHelper.isIngesting(scanState) ||
		ScanCrawlStateHelper.isAnyCsvReadyForDownload(scanState)
	) {
		cellText = 'Done';
	}
	return (
		<div className="status-cell">
			<StatusIcon scanState={scanState} />
			<div className="status-text">{cellText}</div>
		</div>
	);
};

export default StatusFormatter;
