import React, { FC } from 'react';

import { UrlCellFormatter } from '@evinced-private/ui-common';

import './UrlStatusDetailsFormatter.scss';

interface IUrlStatusDetailsFormatter {
	url: string;
}
const UrlStatusDetailsFormatter: FC<IUrlStatusDetailsFormatter> = ({
	url
}: IUrlStatusDetailsFormatter) => {
	return url ? (
		<div className="url-status-details">
			Redirected to <UrlCellFormatter url={url} />
		</div>
	) : null;
};

export default UrlStatusDetailsFormatter;
