import React, { FC } from 'react';

import './AccountPlanInfo.scss';

const FreeTrialPlanInfo: FC = () => {
	return (
		<div className="account-plan-info">
			<div className="info-paragrah">
				Please note this is a limited trial version: <br />
			</div>
			<div className="info-paragrah">
				<ul className="info-list">
					<li className="info-list-item">Up to 50,000 URLs crawled and 50,000 URLs scanned.</li>
					<li className="info-list-item">Each scan is limited to 1,500 pages</li>
					<li className="info-list-item">
						You can run multiple scans across domains and subdomains
					</li>
				</ul>
			</div>
			<div className="info-paragrah">
				If you’re interested in extended capabilities, please don’t hesitate to contact us at
				<FreeTrialPlanInfo />
			</div>
		</div>
	);
};

export default FreeTrialPlanInfo;
