import React, { FC } from 'react';

import {
	BarDatum,
	EvBarChart,
	EvChartBottomTick,
	EvChartTooltip,
	EvSection,
	FormatHelper } from '@evinced-private/ui-common';

import TrendsNoDataIndication from '../../trends-no-data-indication/TrendsNoDataIndication';

import './TotalIssuesTrend.scss';

interface ITotalIssuesTrendProps {
	chartData: BarDatum[];
	numberOfAvailableScans: number;
	onBarItemClick: (scanId: string | number) => void;
}

const patterns = [{ label: 'Issues' }, { label: 'Pages' }];

const TotalIssuesTrend: FC<ITotalIssuesTrendProps> = ({
	chartData,
	numberOfAvailableScans,
	onBarItemClick
}) => {
	const title = 'Total issues across pages';

	const tooltipRenderer = (d): JSX.Element => {
		return (
			<EvChartTooltip
				label={`${FormatHelper.formatNumber(d.data.barItemCount)} Issues in`}
				value={`${FormatHelper.formatNumber(d.data.lineItemCount)} Pages`}
			/>
		);
	};

	const renderNoData = (): JSX.Element => {
		return (
			<TrendsNoDataIndication
				title={numberOfAvailableScans > 0 ? 'No selected scans' : 'No trends available'}
				renderTextMessage={() => (
					<>
						The property should include at least two scans in order to <br /> review accessibility
						trends over time.
					</>
				)}
			/>
		);
	};

	return (
		<EvSection className="total-issues-trend" ariaLabel={title}>
			<div className="chart-section-body">
				{chartData?.length === 0 ? (
					renderNoData()
				) : (
					<EvSection className="chart" ariaLabel={`${title} chart`}>
						<EvBarChart
							className="total-issue-across-pages"
							title={title}
							data={chartData}
							patterns={patterns}
							height={400}
							keys={['barItemCount']}
							indexBy="label"
							withLine
							isFocusable
							onBarClick={(barData) => {
								onBarItemClick(barData.data.id);
							}}
							barDesignProps={{
								margin: { top: 40, right: 70, bottom: 50, left: 70 },
								enableLabel: false,
								padding: 0.6,
								tooltip: tooltipRenderer,
								animate: false,
								axisTop: null,
								axisLeft: {
									tickPadding: 10,
									tickSize: 0,
									legendOffset: -65,
									legendPosition: 'middle'
								},
								axisRight: {
									tickSize: 0,
									tickPadding: 10,
									legendOffset: 60,
									legendPosition: 'middle'
								},
								axisBottom: {
									tickSize: 0,
									tickPadding: 25,
									renderTick: (tick) => <EvChartBottomTick tick={tick} />
								}
							}}
						/>
					</EvSection>
				)}
			</div>
		</EvSection>
	);
};

export default TotalIssuesTrend;
