import { IUrlSet } from '../interfaces/UrlSet';

import ScanCrawlStateHelper from './ScanCrawlStateHelper';

function removeMetaFieldsFromUrlSetConfig(urlSetConfig: IUrlSet): IUrlSet {
	const urlSetConfigCopy = { ...urlSetConfig };
	delete urlSetConfigCopy.id;
	delete urlSetConfigCopy.createdTime;
	delete urlSetConfigCopy.state;
	delete urlSetConfigCopy.updatedTime;
	delete urlSetConfigCopy.totalUrlsCount;
	return urlSetConfigCopy;
}

function findLatestDoneUrlSet(urlSets: IUrlSet[]): IUrlSet {
	const sortDescByDate = (setA: IUrlSet, setB: IUrlSet): number =>
		new Date(setB.createdTime).getTime() - new Date(setA.createdTime).getTime();

	return urlSets.sort(sortDescByDate).find((urlSet) => ScanCrawlStateHelper.isDone(urlSet.state));
}

export default {
	removeMetaFieldsFromUrlSetConfig,
	findLatestDoneUrlSet
};
