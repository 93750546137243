import React, { FC } from 'react';

import { BUTTON_TYPES, EvButton, EvIcon } from '@evinced-private/ui-common';

import DateFormatHelper from '../../helpers/DateFormatHelper';
import { JobTypeDto } from '../../interfaces/ScheduledJobConfiguration';
import EditIcon from '../icons/EditIcon.svg';

import './SchedulingStatusLine.scss';

interface ISchedulingStatusLineProps {
	isSchedulingEnabled: boolean;
	nextScheduledJobDate: Date;
	jobType: JobTypeDto;
	onEditClicked: () => void;
}

const SchedulingStatusLine: FC<ISchedulingStatusLineProps> = ({
	isSchedulingEnabled,
	jobType,
	nextScheduledJobDate,
	onEditClicked
}) => {
	const getDisplayText = (): string => {
		if (!isSchedulingEnabled) {
			return 'Schedule scans';
		}
		const prefixText = jobType === JobTypeDto.SCAN ? 'scan' : 'scan and crawl';
		const date = DateFormatHelper.formatDate(nextScheduledJobDate);
		return `Scheduled ${prefixText}: ${date}`;
	};

	return (
		<div className="scheduling-status-line">
			{getDisplayText()}
			<EvButton
				type={BUTTON_TYPES.ICON}
				onClick={onEditClicked}
				title="Edit scheduled scan"
				customTooltip={{ renderTooltip: true, place: 'left' }}
			>
				<EvIcon icon={EditIcon} className="edit-scheduling-icon" />
			</EvButton>
		</div>
	);
};

export default SchedulingStatusLine;
