import React, { FC } from 'react';

import { PieDatum, PieTooltipProps } from '@evinced-private/ui-common';

import flatten from 'lodash/flatten';

import { ComponentOverviewResponse, ComponentSeverities } from '../../../types/OverviewTypes';

import './ComponentsPieTooltip.scss';

// We're wrapping the component with a function so it can have extra props
// because the ResponsivePie component requires a tooltip of type FunctionComponent<PieTooltip>
// and does not accept a plain function returning JSX
export const getComponentsPieTooltipV2 = (
	componentsCountHash: ComponentOverviewResponse
): FC<PieTooltipProps<PieDatum>> => {
	const ComponentsPieTooltip: FC<PieTooltipProps<PieDatum>> = ({ datum: { id, value } }) => {
		const component = componentsCountHash.components.find((c) => c.component === id);
		const componentExists = component && component.severities.length !== 0;

		const renderObjectAsList = (componentSeverities: ComponentSeverities): JSX.Element => {
			const issueTypes: Array<{ type: string; issuesCount: number }> = flatten(
				componentSeverities.severities.map((issueSeverities) => issueSeverities.types)
			);
			// issueTypes can potentially have multiple entries with the same type, because a type
			// can come from different severities
			const typeCounts = new Map<string, number>();
			issueTypes.forEach((typeCount) => {
				const previousCount = typeCounts.get(typeCount.type) ?? 0;
				typeCounts.set(typeCount.type, typeCount.issuesCount + previousCount);
			});
			return (
				<div className="issue-types">
					{issueTypes.map(({ type, issuesCount }) => (
						<div key={type}>
							<span>
								<b>{type}:</b> {issuesCount}
							</span>
						</div>
					))}
				</div>
			);
		};

		return (
			<div className="components-pie-tooltip">
				<div className="tooltip-title">
					{componentExists ? (
						<>
							<b>Component: </b>
							{id}
						</>
					) : (
						<b>{id}</b>
					)}
				</div>
				<hr />
				<div className="details">
					<b>{value}%</b> of all issues are in{' '}
					{componentExists ? 'this component' : 'these components'}
				</div>
				{componentExists && (
					<>
						<hr />
						<div>
							<div className="tooltip-title">
								<b>Issue types:</b>
							</div>
							{renderObjectAsList(component)}
						</div>
					</>
				)}
			</div>
		);
	};
	return ComponentsPieTooltip;
};
