import React, { FC } from 'react';

import { EvTitle } from '@evinced-private/ui-common';

import SCOPE_TYPES from '../../../../types/scope/ScopeTypes';
import ScopeActionButtons from '../scope-action-buttons/ScopeActionButtons';

interface IScopeHeaderProps {
	actionsList: SCOPE_TYPES[];
	onActionBtnClick: (scopeType: SCOPE_TYPES) => void;
}

const ScopeHeader: FC<IScopeHeaderProps> = ({ onActionBtnClick, actionsList }) => {
	return (
		<div className="view-popup-scope-header">
			<div className="scope-label">
				<EvTitle
					titleText="Scope"
					id="view-popup-scope-title"
					descriptionId="view-popup-scope-description"
				/>
			</div>
			<ScopeActionButtons onBtnClick={onActionBtnClick} actionsList={actionsList} />
		</div>
	);
};

export default ScopeHeader;
