import React, { FC } from 'react';

import { EvInfoTooltip, EvLift, FormatHelper } from '@evinced-private/ui-common';

import './Kpi.scss';

interface IKpi {
	header: string;
	tooltip?: JSX.Element;
	summaryLine?: string;
	count?: number;
	lift?: number;
	falsePositive?: boolean;
}

const Kpi: FC<IKpi> = ({ header, tooltip, summaryLine, count, lift, falsePositive }) => {
	const successState = lift > 0;
	return (
		<div className="kpi">
			<div className="kpi-description">
				<div className="kpi-header-container">
					<div aria-hidden="true" className="kpi-header">
						{header}
					</div>
					<EvInfoTooltip title={header} showTitleText={false}>
						{tooltip}
					</EvInfoTooltip>
				</div>
				{summaryLine && (
					<div className="summary-line" aria-hidden="true">
						{summaryLine}
					</div>
				)}
			</div>
			<div className="kpi-numbers" aria-hidden="true">
				{(count || count === 0) && <div className="count">{FormatHelper.nFormatter(count)}</div>}
				<EvLift
					liftNumber={lift}
					small={!!count}
					success={falsePositive ? !successState : successState}
					ariaHidden
				/>
			</div>
		</div>
	);
};

export default Kpi;
