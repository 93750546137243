import React, { FC, useState } from 'react';

import {
	BUTTON_TYPES,
	EvButton,
	EvIcon,
	EvMainTitle,
	EvRadioGroup,
	EvTextInput
} from '@evinced-private/ui-common';

import { isRegexValid } from 'src/helpers/FormHelper';
import { IGroupingRule } from 'src/interfaces/GroupingRule';

import queryParamsOptions from '../../../../consts/queryParamsOptions';
import UrlNormalizingHelper from '../../../../helpers/UrlNormalizingHelper';
import PlusIcon from '../../../icons/PlusIcon.svg';
import RemoveIcon from '../../../icons/RemoveIcon.svg';

import './AddRulePopup.scss';
import './GroupingQueryParams.scss';

interface IGroupingQueryParamsProps {
	rule: IGroupingRule;
	emptyListRuleError: string;
	setEmptyListRuleError: (message: string) => void;
	updateRule: (rule: IGroupingRule) => void;
}

const QUERY_PARAMS_TOOLTIP = `Pages with the same base URL and different URL 
parameters, should be treated:`;

const GroupingQueryParams: FC<IGroupingQueryParamsProps> = ({
	rule,
	emptyListRuleError,
	setEmptyListRuleError,
	updateRule
}: IGroupingQueryParamsProps) => {
	const [ruleError, setRuleError] = useState('');
	const [excludeRuleEntry, setExcludeRuleEntry] = useState<string>(null);
	const [showInput, setShowInput] = useState<boolean>(false);

	const addExcludeRule = (newRuleUrl: string): void => {
		if (!newRuleUrl) {
			setRuleError('Please enter a URL');
			return;
		}
		const formattedUrl = UrlNormalizingHelper.formatURL(newRuleUrl);
		const isValid = isRegexValid(formattedUrl);
		if (!isValid) {
			setRuleError('Please enter a valid URL');
			return;
		}

		const ruleExists = rule.excludedQueryParameters.find((r) => {
			const formattedRule = UrlNormalizingHelper.formatURL(r);
			return formattedRule === formattedUrl;
		});

		if (ruleExists) {
			setRuleError('This rule is already in the list');
			return;
		}

		setRuleError('');
		setEmptyListRuleError('');

		const newExcludedQueryParameters = [...rule.excludedQueryParameters];
		newExcludedQueryParameters.push(formattedUrl);
		updateRule({ ...rule, excludedQueryParameters: newExcludedQueryParameters });
		setExcludeRuleEntry('');
	};

	const removeExcludeRule = (ruleForDelete): void => {
		const ruleExists = rule.excludedQueryParameters.find((r) => r === ruleForDelete);
		if (ruleExists) {
			const newExcludedQueryParameters = rule.excludedQueryParameters.filter(
				(r) => r !== ruleForDelete
			);
			updateRule({ ...rule, excludedQueryParameters: newExcludedQueryParameters });
		}
	};

	const renderQueryParamsExcludeRule = (excludeRuleUrl): JSX.Element => {
		return (
			<div className="query-param" key={excludeRuleUrl}>
				<label className="param-value">{excludeRuleUrl}</label>
				<EvButton
					type={BUTTON_TYPES.ICON}
					title="remove-rule"
					className="delete-param"
					onClick={() => {
						removeExcludeRule(excludeRuleUrl);
					}}
					disabled={rule.ignoreQueryParameters !== queryParamsOptions.LIST}
				>
					<EvIcon icon={RemoveIcon} />
				</EvButton>
			</div>
		);
	};

	const renderExcludeInputOnListOptionSelected = (): JSX.Element => {
		if (!showInput) {
			return null;
		}
		return (
			<div className="exclude-rule">
				<div className="exclude-input-container">
					<EvTextInput
						placeholder="Add query parameter"
						ariaLabel="Type in a query parameter (URL regex)"
						value={excludeRuleEntry}
						error={emptyListRuleError || ruleError}
						isDisabled={rule.ignoreQueryParameters !== queryParamsOptions.LIST}
						onChange={setExcludeRuleEntry}
						onEnter={() => {
							addExcludeRule(excludeRuleEntry);
						}}
					/>
					<EvButton
						title="Add Parameter"
						type={BUTTON_TYPES.ICON}
						className="add-param-button"
						onClick={() => {
							addExcludeRule(excludeRuleEntry);
						}}
					>
						<EvIcon icon={PlusIcon} />
					</EvButton>
				</div>

				<div className="query-params">
					{rule.excludedQueryParameters.map(renderQueryParamsExcludeRule)}
				</div>
			</div>
		);
	};

	const radioOptions = [
		{
			label: 'as the same page',
			isChecked: rule.ignoreQueryParameters === queryParamsOptions.IGNORE_ALL,
			onClick: () => {
				updateRule({ ...rule, ignoreQueryParameters: queryParamsOptions.IGNORE_ALL });
				setShowInput(false);
			}
		},
		{
			label: 'as different pages',
			isChecked: rule.ignoreQueryParameters === queryParamsOptions.INCLUDE_ALL,
			onClick: () => {
				updateRule({ ...rule, ignoreQueryParameters: queryParamsOptions.INCLUDE_ALL });
				setShowInput(false);
			}
		},
		{
			label: 'according to the following parameters',
			isChecked: rule.ignoreQueryParameters === queryParamsOptions.LIST,
			onClick: () => {
				updateRule({ ...rule, ignoreQueryParameters: queryParamsOptions.LIST });
				setShowInput(true);
			},
			isListMode: true
		}
	];

	const queryParamsTitleId = 'query-params-title';

	return (
		<section
			className="grouping-rule-part grouping-query-params"
			aria-labelledby={queryParamsTitleId}
		>
			<EvMainTitle className="title-text">URL Parameters</EvMainTitle>
			<div>{QUERY_PARAMS_TOOLTIP}</div>
			<EvRadioGroup options={radioOptions} ariaLabel="URL parameters" />
			{renderExcludeInputOnListOptionSelected()}
		</section>
	);
};

export default GroupingQueryParams;
