import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { EvBreadcrumbs } from '@evinced-private/ui-common';

import { formatDate } from '../../helpers/DateFormatHelper';
import { isExistInPath } from '../../helpers/NavigationHelper';
import PropertyHelper from '../../helpers/PropertyHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import { IProperty } from '../../interfaces/Property';
import { IScan } from '../../interfaces/Scan';
import {
	INavigationRoute,
	useNavigation
} from '../../providers/navigationProvider/NavigationProvider';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import Logger from '../../services/Logger';
import propertiesService from '../../services/PropertiesService';
import scansService from '../../services/scan/ScansService';
import TabsViews from '../../types/TabsViews';

import './HeaderNavigationBreadcrumbs.scss';

const HeaderNavigationBreadcrumbs: FC = () => {
	const [property, setProperty] = useState<IProperty>(null);
	const [scan, setScan] = useState<IScan>(null);
	const [currentTab, setCurrentTab] = useState<TabsViews>(null);
	const { propertyId, scanId } = useParams<{ [key: string]: string }>();
	const { currentRoute } = useNavigation();
	const location = useLocation();

	const queryParams: URLSearchParams = useMemo(
		() => new URLSearchParams(location.search),
		[location]
	);
	const isAllPropertiesPage: boolean = useMemo((): boolean => {
		return location.pathname.includes(RoutesHelper.getPropertiesPagePath());
	}, [location.pathname]);
	const isNewPropertyPage: boolean = useMemo((): boolean => {
		return location.pathname.includes(RoutesHelper.getNewPropertyPath());
	}, [location.pathname]);
	const baseScanId: string = queryParams.get(RoutesHelper.COMPARE_SCANS_PARAMS.BASE_SCAN_ID);
	const comparableScanId: string = queryParams.get(
		RoutesHelper.COMPARE_SCANS_PARAMS.COMPARABLE_SCAN_ID
	);
	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const viewId: string = useMemo(() => properyViewContext?.currentView?.id, [properyViewContext]);
	const routes: INavigationRoute[] = [];

	if (isAllPropertiesPage) {
		routes.push({
			id: 'all-properties-link',
			url: RoutesHelper.getPropertiesPagePath(),
			title: 'All properties'
		});
	}

	const isValidPropertyData: boolean = useMemo(() => {
		if (propertyId && property) {
			return true;
		}
		return false;
	}, [propertyId, property]);

	const isValidScanData: boolean = useMemo(() => {
		if (scanId && scan) {
			return true;
		}
		return false;
	}, [scanId, scan]);

	const isSecondBreadcrumbsLevel: boolean = useMemo((): boolean => {
		const paths: string[] = [
			RoutesHelper.getSettingsPath(),
			RoutesHelper.getViewsPath(),
			RoutesHelper.getCrawlResultsPath(),
			RoutesHelper.getScanComparisonPath()
		];
		const secondLevel: boolean = isExistInPath(paths, location.pathname);

		return isValidPropertyData || secondLevel;
	}, [isValidPropertyData, location.pathname]);

	const isThirdBreadcrumbsLevel: boolean = useMemo((): boolean => {
		const paths: string[] = [
			RoutesHelper.getIssuesPath(),
			RoutesHelper.getPagePath(),
			RoutesHelper.getScannedPagesPath()
		];
		const isIssuesOrPagePath: boolean = isExistInPath(paths, location.pathname);
		const isOptionsTab: boolean = Object.values(TabsViews).some((tab: TabsViews): boolean => {
			const tabName = `/${RoutesHelper.getViewName(tab)}`;
			const isIncludeInRoute: boolean = location.pathname.includes(tabName);
			if (isIncludeInRoute) {
				setCurrentTab(tab);
				return isIncludeInRoute;
			}
			return false;
		});

		return isValidScanData && (isOptionsTab || isIssuesOrPagePath);
	}, [isValidScanData, location.pathname]);

	const isScanComparisonCommon: boolean = useMemo((): boolean => {
		const isScanComparisonCommonPage: boolean = location.pathname.includes(
			RoutesHelper.getCommonListPath()
		);

		return propertyId && isScanComparisonCommonPage;
	}, [propertyId, location.pathname]);

	const fetchProperty = useCallback(async (): Promise<void> => {
		try {
			const property: IProperty = await propertiesService.getProperty(propertyId);
			setProperty(property);
		} catch (error) {
			Logger.error(`Error fetching property details for ${propertyId}`, error);
			setProperty(null);
		}
	}, [propertyId]);

	const fetchScan = useCallback(async (): Promise<void> => {
		try {
			const scan: IScan = await scansService.getScanMetadata(scanId);
			setScan(scan);
		} catch (error) {
			Logger.error(`Error scan/scans details for scan id ${scanId}, property ${propertyId}`, error);
			setScan(null);
		}
	}, [scanId, propertyId]);

	useEffect(() => {
		if (propertyId && !PropertyHelper.isPropertyInDraftMode(propertyId)) {
			fetchProperty();
		} else {
			setProperty(null);
		}
	}, [propertyId, fetchProperty]);

	useEffect(() => {
		if (scanId) {
			fetchScan();
		} else {
			setScan(null);
		}
	}, [scanId, fetchScan]);

	const renderBreadcrumbs = (): JSX.Element => {
		if (isNewPropertyPage) {
			routes.push({
				id: 'new-property',
				url: '',
				title: 'Create new property'
			});
		} else if (isSecondBreadcrumbsLevel && property) {
			// All properties -> Property name
			const url: string = RoutesHelper.getPropertyPagePath(propertyId, viewId);
			routes.push({
				id: `${property?.name}-${propertyId}`,
				url,
				title: property?.name
			});
		}
		if (isThirdBreadcrumbsLevel) {
			// All properties -> Property name -> Scan
			routes.push({
				id: `${scan.createdTime}-${scan.id}`,
				url: RoutesHelper.getScanDetailsView(propertyId, scanId, currentTab, viewId),
				title: `Scan ${formatDate(scan.createdTime)}`
			});
		} else if (isScanComparisonCommon) {
			// All properties -> Property name -> Scan Comparison
			routes.push({
				id: `scan-comparison-${propertyId}`,
				url: RoutesHelper.getScanComparisonPagePath(
					propertyId,
					baseScanId,
					comparableScanId,
					viewId
				),
				title: 'Scan Comparison'
			});
		}

		if (currentRoute) {
			// -> Current page
			routes.push(currentRoute);
		}

		// TO DO: Make the Evbreadcrumbs responsive as part of the work
		// with Vered
		return (
			<EvBreadcrumbs
				routes={routes}
				className="header-navigation-breadcrumbs"
				lastBreadcrumbTitleLength={25}
			/>
		);
	};

	return renderBreadcrumbs();
};

export default HeaderNavigationBreadcrumbs;
