import React, { FC } from 'react';
import { useLocation } from 'react-router';

import classNames from 'classnames';

import { EvMainTitle, EvSection, EvTextInput } from '@evinced-private/ui-common';

import { FormError } from '../../../helpers/FormHelper';
import propertySourceHelper from '../../../helpers/PropertySourceHelper';
import RoutesHelper from '../../../helpers/RoutesHelper';
import { IProperty } from '../../../interfaces/Property';
import URL_SET_SOURCES from '../../../types/UrlSetSources';
import PropertyJsonEditor from '../property-json-editor/PropertyJsonEditor';
import PropertySettingsSourcePicker from '../source-picker/PropertySettingsSourcePicker';

interface IPropertySettingsTitleProps {
	property: IProperty;
	originalProperty: IProperty;
	nameError: FormError;
	sourceMode: URL_SET_SOURCES;
	setLoading: (state: boolean) => void;
	getPropertyData: (sourceMode: URL_SET_SOURCES) => Promise<void>;
	setProperty: (property: IProperty) => void;
	setPropertySourceMode: (source: URL_SET_SOURCES) => void;
	// JSON Editor props
	propertyId: string;
	goToProperty: (propertyId: string) => void;
	editProperty: (propertyForSave: IProperty, shouldCrawl: boolean) => void;
}

const PropertySettingsTitle: FC<IPropertySettingsTitleProps> = ({
	property,
	originalProperty,
	nameError,
	sourceMode,
	getPropertyData,
	setProperty,
	setLoading,
	setPropertySourceMode,
	propertyId,
	goToProperty,
	editProperty
}: IPropertySettingsTitleProps) => {
	const location = useLocation();
	const isListMode = propertySourceHelper.isListMode(sourceMode);
	const isNewProperty: boolean = location.pathname.includes(RoutesHelper.getNewPropertyPath());
	const title: string = isNewProperty ? 'Create new property' : 'Advanced settings';
	const classes: string = classNames('page-heading', { 'new-property': isNewProperty });

	const setPropertyName = (name): void => {
		const newProperty = { ...property };
		newProperty.name = name;
		setProperty(newProperty);
	};

	return (
		<div className="property-settings-title">
			<EvMainTitle className={classes}>{title}</EvMainTitle>
			<EvSection
				className="property-title"
				ariaLabel="Set property name and mapping mode"
				skipLinkId="property-name"
			>
				<div className="property-name">
					<div className="new-label">Property name</div>
					<div className="name-input-container">
						<EvTextInput
							ariaLabel="Property name"
							error={(nameError && nameError.message) || ''}
							value={property.name}
							onChange={setPropertyName}
						/>
					</div>
				</div>
				<div className="spacer" />
				<PropertySettingsSourcePicker
					property={property}
					originalProperty={originalProperty}
					sourceMode={sourceMode}
					getPropertyData={getPropertyData}
					setPropertySourceMode={setPropertySourceMode}
					setLoading={setLoading}
				/>
				{!isListMode && (
					<PropertyJsonEditor
						propertyId={propertyId}
						goToProperty={goToProperty}
						editProperty={editProperty}
					/>
				)}
			</EvSection>
		</div>
	);
};

export default PropertySettingsTitle;
