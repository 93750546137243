import React, { FC, useCallback, useRef, useState } from 'react';

import {
	BUTTON_TYPES,
	ErrorIcon,
	EvButton,
	EvIcon,
	EvToast,
	TOAST_STATUS
} from '@evinced-private/ui-common';

import ClipboardHelper from '../../../helpers/ClipboardHelper';
import CheckIcon from '../../icons/CheckIcon.svg';
import CopyIcon from '../../icons/CopyIcon.svg';

import './CopyToClipboard.scss';

const copyStates = {
	copying: {
		text: 'Copying. Please wait',
		icon: null,
		state: TOAST_STATUS.INFO
	},
	success: {
		text: 'Copied to Clipboard',
		icon: CheckIcon,
		state: TOAST_STATUS.SUCCESS
	},
	error: {
		text: 'Copy Attempt Error',
		icon: ErrorIcon,
		state: TOAST_STATUS.FAIL
	}
};

interface ICopyToClipboardProps {
	textToCopy: string;
	renderJustIcon?: boolean;
	copyButtonText?: string;
	iconTitle?: string;
	buttonType?: BUTTON_TYPES;
}

type copyStateItem = {
	text: string;
	icon: string;
	state: TOAST_STATUS;
};

const CopyToClipboard: FC<ICopyToClipboardProps> = ({
	renderJustIcon,
	copyButtonText = 'Copy Issue Link',
	textToCopy,
	iconTitle = 'Copy selector',
	buttonType = BUTTON_TYPES.ACTION,
}) => {
	const [copyState, setCopyState] = useState<copyStateItem>(copyStates.copying);
	const [showAnnouncement, setShowAnnouncement] = useState<boolean>(false);
	const [buttonRef, setbuttonRef] = useState(useRef(null));

	const copyTextLinkButton = useRef(null);

	const onToastClose = (): void => {
		setShowAnnouncement(false);
		buttonRef.current.focus();
	};

	const renderToast = (): JSX.Element => (
		<EvToast
			announcement={copyState.text}
			status={copyState.state}
			show={showAnnouncement}
			onToastClose={onToastClose}
		/>
	);

	const copyToClipboard = (text): void => {
		const isCopied = ClipboardHelper.copyToClipboard(text);
		setCopyState(isCopied ? copyStates.success : copyStates.error);
		setShowAnnouncement(true);
	};

	const copyButtonSelector = useCallback(
		(copyToClipboardButton: React.RefObject<HTMLButtonElement>): void => {
			setbuttonRef(copyToClipboardButton);
			copyToClipboard(textToCopy);
		},
		[textToCopy]
	);

	const copyTextLink = useCallback((): void => {
		setbuttonRef(copyTextLinkButton);
		copyToClipboard(textToCopy);
	}, [textToCopy]);

	return (
		<div className="copy-to-clipboard">
			{showAnnouncement && renderToast()}
			<div id="announce" className="sr-only" aria-live="assertive">
				{copyState.text !== copyStates.copying.text && <p>{copyState.text}</p>}
			</div>
			<div className="copy-text">
				{renderJustIcon ? (
					<EvButton
						ref={buttonRef}
						type={BUTTON_TYPES.ICON}
						onClick={() => copyButtonSelector(buttonRef)}
						title={iconTitle}
					>
						<EvIcon icon={CopyIcon} />
					</EvButton>
				) : (
					<EvButton
						ref={copyTextLinkButton}
						type={buttonType}
						title={copyButtonText}
						onClick={copyTextLink}
					>
						{copyButtonText}
					</EvButton>
				)}
			</div>
		</div>
	);
};

export default CopyToClipboard;
