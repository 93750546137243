import React, { FC, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
	BUTTON_TYPES,
	DropdownOptionType,
	EvDropdownMenu,
	EvIcon,
	EvLink,
	EvSpinner,
	EvTableColumn,
	EvTableOptions,
	IDropdownMenuOption,
	IEvButtonProps,
	SORT_ORDER,
	TITLE_MODES
} from '@evinced-private/ui-common';

import { formatShortMonthWithoutTimeDatetime } from '../../helpers/DateFormatHelper';
import PropertyHelper from '../../helpers/PropertyHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import ScanCrawlStateHelper from '../../helpers/ScanCrawlStateHelper';
import ScanHelper from '../../helpers/ScanHelper';
import ScoreHelper from '../../helpers/ScoreHelper';
import { IProperty } from '../../interfaces/Property';
import { IScan } from '../../interfaces/Scan';
import SCAN_STATE from '../../interfaces/ScanState';
import { IUrlSet } from '../../interfaces/UrlSet';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import logger from '../../services/Logger';
import scansService from '../../services/scan/ScansService';
import TogglesService, { DevelopmentToggles } from '../../services/TogglesService';
import TabsViews from '../../types/TabsViews';
import renderNumberCellFormatter from '../common/ev-table/cell-formatters/numberCellFormatter';
import NoDataIndication from '../common/ev-table/no-data-indication/NoDataIndication';
import SiteScannerTable from '../common/site-scanner-table/SiteScannerTable';
import DeleteScanPopup from '../delete-scan-popup/DeleteScanPopup';
import EllipsisIcon from '../icons/EllipsisIcon.svg';
import HandsIcon from '../icons/HandsIcon.svg';
import PropertyJobsStatusAction from '../property-jobs-status-row-action/PropertyJobsStatusAction';
import StatusFormatter from '../scan-analysis/table-formatters/status-formatter/StatusFormatter';
import ScanConfigPopup from '../scan-configuration/ScanConfigPopup';

import './ScansTable.scss';

interface IScansTableProps {
	property: IProperty;
	onScanDeleted: () => Promise<void>;
	scans: IScan[];
	urlSet: IUrlSet;
	renderCreateScanPopup: () => JSX.Element;
	selectedScans: string[];
	setSelectedScans: (ids: string[]) => void;
}

interface IScanTableRowData extends IScan {
	createdTimeFormatted: {
		createdTime: string;
		link: string;
	};
	scoreResult?: {
		score?: number;
		lift?: number;
		liftPercentage?: number;
	};
}

const PDF_MODE_MESSAGE = <>This property is in PDF file type mode. Scans are not supported.</>;
const ADD_SCAN_AFTER_CRAWL_MESSAGE =
	'Once crawl is complete, initiate a scan and check results here';
const SCAN_MESSAGE = (
	<>
		Your crawl has been completed.
		<br />
		Please scan to view data and get valuable insights.
		<br />
		<br />
		Note: To ensure up-to-date insights, scans older than one year are archived and not displayed.
	</>
);
const RUN_CRAWL_AFTER_SCAN_MESSAGE = (
	<>
		<br />
		<br />
		Once crawling is complete, you will need to initiate a scan.
	</>
);

const ScansTable: FC<IScansTableProps> = ({
	property,
	scans,
	urlSet,
	onScanDeleted,
	renderCreateScanPopup,
	selectedScans,
	setSelectedScans
}: IScansTableProps) => {
	const history = useHistory();
	const { isReadOnlyUser } = useUserTenant();

	const canCompareScans = useMemo(() => {
		return TogglesService.getToggle(DevelopmentToggles.SHOW_COMPARE_SCANS);
	}, []);
	const nonSelectableScanIds = useMemo(() => {
		return ScanHelper.getNonSelectableScanIds(selectedScans, scans);
	}, [selectedScans, scans]);

	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const currentViewId: string = useMemo(
		() => properyViewContext?.currentView?.id,
		[properyViewContext]
	);

	const handleScanSelection = (scan: IScan, isSelected: boolean): void => {
		if (isSelected) {
			setSelectedScans([...selectedScans, scan.id]);
		} else {
			setSelectedScans(selectedScans.filter((id) => id !== scan.id));
		}
	};

	const tableOptions: EvTableOptions = {
		title: 'Scans',
		defaultSorted: [
			{
				dataField: 'createdTime',
				order: SORT_ORDER.DESC
			}
		],
		pagination: true,
		dataType: 'scans',
		caption: 'Scans Table',
		...(canCompareScans && {
			selectRow: {
				mode: 'checkbox',
				clickToSelect: false,
				hideSelectAll: true,
				onSelect: handleScanSelection,
				nonSelectable: nonSelectableScanIds
			}
		})
	};

	const getLinkToScanResults = (scan: IScanTableRowData): string => {
		const isScanResultsReady = ScanCrawlStateHelper.isScanResultsReady(scan.state);
		return isScanResultsReady
			? RoutesHelper.getScanDetailsView(property.id, scan.id, TabsViews.OVERVIEW, currentViewId)
			: '#';
	};

	const getViewScanResultsButtonProps = (row: IScanTableRowData): IEvButtonProps => {
		const isScanResultsReady = ScanCrawlStateHelper.isScanResultsReady(row.state);
		const link = getLinkToScanResults(row);
		const onViewClick = (): void => {
			history.push(link);
		};

		return {
			type: BUTTON_TYPES.ICON,
			title: isScanResultsReady ? 'View scan results' : 'Scan is in progress...',
			customTooltip: isScanResultsReady ? null : { renderTooltip: true },
			onClick: isScanResultsReady ? onViewClick : null,
			disabled: !isScanResultsReady,
			disabledReason: 'Scan is in progress',
			children: 'View scan results'
		};
	};

	const onDeleteScanConfirm = useCallback(
		async (id): Promise<void> => {
			try {
				await scansService.deleteScan(id);
				logger.debug(`Scan deleted ${id}`);
				await onScanDeleted();
			} catch (err) {
				logger.error(`Error deleting scan ${id}`, err);
			}
		},
		[onScanDeleted]
	);

	const renderActionsCell = (cell, row: IScanTableRowData): JSX.Element => {
		const isScanReady = ScanCrawlStateHelper.isScanResultsReady(row.state);
		const options: IDropdownMenuOption[] = [
			{
				id: 'view-scan-results',
				buttonProps: getViewScanResultsButtonProps(row),
				disabled: !isScanReady,
				title: 'View scan results',
				type: DropdownOptionType.BUTTON
			}
		];

		if (!isReadOnlyUser()) {
			options.push({
				id: 'delete-scan',
				renderOption: (accessibilityProps) => (
					<DeleteScanPopup
						id={row.id}
						createdTime={row.createdTime}
						accessibilityProps={accessibilityProps}
						onConfirm={onDeleteScanConfirm}
					/>
				),
				type: DropdownOptionType.CUSTOM
			});
		}

		if (TogglesService.getToggle(DevelopmentToggles.SHOW_SCAN_URLSET_JSON_CONFIG)) {
			options.push({
				id: 'view-scan-config',
				renderOption: (accessibilityProps) => (
					<ScanConfigPopup
						triggerButtonProps={{
							title: 'Scan settings',
							type: BUTTON_TYPES.ICON,
							children: 'Scan Url Set configuration',
							accessibilityProps
						}}
						scanId={row.id}
						accessibilityProps={accessibilityProps}
					/>
				),
				type: DropdownOptionType.CUSTOM
			});
		}
		return (
			<div className="actions-cell">
				<div className="actions-cell">
					<EvDropdownMenu
						id={property.id}
						triggerButtonProps={{
							type: BUTTON_TYPES.ICON,
							ariaHaspopup: 'menu',
							title: 'Scan Actions',
							children: (
								<div className="menu-trigger-icon">
									<EvIcon icon={EllipsisIcon} small />
								</div>
							)
						}}
						options={options}
					/>
				</div>
			</div>
		);
	};

	const renderEndedCell = (cell, row: IScanTableRowData): string => {
		if (
			ScanCrawlStateHelper.isScanResultsReady(row.state) ||
			ScanCrawlStateHelper.isFailed(row.state)
		) {
			return formatShortMonthWithoutTimeDatetime(cell);
		}
		return 'N/A';
	};

	const renderCreatedCell = (cell, row: IScanTableRowData): JSX.Element => {
		const isScanResultsReady = ScanCrawlStateHelper.isScanResultsReady(row.state);
		if (isScanResultsReady) {
			const link = getLinkToScanResults(row);
			return <Link to={link}>{formatShortMonthWithoutTimeDatetime(cell)}</Link>;
		}
		return <div className="no-link-td">{formatShortMonthWithoutTimeDatetime(cell)}</div>;
	};

	const renderScoreCell = (cell: number, row: IScanTableRowData): JSX.Element => {
		if (!row.scoreResult) {
			return <div>N/A</div>;
		}

		const formattedScore = ScoreHelper.roundScore(cell || 0);
		return renderNumberCellFormatter(formattedScore);
	};

	const getColumns = (): EvTableColumn[] => {
		const columns = [
			{
				dataField: 'createdTime',
				text: 'Created',
				style: { width: '80px' },
				headerStyle: { width: '80px' },
				classes: 'clickable-cell',
				sort: true,
				formatter: renderCreatedCell
			},
			{
				dataField: 'updatedTime',
				text: 'Ended',
				style: { width: '80px' },
				headerStyle: { width: '80px' },
				sort: true,
				formatter: renderEndedCell
			},
			{
				dataField: 'scoreResult.score',
				text: 'Score',
				style: { width: '80px' },
				classes: 'score-cell-td',
				headerStyle: { width: '80px' },
				sort: true,
				formatter: renderScoreCell
			},
			{
				dataField: 'state',
				text: 'Status',
				style: { width: '50px' },
				headerStyle: { width: '50px' },
				sort: true,
				// eslint-disable-next-line react/no-unstable-nested-components
				formatter: (cell: SCAN_STATE) => {
					return <StatusFormatter scanState={cell} />;
				}
			},
			{
				dataField: 'issuesCount',
				text: 'Issues',
				style: { width: '25px' },
				headerStyle: { width: '25px' },
				sort: true,
				formatter: renderNumberCellFormatter
			},
			{
				dataField: 'totalUrlsCount',
				text: 'Pages',
				style: { width: '25px' },
				headerStyle: { width: '25px' },
				sort: true,
				formatter: renderNumberCellFormatter
			},
			{
				dataField: 'failedUrlsCount',
				text: 'Errors',
				style: { width: '25px' },
				headerStyle: { width: '25px' },
				sort: true,
				formatter: renderNumberCellFormatter
			},
			{
				dataField: 'actions',
				classes: 'actions-cell-td',
				text: 'Actions',
				style: { width: '50px' },
				headerStyle: { width: '50px' },
				formatter: renderActionsCell
			}
		];

		return columns;
	};

	const formatTableData = (): IScanTableRowData[] => {
		return scans.map((scan) => {
			return {
				...scan,
				// formatted data for the first cell
				// todo: check if this can be removed
				createdTimeFormatted: {
					createdTime: scan.createdTime,
					link: RoutesHelper.getScanResultsPagePath(property.id, scan.id, currentViewId)
				}
			};
		});
	};

	const getNoDataMessage = (): string | JSX.Element => {
		if (!urlSet) {
			return PropertyHelper.isPdfMode(property) ? PDF_MODE_MESSAGE : ADD_SCAN_AFTER_CRAWL_MESSAGE;
		}

		const isCrawlRunning = ScanCrawlStateHelper.isInProgress(urlSet.state);
		const isCrawlDone = ScanCrawlStateHelper.isDone(urlSet.state);

		if (isCrawlRunning) {
			return (
				<div className="no-data-crawl-running">
					<EvSpinner />
					<NoDataIndication
						className="no-data-message"
						titleMode={TITLE_MODES.NORMAL}
						title="This property is currently being crawled."
						renderTextMessage={() => (
							<>
								This should take between 5 to 30 minutes, depending on your <br />
								site&rsquo;s size and complexity.
								{/* eslint-disable-next-line react/jsx-no-useless-fragment */}
								{PropertyHelper.isPdfMode(property) ? <></> : RUN_CRAWL_AFTER_SCAN_MESSAGE}
							</>
						)}
					/>
				</div>
			);
		}

		if (isCrawlDone) {
			if (PropertyHelper.isPdfMode(property)) {
				return (
					<NoDataIndication
						title="Review crawl results"
						renderTextMessage={() => (
							<>
								<EvLink
									url={RoutesHelper.getUrlSetPagePath(property.id, urlSet.id)}
									className="link-to-status"
									linkText="PDFs List"
								/>
								<br />
								<br />
								Detecting accessibility issues within these PDF files - coming soon!
							</>
						)}
					/>
				);
			}
			return (
				<NoDataIndication
					titleMode={TITLE_MODES.NORMAL}
					className="no-data-crawl-complete"
					title="Almost there..."
					renderIcon={() => <EvIcon className="no-data-icon" icon={HandsIcon} />}
					renderTextMessage={() => SCAN_MESSAGE}
					renderMessageAction={() => {
						return renderCreateScanPopup();
					}}
				/>
			);
		}
		return '';
	};

	const noDataIndicationMessage = getNoDataMessage();
	const tableData = formatTableData();
	const lastScan = scans[0];

	const topRowActions = useMemo(() => {
		return <PropertyJobsStatusAction onDeleteScan={onDeleteScanConfirm} lastScan={lastScan} />;
	}, [lastScan, onDeleteScanConfirm]);

	return (
		<div className="scans-table">
			<SiteScannerTable
				tableId={`property-${property.id}-scans-table`}
				tableData={tableData}
				totalCount={tableData.length}
				columns={getColumns()}
				options={tableOptions}
				noDataIndicationMessage={noDataIndicationMessage}
				topRowActions={topRowActions}
				alignActionsRight={false}
			/>
		</div>
	);
};

export default ScansTable;
