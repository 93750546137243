import React, { FC } from 'react';

import { EvSection, EvTextInputSearch } from '@evinced-private/ui-common';

import './SearchFilter.scss';

interface IPropertiesFilter {
	searchInputValue: string;
	onInputChange: (value: string) => void;
}

const SearchFilter: FC<IPropertiesFilter> = ({ searchInputValue, onInputChange }) => {
	return (
		<EvSection className="search-filter" ariaLabel="Search filter">
			<div className="search-input">
				<EvTextInputSearch
					value={searchInputValue}
					onChange={onInputChange}
					onConfirm={() => onInputChange(searchInputValue)}
				/>
			</div>
		</EvSection>
	);
};

export default SearchFilter;
