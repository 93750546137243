import React, { FC } from 'react';

import classNames from 'classnames';

import { EvIcon } from '@evinced-private/ui-common';

import EmptyCollections from 'src/components/icons/EmptyCollections.svg';
import QuickTipIcon from 'src/components/icons/QuickTipIcon.svg';

import LoadErrorMsgLayout from '../../pages/property/LoadErrorMsgLayout';

import './NoDataInCollection.scss';

interface INoDataInCollection {
	className?: string;
}

const MAIN_TITLE = '"My collection" is empty';
const SECONDARY_MESSAGE =
	'In order to view your key properties filtered from the rest, please add at least one property to “My collection”.';
const NO_DATA_IMG_ALT_TEXT =
	'To add a property, click on the actions menu, (located at the end of the property row you wish to add), and select “Add to My collection”';

const NoDataInCollection: FC<INoDataInCollection> = ({ className }): JSX.Element => {
	return (
		<div className={classNames('no-data-in-collection-wrapper', { [className]: !!className })}>
			<LoadErrorMsgLayout
				mainTitle={MAIN_TITLE}
				secondaryMsg={SECONDARY_MESSAGE}
				icon={EmptyCollections}
			>
				<EvIcon icon={QuickTipIcon} title={NO_DATA_IMG_ALT_TEXT} className="quick-tip" />
			</LoadErrorMsgLayout>
		</div>
	);
};

export default NoDataInCollection;
